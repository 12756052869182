import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import './login.css'
import { apiCall, setDefaultHeader } from '../utils/httpClient'
import ApiEndPoints from '../utils/apiEndPoints'
import Footer from '../footer/footer';
import Header from '../header/header';
import SimpleReactValidator from 'simple-react-validator';
import Flag from 'react-world-flags'
import Autocomplete from 'react-google-autocomplete';

import countries from '../../component/countries'
import _ from 'lodash';
import Geocode from "react-geocode";
import strings from '../../language';
import detectBrowserLanguage from "detect-browser-language";
Geocode.setApiKey("AIzaSyDdLk5tb75SiJvRk9F2B4almu-sBAi1-EM");
export default (props) => {
  const history = useHistory();
  const wrapperRef = useRef()

  const [searchval, setsearchval] = useState('')
  const [searchPlaceh, setsearchPlaceh] = useState('France')
  const [countriesData, setcountriesData] = useState(countries)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('+33')
  const [flag, setFlag] = useState("fr")
  const [showflagPicker, setShowflagPicker] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')
  const [, forceUpdate] = useState()
  const [resname, setresname] = useState('')
  const [addressshow, setaddress] = useState('')
  const [password, setpassword] = useState('')
  const [email, setemail] = useState('')
  const [latitude, setlatitude] = useState('')
  const [longitude, setlongitude] = useState('')

  const simpleValidator = useRef(new SimpleReactValidator({
    locale: detectBrowserLanguage()
  }))
  useEffect(() => {
    setLocale()
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowflagPicker(false)
      }
    }
    history.location.state &&
      setemail(history.location.state.email)
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [history, wrapperRef])

  async function setLocale() {
    const lang = await localStorage.getItem("locale");

  }
  function validateAllField() {
    if (simpleValidator.current.allValid()) {
      return true;
    } else {
      simpleValidator.current.showMessages(true);
      forceUpdate(1)
      return false;
    }
  }

  const onPressFlagPicker = (country) => {
    setShowflagPicker(!showflagPicker)
    setCountryCode(country.dial_code)
    setFlag(country.code)
    setcountriesData(countries);
    setsearchPlaceh(country.name)
  }
  const onCountryCodeSearch = async (searchText) => {
    setsearchval(dataArray2);
    let data = await countries;
    searchText = searchText.trim().toLowerCase();
    let dataArray2 = data.filter(l => {
      return l.name.toLowerCase().match(searchText);
    });
    setcountriesData(dataArray2);
  }
  const routeChange = async () => {
    await localStorage.setItem('Auth', '02');
    let path = ``;
  }

  const onRestaurantReg = async () => {
    const isValid = validateAllField()
    const lang = await localStorage.getItem("locale");
    if (isValid) {
      const datas = {
        phone: phoneNumber.replace(/^0+/, ""),
        name: resname,
        email: email,
        password: password,
        country_code: countryCode,
        device_id: 'kjfbgjfjdkf',
        device_type: 'web',
        device_token: 'dvsdfndfsdfnldksnfsdknfksdjfsdnfkl',
        address: addressshow,
        latitude: latitude,
        longitude: longitude
      }
      try {
        const { data } = await apiCall('post',
          ApiEndPoints.restroRegistration, datas)
        if (data.status == 200) {
         
          setDefaultHeader('token', data.token)
          await localStorage.setItem("address",addressshow);
          await localStorage.setItem("name",resname);
          // await localStorage.setItem("id",data.data.id);
          navOtp()
        } else if (data.status == 201) {
          setErrorMessage(lang == "en-US" ? data.message : lang == "en" ? data.message : data.fr_message)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  const navOtp = () => {

    let path = `otpverification`;
    history.push(path, { phoneNumber: phoneNumber, countryCode: countryCode, type: 1 });
  }
  const onPressAddress = () => {
    let path = `map`;
    history.push(path, { phoneNumber, countryCode, resname, addressshow, email, password, latitude, longitude });
  }
  const onPlaceSelected = (place) => {
    if (place.geometry) {
      // const latValue = place.geometry.location.lat();
      // const lngValue = place.geometry.location.lng();
      setlatitude(place.geometry.location.lat())
      setlongitude(place.geometry.location.lng())
      setaddress(place.formatted_address)
    }
  }
  return (
    <div>
      <Header />
      <div className="main-banner--home">
        {/* <div className="bg" style={{ backgroundImage: 'url("images/BackGroundImage.svg")' }} /> */}
        <div className="bg registration_pat_bg" />
        <div className="form-section">
          <div className="outer">
            <div className="wrap">
              <div className="row row-main">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="single-caption single-caption-top" >
                    <h4 style={{ color: "#fff" }}>
                      {/* {strings.become} {' '} */}
                      {/* <br /> */}
                      {/* {strings.partner}{' '} */}
                      {/* <br /> */}
                      {/* {strings.krusttyand}{' '} */}
                      {/* <br /> */}
                      {/* {strings.increaseyour}{' '} */}
                      {/* <br /> */}
                      {/* {strings.mediumTurn} */}
                      {strings.landingpartner}
                    </h4>
                    <div className="fs-text" style={{ color: "#fff" }}>
                      {/* {strings.signUpmsg} */}
                      {strings.landingpartner2}
                      {/* <br /> */}
                      {/* <br /> {strings.becomeToday} */}
                    </div>
                  </div>
                </div>
                {/* */}
                <div className="col-lg-6 col-md-4 col-sm-12 col-xs-12">
                  {/* style={{ bottom: 50, maxHeight: 600 }} */}
                  <div className="main-form main_partner_registr">
                    <div>
                      <div className="form--group">
                        <input
                          type="text"
                          value={resname}
                          name="restaurntName"
                          placeholder={strings.restroName}
                          className="form--control"
                          style={{
                            paddingTop: 12,
                            paddingBottom: 12,
                            color: "#474747",
                          }}
                          onChange={(event) => setresname(event.target.value)}
                        />
                        <div className="errorTxt errorM">
                          {simpleValidator.current.message(
                            strings.restroName,
                            resname,
                            "required"
                          )}
                        </div>
                      </div>
                      <div
                        className="form--group"
                        style={{
                         
                         
                          marginBottom: 17,
                        }}
                      >
                        <Autocomplete
                          settypes={{
                            "changetype-all": [],
                          }}
                          className="form--control"
                          // apiKey={'AIzaSyDdLk5tb75SiJvRk9F2B4almu-sBAi1-EM'}
                          apiKey={"AIzaSyC_6yUWRvwNbd3uBLVPwhIXa6keeyuBLzE"}
                          style={{
                            width: "100%",
                            paddingLeft: 15,
                            position: "relative",
                            paddingTop: 12,
                            paddingBottom: 12,
                            color: "#474747",
                          }}
                          componentRestrictions={{ country: "fr" }}
                          onPlaceSelected={(place) => onPlaceSelected(place)}
                          types={[]}
                        />
                        <div className="errorTxt errorM" style={{ marginBottom: 5 }}>
                          {simpleValidator.current.message(
                            strings.address,
                            addressshow,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className="form--group">
                        <input
                          type="text"
                          placeholder={strings.emailphone}
                          value={email}
                          name="email"
                          className="form--control"
                          style={{ paddingTop: 12, paddingBottom: 12 }}
                          onChange={(event) => setemail(event.target.value)}
                        />
                        <div className="errorTxt errorM">
                          {simpleValidator.current.message(
                            strings.emailphone,
                            email,
                            "email|required"
                          )}
                        </div>
                      </div>
                      {!showflagPicker && (
                        <div style={{ marginBottom: 15, width: "100%" }}>
                          <div
                            style={{
                              background: "#fff",
                              paddingTop: 8,
                              paddingBottom: 8,
                            }}
                          >
                            <div
                              onClick={() =>
                                setShowflagPicker(!showflagPicker)
                              }
                              className="divcenter"
                              style={{
                                width: "15%",
                                paddingLeft: 15,
                                textAlign: "center",
                                fontSize: 23,
                              }}
                            >
                              <Flag
                                height="30"
                                code={flag}
                                fallback={
                                  <Flag
                                    code={"Fr"}
                                    height="30"
                                    fallback={<span>Unknown</span>}
                                  />
                                }
                              />
                            </div>
                            <div
                              className="divcenter"
                              style={{ width: "13%", paddingLeft: 5 }}
                            >
                              {countryCode}
                            </div>
                            <div
                              className="divcenter"
                              style={{ width: "65%", paddingLeft: 5 }}
                            >
                              <input
                                type="text"
                                name="phone"
                                placeholder={strings.mobile}
                                value={phoneNumber}
                                className="countryinput"
                                onChange={(event) =>
                                  setPhoneNumber(event.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="errorTxt ">
                            {simpleValidator.current.message(
                              strings.mobile,
                              phoneNumber,
                              "required|min:9|max:11|numeric"
                            )}
                            {simpleValidator.current.message(
                              "countryCode",
                              countryCode,
                              "required"
                            )}
                          </div>
                        </div>
                      )}
                      {showflagPicker && (
                        <div className="form--group" ref={wrapperRef}>
                          <input
                            style={{
                              width: "100%",
                              paddingLeft: 20,
                              paddingTop: 12,
                              paddingBottom: 12,
                            }}
                            type="text"
                            placeholder={searchPlaceh}
                            value={searchval}
                            className="countryinput"
                            onChange={(event) =>
                              onCountryCodeSearch(event.target.value)
                            }
                          />
                          <div
                            style={{
                              height: 160,
                              width: "100%",
                              minHeight: 100,
                              overflowY: "scroll",
                              backgroundColor: "#ffffff",
                              position: "absolute",
                              zIndex: 1,
                            }}
                          >
                            {countriesData.map((country, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() => onPressFlagPicker(country)}
                                  style={{
                                    color: "#OOO",
                                    width: "100%",
                                    cursor: "pointer",
                                    padding: 5,
                                    textAlign: "left",
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    paddingLeft: 20,
                                  }}
                                >
                                  <Flag
                                    code={country.code}
                                    height="16"
                                    width="50"
                                    fallback={<span>Unknown</span>}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;{country.name}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      <div className="form--group">
                        <input
                          type="password"
                          value={password}
                          name="password"
                          placeholder={strings.choosePassword}
                          className="form--control"
                          style={{ paddingTop: 12, paddingBottom: 12 }}
                          onChange={(event) =>
                            setpassword(event.target.value)
                          }
                        />
                        <div className="errorTxt errorM">
                          {simpleValidator.current.message(
                            strings.password,
                            password,
                            "required"
                          )}
                          {errorMessage}
                        </div>
                      </div>
                      <div className="form--group">
                        {/* <p>
                          {strings.byClick}{" "}
                          <a>{strings.termsandconditions}</a>{" "}
                          {strings.readPolicy}
                        </p> */}
                        <p>
                          {strings.byClick}{" "}
                          <a>{strings.byClick2}</a>{" "}
                          {strings.generalsales}
                        </p>
                      </div>
                      <div className="form--group">
                        <input
                          style={{ padding: `15px 25px`}}
                          onClick={() => onRestaurantReg()}
                          type="button"
                          defaultValue={strings.toSend}
                          className="btn-submit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        hasPadding={1}
      />
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import Footer from "../footer/footerApp";
import Header from "../header/header";
import SideNav from "../sideNav/sideNav";
import { useHistory } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Popup from "reactjs-popup";
import { apiCall, setDefaultHeader } from "../utils/httpClient";
import ApiEndPoints from "../utils/apiEndPoints";
import _ from "lodash";
import { useAlert } from "react-alert";
import Autocomplete from "react-google-autocomplete";
import Flag from "react-world-flags";
import countries from "../../component/countries";
import "./itemadd.css";
import strings from "../../language";
export default (props) => {
  const alert = useAlert();
  const wrapperRef = useRef();
  const history = useHistory();
  const [, forceUpdate] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [dropType, setDropType] = useState(false);

  const [cuisinesData, setCuisinesData] = useState([]);
  const [foodChoiceData, setFoodChoiceData] = useState([]);
  const [typeOfserviceData, setTypeOfserviceData] = useState([]);
  const [dishData, setDishData] = useState([]);
  const [imageForSave, setImageForSave] = useState([]);

  const [dropDownData, setDropDownData] = useState([]);
  const [serachDropDownData, setSerachDropDownData] = useState([]);

  const [cuisinesVal, setCuisinesVal] = useState([]);
  const [serviceVal, setServiceVal] = useState([]);
  const [foodChoiceVal, setFoodChoiceVal] = useState([]);
  const [dishVal, setDishVal] = useState([]);

  const [imageFordelete, setImageFordelete] = useState([]);

  const [dropDownVal, setDropDownVal] = useState([]);
  const [times, setTime] = useState([{ openTime: "", closeTime: "" }]);

  const [imageArray, setImageArray] = useState([]);
  const [imageArrays, setImageArrays] = useState([]);

  const [restaurantName, setRestaurantName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [serviceIn, setServiceIn] = useState("");
  const [paymetdataMethod, setPaymetDataMethod] = useState("");
  const [capacity, setCapacity] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const [parking, setParking] = useState(0);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ownerDescription, setOwnerDescription] = useState("");
  const [ownerDetails, setOwnerDetails] = useState("");
  const [imgUrl, setImgUrl] = useState("");

  const [flag, setFlag] = useState("fr");
  const [showflagPicker, setShowflagPicker] = useState(false);
  const [searchval, setsearchval] = useState("");
  const [searchPlaceh, setsearchPlaceh] = useState("France");
  const [countriesData, setcountriesData] = useState(countries);

  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [paymentmethod,setpaymentmethod] =useState("");
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [paymentArray, setPaymentArray] = useState([
    {
      id: "1",
      label: strings.Online,
      img: "../images/debitcard.png",
    },
    {
      id: "2",
      label: strings.Cash,
      img: "../images/money.png",
    },
    {
      id: "3",
      label:strings.Paylater,
      img: "../images/cardmachine.png",
    },
    {
      id: "4",
      label:strings.Voucher,
      img: "../images/voucher.png",
    },
  ]);

  useEffect(() => {
    getRestaurantTypeOf();
  }, [imageArray]);

  const getRestaurantTypeOf = async () => {
    try {
      const dataUser = await JSON.parse(localStorage.getItem("userdata"));
      var userdata = await JSON.parse(localStorage.getItem("userdata")).data;
      const openTime = userdata.open_time.split(",");
      const closeTime = userdata.close_time.split(",");
      const timeData = openTime[2]
        ? [
          {
            openTime: openTime[0],
            closeTime: closeTime[0],
          },
          {
            openTime: openTime[1],
            closeTime: closeTime[1],
          },
          {
            openTime: openTime[2],
            closeTime: closeTime[2],
          },
        ]
        : openTime[1]
          ? [
            {
              openTime: openTime[0],
              closeTime: closeTime[0],
            },
            {
              openTime: openTime[1],
              closeTime: closeTime[1],
            },
          ]
          : openTime[0]
            ? [
              {
                openTime: openTime[0],
                closeTime: closeTime[0],
              },
            ]
            : [];
      setTime(timeData);
      setImageArrays(userdata.restroGalleryImage);
      setImgUrl(dataUser.imgpath);
      setRestaurantName(userdata.name);
      setAddress(userdata.address);
      setEmail(userdata.email);
      setWebsite(userdata.web_restro);
      setInstagram(userdata.web_instagram);
      setFacebook(userdata.web_facebook);
      setServiceIn(userdata.prepration_time);
      setCapacity(userdata.capacity);
      setPriceRange(userdata.price_range);
      setParking(userdata.parking);
      console.log("userdata.parking",userdata.parking)
      setCountryCode("+" + userdata.country_code);
      setPhoneNumber(userdata.phone);
      setOwnerDescription(userdata.description);
      setOwnerDetails(userdata.owner_details);
      setlatitude(userdata.latitude);
      setlongitude(userdata.longitude);
      setPaymetDataMethod(userdata.payment_type);
      setCuisinesVal(userdata.cuisines);
      setServiceVal(userdata.type_of_service);
      setFoodChoiceVal(userdata.food_choices);
      const token = await localStorage.getItem("token");
      const lang = await localStorage.getItem("locale");
      const data = await apiCall(
        "Get",
        ApiEndPoints.getRestaurantTypeOf + "?lang=" + lang,
        {
          Accept: "application/json, text/plain, */*",
          token: token,
        }
      );
      if (data.data.status == 200) 
      {
        setCuisinesData(data.data.data.type_of_cuisines) ;
        setFoodChoiceData(data.data.data.food_choice);
        setTypeOfserviceData(data.data.data.type_of_service);
        setDishData(data.data.data.type_of_item);
        setpaymentmethod(data.data.data.payment_method)
      }
    } catch (error) {
      console.log(error);
    }
  };

 

  const handlePayment = (item, index) => {
    const newData = [...paymentArray];
    newData[index].status = !newData[index].status;
    setPaymentArray(newData);
  };
  const savePayment = (item, index) => {
    // const payemtnVal = _.filter(paymentArray, { status: true });
    // setPaymetDataMethod(_.map(payemtnVal, "label").toString());
    setPaymentPopup(false);
  };

  const handleDropDown = (item, index) => {
    const newData = [...dropDownData];
    newData[index].status = !newData[index].status;
    setDropDownData(newData);
  };

  const handleSearch = (serachVal) => {
    var specialArray = _.filter(serachDropDownData, (row) =>
      row.label.toLowerCase().match(serachVal.toLowerCase())
    );
    setDropDownData(specialArray);
  };

  const openPopup = (type, data) => {
    console.log("type",type);
    console.log("data data",data);
    setDropDownData(data);
    setSerachDropDownData(data);
    setDropType(type);
    setShowPopup(!showPopup);
  };

  const saveDropVal = () => {
   
    if (dropType == "cuisines") {
      setCuisinesVal(_.filter(dropDownData, { status: true }));
      setDropDownData([]);
    } else if (dropType == "service") {
      setServiceVal(_.filter(dropDownData, { status: true }));
    } else if (dropType == "paymentMethod") {
      setPaymetDataMethod(_.filter(dropDownData, { status: true }));
    }else if (dropType == "choices") {
      setFoodChoiceVal(_.filter(dropDownData, { status: true }));
    }
     else if (dropType == "dish") {
      setDishVal(_.filter(dropDownData, { status: true }));
    } else if (dropType == "servicetime") {
    }
    setDropDownVal([]);
    setShowPopup(!showPopup);
  };

  const onPlaceSelected = (place) => {
    if (place.geometry) {
      // const latValue = place.geometry.location.lat();
      // const lngValue = place.geometry.location.lng();
      setlatitude(place.geometry.location.lat());
      setlongitude(place.geometry.location.lng());
      setAddress(place.formatted_address);
    }
  };

  const handleImage = (e) => {
    const fileObj = [];
    const fileArray = [];
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(fileObj[0]);
    }
    setImageForSave(fileArray);
  };

  const handleRemoveClick = (index) => {
    const list = [...times];
    list.splice(index, 1);
    setTime(list);
  };

  const handleAddClick = () => {
    setTime([...times, { openTime: "", closeTime: "" }]);
  };

  function validateAllField() {
    if (!restaurantName) {
      alert.show(strings.enteraddress, {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }
    if (!address) {
      alert.show(strings.enteraddress, {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }
    if (!email) {
      alert.show(strings.enteremail, {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }
    if (!phoneNumber) {
      alert.show(strings.entermobile, {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }
    if (cuisinesVal.length <= 0) {
      alert.show(strings.selectcuisines, {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }

    if (serviceVal.length <= 0) {
      alert.show(strings.selectService, {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }
    if (!serviceIn) {
      alert.show(`${strings.selectService} in`, {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }

    {/*  if (!capacity) {
      alert.show("Please enter capacity", {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    } */}

    if (!paymetdataMethod) {
      alert.show(strings.selectPayment, {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }
    if (!priceRange) {
      alert.show(strings.pricescale, {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }
    return true;
  }
  const editMyDetails = () => {
  
    const isValid = validateAllField();
    if (isValid) {
      editMDetails();
    }
  };

  const editMDetails = async () => {
   
    try {

    
      let formdata = new FormData(); //formdata object
      formdata.append("name", restaurantName);
      formdata.append("address", address);
      formdata.append("email", email);
      formdata.append("phone", phoneNumber);
      formdata.append("latitude", latitude);
      formdata.append("longitude", longitude);

      formdata.append("country_code", countryCode);

      formdata.append("open_time", _.map(times, "openTime").toString());
      formdata.append("close_time", _.map(times, "closeTime").toString());

      formdata.append("capacity", capacity);
      formdata.append("price_range", priceRange);
      formdata.append("preprationTime", serviceIn);

      formdata.append("cuisines", _.map(cuisinesVal, "id").toString());
      formdata.append("type_of_service", _.map(serviceVal, "id").toString());
      formdata.append("food_choices", _.map(foodChoiceVal, "id").toString());

      formdata.append("owner_details", ownerDetails);
      formdata.append("description", ownerDescription);
      formdata.append("fr_owner_details:", ownerDescription);
      formdata.append("fr_description", ownerDetails);

      formdata.append("web_restro", website);
      formdata.append("web_facebook", facebook);
      formdata.append("web_instagram", instagram);

      formdata.append("deleteimage", imageFordelete.toString());
      formdata.append("parking", parking);
      const langg = await localStorage.getItem("locale");
      formdata.append("lang", langg);
      formdata.append("payment_type", _.map(paymetdataMethod, "id").toString());
      imageForSave.map((file, index) => {
        formdata.append("restro_gallery_image", file[index], file[index].name);
      });
      const data = await apiCall("post", ApiEndPoints.editMyDetails, formdata, {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      });
      if (data.data.status == 200) {
        setAsync(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  async function setAsync(data) {
    alert.show(strings.successmsg, {
      timeout: 2000, // custom timeout just for this one alert
      type: "success",
      onOpen: () => { }, // callback that will be executed after this alert open
      onClose: () => { }, // callback that will be executed after this alert is removed
    });

    setImageArrays(data.data.restroGalleryImage);
    await localStorage.setItem("userdata", JSON.stringify(data));
  }
  const deleteImage = async (id, photos) => {
    setImageArrays(photos);
    imageFordelete.push(id);
    setImageFordelete(imageFordelete);
    // props.onPressImageDelete(photo.id, phots)
    // const img = imageArray;
    // img.splice(i, 1);
    // setImageArray(img)
    // const { data } = await apiCall('get',
    //   ApiEndPoints.itemImgdelete + '?id=' + id)
    // if (data.status == 200) {
    //   alert.show('Image delete Successfully', {
    //     timeout: 2000, // custom timeout just for this one alert
    //     type: 'success',
    //     onOpen: () => {
    //       console.log('hey')
    //     }, // callback that will be executed after this alert open
    //     onClose: () => {
    //       console.log('closed')
    //     } // callback that will be executed after this alert is removed
    //   })
    // }
  };
  function handleOpenTime(e, i) {
    times[i].openTime = e.target.value;
    setTime(times);
  }
  const handleCloseTime = (e, i) => {
    times[i].closeTime = e.target.value;
    setTime(times);
  };

  const onCountryCodeSearch = async (searchText) => {
    setsearchval(dataArray2);
    let data = await countries;
    searchText = searchText.trim().toLowerCase();
    let dataArray2 = data.filter((l) => {
      return l.name.toLowerCase().match(searchText);
    });
    setcountriesData(dataArray2);
  };
  const onPressFlagPicker = (country) => {
    setShowflagPicker(!showflagPicker);
    setCountryCode(country.dial_code);
    setFlag(country.code);
    setcountriesData(countries);
    setsearchPlaceh(country.name);
  };
  return (
    <>
      <SideNav />
      <div className="content-wrapper bg-gray-light">
        {/* Main content */}
        <div
          className="content p-0 pt-3"
          style={{ height: "90vh", overflow: "auto" }}
        >
          <div className="container-fluid">
            <div className="row m-0">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form-horizontal">
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.restroName}{" "}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={strings.restroName}
                            defaultValue={restaurantName}
                            onChange={(event) =>
                              setRestaurantName(event.target.value)
                            }
                          />
                        </div>

                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.disOwner}
                          </label>
                          <input
                            type="text"
                            placeholder={strings.disOwner}
                            className="form-control"
                            defaultValue={ownerDescription}
                            onChange={(event) =>
                              setOwnerDescription(event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.eamiladdress}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={strings.eamiladdress}
                            defaultValue={email}
                            onChange={(event) => setEmail(event.target.value)}
                          />
                        </div>

                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.detailsowner}
                          </label>
                          <input
                            type="text"
                            placeholder={strings.detailsowner}
                            className="form-control"
                            defaultValue={ownerDetails}
                            onChange={(event) =>
                              setOwnerDetails(event.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.mobileno}
                            <span className="required">*</span>
                          </label>
                          {!showflagPicker && (
                            <div style={{ marginBottom: 15, width: "100%" }}>
                              <div
                                style={{
                                  background: "#fff",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                }}
                              >
                                <div
                                  onClick={() =>
                                    setShowflagPicker(!showflagPicker)
                                  }
                                  className="divcenter"
                                  style={{
                                    width: "15%",
                                    paddingLeft: 15,
                                    textAlign: "center",
                                    fontSize: 23,
                                  }}
                                >
                                  <Flag
                                    height="30"
                                    code={flag}
                                    fallback={
                                      <Flag
                                        code={"Fr"}
                                        height="30"
                                        fallback={<span>Unknown</span>}
                                      />
                                    }
                                  />
                                </div>
                                <div
                                  className="divcenter"
                                  style={{ width: "13%", paddingLeft: 5 }}
                                >
                                  {countryCode}
                                </div>
                                <div
                                  className="divcenter"
                                  style={{ width: "65%", paddingLeft: 5 }}
                                >
                                  <input
                                    type="text"
                                    name="phone"
                                    placeholder={strings.mobileno}
                                    value={phoneNumber}
                                    className="countryinput"
                                    onChange={(event) =>
                                      setPhoneNumber(event.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {showflagPicker && (
                            <div className="form--group" ref={wrapperRef}>
                              <input
                                style={{
                                  width: "100%",
                                  paddingLeft: 20,
                                  paddingTop: 12,
                                  paddingBottom: 12,
                                }}
                                type="text"
                                // placeholder={searchPlaceh}
                                // value={searchval}
                                className="countryinput"
                                onChange={(event) =>
                                  onCountryCodeSearch(event.target.value)
                                }
                              />
                              <div
                                style={{
                                  height: 160,
                                  width: "100%",
                                  minHeight: 100,
                                  overflowY: "scroll",
                                  backgroundColor: "#ffffff",
                                  position: "absolute",
                                  zIndex: 1,
                                }}
                              >
                                {countriesData.map((country, index) => {
                                  return (
                                    <div
                                      key={index}
                                      onClick={() => onPressFlagPicker(country)}
                                      style={{
                                        color: "#OOO",
                                        width: "100%",
                                        cursor: "pointer",
                                        padding: 5,
                                        textAlign: "left",
                                        paddingTop: 12,
                                        paddingBottom: 12,
                                        paddingLeft: 20,
                                      }}
                                    >
                                      <Flag
                                        code={country.code}
                                        height="16"
                                        width="50"
                                        fallback={<span>Unknown</span>}
                                      />
                                      &nbsp;&nbsp;&nbsp;&nbsp;{country.name}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.instagram}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={instagram}
                            placeholder={strings.instagram}
                            onChange={(event) =>
                              setInstagram(event.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.address}
                            <span className="required">*</span>
                          </label>
                          <Autocomplete
                            // setTypes={{
                            //   'changetype-all': [],
                            // }}
                            className="form-control"
                            apiKey={"AIzaSyC_6yUWRvwNbd3uBLVPwhIXa6keeyuBLzE"}
                            style={{
                              width: "100%",
                              paddingLeft: 15,
                              position: "relative",
                              paddingTop: 12,
                              paddingBottom: 12,
                              color: "#474747",
                              fontFamily: "Roboto-Light",
                            }}
                            defaultValue={address}
                            placeholder={strings.address}
                            componentRestrictions={{ country: "fr" }}
                            onPlaceSelected={(place) => onPlaceSelected(place)}
                            types={[]}
                          />
                        </div>

                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.website}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={website}
                            placeholder={strings.website}
                            onChange={(event) => setWebsite(event.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.typeOfCusinies}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="button"
                            onClick={() => openPopup("cuisines", cuisinesData)}
                            style={{ textAlign: "left" }}
                            className="form-control"
                            defaultValue={
                              cuisinesVal.length > 0
                                ? _.map(cuisinesVal, "label").toString()
                                : strings.typeOfCusinies
                            }
                          />
                        </div>

                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.facebook}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={strings.facebook}
                            defaultValue={facebook}
                            onChange={(event) =>
                              setFacebook(event.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.typeOfService}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="button"
                            style={{ textAlign: "left" }}
                            className="form-control"
                            onClick={() =>
                              openPopup("service", typeOfserviceData)
                            }
                            defaultValue={
                              serviceVal.length > 0
                                ? _.map(serviceVal, "label").toString()
                                : strings.typeOfService
                            }
                          />
                        </div>

                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.capacity}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={strings.capacity}
                            defaultValue={capacity}
                            onChange={(event) =>
                              setCapacity(event.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.paymetMethodName}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="button"
                            className="form-control"
                            style={{ textAlign: "left" }}
                            // defaultValue={
                            //   paymetMethod
                            //     ? paymetMethod
                            //     : strings.paymetMethodName
                            // }
                            defaultValue={
                              paymetdataMethod.length > 0
                                ? _.map(paymetdataMethod, "label").toString()
                                :  strings.paymetMethodName
                            }
                            //onClick={() => setPaymentPopup(true)}
                            onClick={() => openPopup("paymentMethod", paymentmethod)}
                          />
                        </div>
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.choiceofFood}
                          </label>
                          <input
                            type="button"
                            style={{ textAlign: "left" }}
                            className="form-control"
                            defaultValue={
                              foodChoiceVal.length > 0
                                ? _.map(foodChoiceVal, "label").toString()
                                : strings.choiceofFood
                            }
                            onClick={() => openPopup("choices", foodChoiceData)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.pricescale}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={strings.pricescale}
                            defaultValue={priceRange}
                            onChange={(event) =>
                              setPriceRange(event.target.value)
                            }
                          />
                        </div>
                        <div className="col-sm-6">
                          <label className="col-sm-1 col-form-label">
                            {/* {strings.buyoneget} */}
                          </label>
                          <div className="row">
                            <label className="col-sm-4 col-form-label text-center">
                              {strings.parking}
                            </label>
                            <div className="col-sm-2">
                            {parking==1? <input
                                style={{ width: 25 }}
                                type="radio"
                                className="form-control"
                                placeholder={strings.parking}
                                value={parking}
                                checked={1}
                                onChange={(event) => setParking(0)}
                              />:
                              <input
                                style={{ width: 25 }}
                                type="radio"
                                className="form-control"
                                placeholder={strings.parking}
                                value={parking}
                                checked={0}
                                onChange={(event) => setParking(1)}
                              />}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.servicein}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={strings.servicein}
                            value={serviceIn}
                            onChange={(event) =>
                              setServiceIn(event.target.value)
                            }
                          />
                        </div>
                        {/* <label className="col-sm-3 col-form-label">
                          &nbsp;
                        </label> */}
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.gallery}
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="customFile"
                            onChange={(event) => handleImage(event)}
                            multiple
                          />
                        </div>
                      </div>

                      {times.map((x, i) => {
                        return (
                          <div
                            key={i}
                            className="form-group row"
                          >
                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                              <label className="col-form-label">
                                {strings.openTime}
                              </label>
                              <input
                                type="time"
                                className="form-control text-center"
                                placeholder="Option"
                                onChange={(e) =>
                                  handleOpenTime(e, i)
                                }
                                defaultValue={
                                  x.openTime
                                }
                              />

                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                              <label className="col-form-label">
                                {strings.closeTime}
                              </label>
                              <input
                                type="time"
                                className="form-control text-center"
                                placeholder="Price"
                                onChange={(e) =>
                                  handleCloseTime(e, i)
                                }
                                defaultValue={
                                  x.closeTime
                                }
                              />
                            </div>
                            <div className="col-sm-2 row align-items-center justify-content-center">
                              {times.length !== 1 && (
                                <button
                                  type="button"
                                  style={{
                                    marginRight: 10,
                                    marginTop: 30
                                  }}
                                  className="btn btn-danger btn-md"
                                  onClick={() =>
                                    handleRemoveClick(i)
                                  }
                                >
                                  <i className="fa fa-minus" />
                                </button>
                              )}
                              {times.length - 1 ===
                                i && (
                                  <button
                                    type="button"
                                    className="btn btn-success btn-md"
                                    style={{
                                      marginTop: 30
                                    }}
                                    onClick={() =>
                                      handleAddClick()
                                    }
                                  >
                                    <i className="fa fa-plus" />
                                  </button>
                                )}
                            </div>
                          </div>
                        );
                      })}
                      <div className="form-group row">
                        <label className="col-form-label">
                          &nbsp;
                        </label>
                        <div className="col-sm-10 row">
                          {imageArrays.map((item, i) => {
                            return (
                              <div key={i} className="col-sm-3">
                                <div
                                  onClick={() => {
                                    const photos = [...imageArrays];
                                    photos.splice(i, 1);
                                    deleteImage(item.id, photos);
                                  }}
                                >
                                  <img
                                    src="../images/cancel.png"
                                    style={{
                                      position: "absolute",
                                      height: 20,
                                      width: 20,
                                      left: 5,
                                      top: 5,
                                    }}
                                  />
                                </div>
                                <img
                                  src={imgUrl + "/" + item.file_name}
                                  style={{
                                    height: 180,
                                    width: 180,
                                    resize: "block",
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <label className="col-form-label">
                          &nbsp;
                        </label>
                      </div>
                      <div className="form-group row">
                        <label className="col-form-label">
                          &nbsp;
                        </label>
                        <div className="col-sm-11">
                          <button
                            onClick={() => editMyDetails()}
                            type="button"
                            className="btn btn-block btn-success btn-lg"
                          >
                            {strings.save}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        open={showPopup}
        closeOnDocumentClick
        onClose={() => setShowPopup(false)}
        contentStyle={{ maxWidth: 300 }}
      >
        <div
          style={{
            height: 360,
            minHeight: 100,
          }}
        >
          <input
            type="text"
            placeholder="Search"
            style={{
              height: 50,
              width: "80%",
              borderWidth: 0,
              paddingLeft: 14,
            }}
            onChange={(event) => handleSearch(event.target.value)}
          />
          <div
            className="scroll-height"
            style={{
              height: 255,
              overflowY: "scroll",
              overflowX: "hidden",
              minHeight: 100,
              padding: 10,
            }}
          >
            {dropDownData.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleDropDown(item, index)}
                  style={{
                    height: 40,
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: 250,
                  }}
                >
                  <label>
                    {item.status}
                    {item.label}
                  </label>
                  {item.status ? (
                    <img
                      src="../images/fill-checkbox.png"
                      style={{ height: 30, width: 30 }}
                    />
                  ) : (
                    <img
                      src="../images/checkbox.png"
                      style={{ height: 30, width: 30 }}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">&nbsp;</label>
            <div className="col-sm-8">
              <button
                onClick={() => saveDropVal()}
                type="button"
                className="btn btn-block btn-success btn-lg"
              >
                {strings.save}
              </button>
            </div>
            <label className="col-sm-2 col-form-label">&nbsp;</label>
          </div>
        </div>
      </Popup>
      <Popup
        open={paymentPopup}
        closeOnDocumentClick
        onClose={() => setPaymentPopup(false)}
      >
        <div
          style={{
            height: 360,
            minHeight: 100,
          }}
        >
          <div
            className="scroll-height"
            style={{
              height: 305,
              overflowY: "auto",
              overflowX: "hidden",
              minHeight: 100,
              padding: 10,
            }}
          >
            {paymentArray.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handlePayment(item, index)}
                  style={{
                    height: 40,
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: 250,
                  }}
                >
                  <img src={item.img} style={{ height: 30, width: 30 }} />

                  <label>
                    {item.status}
                    {item.label}
                  </label>
                  {item.status ? (
                    <img
                      src="../images/fill-checkbox.png"
                      style={{ height: 30, width: 30 }}
                    />
                  ) : (
                    <img
                      src="../images/checkbox.png"
                      style={{ height: 30, width: 30 }}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">&nbsp;</label>
            <div className="col-sm-8">
              <button
                onClick={() => savePayment()}
                type="button"
                className="btn btn-block btn-success btn-lg"
              >
                {strings.save}
              </button>
            </div>
            <label className="col-sm-2 col-form-label">&nbsp;</label>
          </div>
        </div>
      </Popup>
      <Footer />
    </>
  );
};

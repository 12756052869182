import React, { useEffect, useState } from 'react';
import String from '../../language/index';
export default (props) => {

return(
<div className="col-lg-12" >
<div style={{ width: '100%', padding: 10, display: 'flex', marginBottom: 10 }}>
  <div style={{ width: '60%' }}>
    <div> <img width="150" height="100" src="/images/cta-logo.png" />
    </div>
    <div style={{ marginTop: 20, fontFamily: "sans-serif" }}>
    
      <h3 style={{ fontWeight: 700, margin: 0 }}>{props.name} </h3>

      {props.address.map((x, i) => 
          {
            return(
              <p style={{ margin: 0, fontSize: 20, fontWeight: 500 }}>{x}
      </p>)
           })}
      
    </div>
  </div>
</div>
<div>
  <div style={{ width: '100%', marginTop: 10, marginBottom: 10}}>
    <div style={{ padding: 5, margin: 5 }}>
      <div style={{ overflow: 'hidden', outline: 'none' }} tabIndex={0}>
        <table style={{ display: 'table', border: '1px solid #ddd', width: '100%', maxWidth: '100%', marginBottom: 20, backgroundColor: 'transparent', borderCollapse: 'collapse', borderSpacing: 0, textIndent: 'initial', whiteSpace: 'normal', lineHeight: 'normal', fontWeight: 'normal', fontSize: 'medium', fontStyle: 'normal', color: '-internal-quirk-inherit', textAlign: 'start' }}>
          <thead style={{ lineHeight: 2 }}>
            <tr style={{ color: 'black',backgroundColor:"#DCDCDC", display: 'table-row', verticalAlign: 'inherit', borderColor: 'inherit' }}>
              <th style={{ borderTop: 0,fontSize:20, borderBottomWidth: 2, border: '1px solid #ddd' }} className="per70 text-center">{String.Order}#</th>
              <th style={{ borderTop: 0, fontSize:20,borderBottomWidth: 2, border: '1px solid #ddd' }} className="per70 text-center">{String.datetime} </th>
              <th style={{ borderTop: 0,fontSize:20, borderBottomWidth: 2, border: '1px solid #ddd' }} className="per70 text-center">{String.ItemName}</th>
              <th style={{ borderTop: 0,fontSize:20,borderBottomWidth: 2, border: '1px solid #ddd' }} className="per70 text-center">{String.Quantity}</th>
              <th style={{ borderTop: 0,fontSize:20, borderBottomWidth: 2, border: '1px solid #ddd' }} className="per70 text-center">{String.foodchoice}</th>
              <th style={{ borderTop: 0,fontSize:20, borderBottomWidth: 2, border: '1px solid #ddd' }} className="per70 text-center">{String.Price}</th>
              <th style={{ borderTop: 0, fontSize:20,borderBottomWidth: 2, border: '1px solid #ddd' }} className="per70 text-center">TVA</th>
              <th style={{ borderTop: 0,fontSize:20,borderBottomWidth: 2, border: '1px solid #ddd' }} className="per70 text-center">{String.Earning}</th>
            </tr>
          </thead>
          <tbody>
         
          {props.dataArray.map((x, i) => 
          {
            return(
            <tr>
              <td style={{lineHeight: '2.42857143',fontSize:20,verticalAlign: 'center',textAlign:"center",borderTop: '1px solid #ddd' }}>
             <b> {x.order_id} </b></td>
             <td style={{ lineHeight: '2.42857143',fontSize:20, verticalAlign: 'center',textAlign:"center", borderTop: '1px solid #ddd' }}>
             <b> {x.date_time} </b></td>
             <td style={{ lineHeight: '2.42857143',fontSize:20, verticalAlign: 'center',textAlign:"center", borderTop: '1px solid #ddd' }}>
             <b> {x.item_name} </b></td>
             <td style={{ lineHeight: '2.42857143',fontSize:20, verticalAlign: 'center',textAlign:"center", borderTop: '1px solid #ddd' }}>
             <b> {x.item_quantity} </b></td>
             <td style={{ ineHeight: '2.42857143',fontSize:20, verticalAlign: 'center', textAlign:"center",borderTop: '1px solid #ddd' }}>
             <b> {x.food_choice} </b></td>
              <td style={{lineHeight: '2.42857143',fontSize:20, verticalAlign: 'center', borderTop: '1px solid #ddd' }}>
              {x.pricee}</td>
              <td style={{lineHeight: '2.42857143',fontSize:20, verticalAlign: 'center', borderTop: '1px solid #ddd' }}>
              {x.TVA}</td>
              <td style={{lineHeight: '2.42857143',fontSize:20, verticalAlign: 'center', borderTop: '1px solid #ddd' }}>
              {parseFloat(x.price - x.TVA_per).toFixed(2)}€</td>
              
            </tr>)
           })}
           <tr>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
        <td style={{textAlign:"center",height:"30",fontSize:22}}><b>TOTAL:</b></td>
        <td  style={{lineHeight: '2.42857143',fontSize:22, verticalAlign: 'center', borderTop: '1px solid #ddd' }}><b>{props.calorieTotal}€</b></td>
        <td style={{lineHeight: '2.42857143',fontSize:22, verticalAlign: 'center', borderTop: '1px solid #ddd' }}><b>{props.tvssTotal}€</b></td>
        <td style={{lineHeight: '2.42857143',fontSize:22, verticalAlign: 'center', borderTop: '1px solid #ddd' }}><b>{parseFloat(props.calorieTotal -props.tvssTotal).toFixed(2)}€</b></td>
         </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</div>
)
}
export default {
    JWTToken: 'v1/tokenGenrate/JWTToken',
    loginCustomer: 'v1/customer/auth/loginCustomer',
    customerRegistration: 'v1/customer/auth/customerRegistration',
    loginVerifyCustomer: 'v1/customer/auth/loginVerify',
    otpVerifyCustomer: 'v1/customer/auth/verifyCustomer',
    forgotPassCustomer: 'v1/customer/auth/forgotPassCustomer',
    forgotPasswordUpdate: 'v1/customer/auth/forgotPasswordUpdate',
    resendotp: 'v1/customer/auth/reSendOtp',
    socialLogin: 'v1/customer/auth/socialSiteLogin',
    restroRegistration: 'v1/restaurant/auth/signuprestaurant',
    restrootpverify: 'v1/restaurant/auth/activate',
    logirestro: "v1/restaurant/auth/loginrestraurant",
    otpresendrestro: '/v1/restaurant/auth/reSendOtpForRestro',
    forgotpassRestro: '/v1/restaurant/auth/forgotPassResto',
    forgotUpdateRestro: '/v1/restaurant/auth/forgotPassUpdateResto',


    getRestaurantTypeOf: '/v1/master/getRestaurantTypeOf',


    getitemslist: '/v1/restaurant/getitemslist',
    saveItem: '/v1/restaurant/addEditItem',
    itemImgdelete: '/v1/restaurant/deleteItemImg',
    changeItemStatus: '/v1/restaurant/changeitemstatus',
    deleteItem:"/v1/restaurant/deleteItem",
    getAddonlist:"/v1/master/addon_item_list",
    addAddon:'/v1/master/addAddOnUrl',
    saveAddon:'/v1/master/itemsAddon',
    deleteAddon:'/v1/master/addon_item_delete',
    editMyDetails:'/v1/restaurant/updaterestraurant',
    getOrderList:'/v1/order/getOrderList',
    getOrderListSearch:'/v1/order/getOrderListSearch',

    changerestrostatus:"/v1/restaurant/changerestrostatus",
    dishlistapi:"/v1/restaurant/dishtypelist",
    addupdateItemorder:"/v1/restaurant/addItemorder",
    
}
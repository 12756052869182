import React, { useState, useEffect } from "react";
import { StyleSheet, View, Platform, Animated } from "react-native";

import Routes from "./js/Navigation/webNavigation";
import EndPoints from "./js/Screen/utils/apiEndPoints";
import { apiCall, setDefaultHeader } from "./js/Screen/utils/httpClient";
import detectBrowserLanguage from "detect-browser-language";

import * as firebase from "firebase/app";

import { UserProvider } from "./js/component/Context/UserContext";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";

export default (props) => {
  var firebaseConfig = {
    apiKey: "AIzaSyC_6yUWRvwNbd3uBLVPwhIXa6keeyuBLzE",
    authDomain: "krustty-6de1e.firebaseapp.com",
    databaseURL: "https://krustty-6de1e.firebaseio.com",
    projectId: "krustty-6de1e",
    storageBucket: "krustty-6de1e.appspot.com",
    messagingSenderId: "22274154613",
    appId: "1:22274154613:web:f46b74a4a7999fc638983c",
    measurementId: "G-1N1BWJJX5D",
  };
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  // firebase.initializeApp(firebaseConfig);
  SimpleReactValidator.addLocale("fr", {
    accepted: "Le :attribute doit être accepté.",
    after: "Le :attribute doit être après :date.",
    after_or_equal: "Le :attribute doit correspondre ou bien être après :date.",
    alpha: "Le :attribute ne peut contenir que des lettres.",
    alpha_space:
      "Le :attribute ne peut contenir que des lettres et des espaces.",
    alpha_num:
      "Le :attribute ne peut contenir que des lettres et des chiffres.",
    alpha_num_space:
      "Le :attribute ne peut contenir que des lettres, chiffres, et espaces.",
    alpha_num_dash:
      "Le :attribute ne peut contenir que des lettres, chiffres, et tirets.",
    alpha_num_dash_space:
      "Le :attribute ne peut contenir que des lettres, chiffres, tirets, et espaces.",
    array: "Le :attribute doit êttre un tableau.",
    before: "Le :attribute doit être avant :date.",
    before_or_equal:
      "Le :attribute doit correspondre ou bien être avant :date.",
    between: "Le :attribute doit être entre :min et :max:type.",
    boolean: "Le :attribute doit être booléen.",
    card_exp: "Le :attribute doit être une date d'expiration valide.",
    card_num: "Le :attribute doit être un numéro valide de carte de crédit .",
    currency: "Le :attribute doit être une devise valide.",
    date: "Le :attribute doit être une date.",
    date_equals: "Le :attribute doit correspondre à :date.",
    email: "Le :attribute doit êre une adresse email valide.",
    in: "Le selectionné :attribute doit être :values.",
    integer: "Le :attribute doit être un entier.",
    max: "Le :attribute ne doit pas dépasser :max:type.",
    min: "Le :attribute doit au moins être :min:type.",
    not_in: "Le selectionné :attribute ne doit pas être :values.",
    not_regex: "Le :attribute ne doit pas correspondre au motif requis.",
    numeric: "Le :attribute doit être un chiffre.",
    phone: "Le :attribute doit être un numéro de téléphone valide.",
    regex: "Le :attribute doit correspondre au motif requis.",
    required: "Saisissez le :attribute",
    size: "Le :attribute doit être :size:type.",
    string: "Le :attribute doit être une chaîne.",
    typeof: "Le :attribute n'est pas le type correcte de :type.",
    url: "Le :attribute doit être un url.",
  });

  const [auth, setauth] = React.useState();
  const [userdata, setuserdata] = React.useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  useEffect(() => {
    let userToken;
    const setdata = async () => {
      await localStorage.setItem("locale", detectBrowserLanguage());
      await localStorage.setItem("lang", detectBrowserLanguage());
      userToken = await localStorage.getItem("Auth");
      const userdata = JSON.parse(await localStorage.getItem("userdata"));
      setuserdata(userdata);
      if (userToken) {
        setauth(true);
      } else {
        setauth(false);
      }
    };
    setdata();
    getToken();
    setTimeout(3000);
  }, []);

  async function getToken() {
    try {
      const userToken = await localStorage.getItem("Auth");
      if (userToken) {
        const token = await localStorage.getItem("token");
        setDefaultHeader("token", token);
      } else {
        const { data } = await apiCall("get", EndPoints.JWTToken);
        //console.log("data", data);
        await localStorage.setItem("token", data.token);
        setDefaultHeader("token", data.token);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <main className="wrapper single-form" id="mainPage">
      <UserProvider userdata={userdata}>
        <Routes auth={auth} userdata={userdata} />
      </UserProvider>
    </main>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#282c34",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: 300,
    height: 300,
  },
  title: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 16,
  },
  text: {
    color: "#fff",
  },
  button: {
    borderRadius: 3,
    padding: 20,
    marginVertical: 10,
    marginTop: 10,
    backgroundColor: "#1B95E0",
  },
  buttonText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 16,
  },
});

import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { UserContext } from '../component/Context/UserContext';
const PublicRoute = ({ children, ...rest }) => {
    const [userData, setUserData] = useContext(UserContext);
    const name = userData?userData.data.name.trim().replace(/\s/g, '-'):null
    return (
        <Route
            {...rest}
            render={() => {
                if (userData) {
                    return <Redirect to="/item_list" />;
                } else {
                    return <Redirect to="/home" />;
                }
            }}
        />
    );
};

export default PublicRoute;
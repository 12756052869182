import React, { useState, useEffect } from "react";
export const UserContext = React.createContext();

export const UserProvider = (props) => {
  // console.log('props',props.userdata)
  const [userData, setUserData] = useState(props.userdata)
  // const [userData, setUserData] = useState(localStorage.getItem('userdata'))
  return (
    <UserContext.Provider value={[userData, setUserData]}>
      {props.children}
    </UserContext.Provider>
  )
}
// const [value, setValue] = React.useState("foo");
// const [value2, setValue2] = React.useState("goo");
{/* <MyContext.Provider
  value={{ value: [value, setValue], value2: [value2, setValue2] }}
>
  {props.children}
</MyContext.Provider>; */}

// const { value, value2 } = React.useContext(MyContext);
// const [stateValue, setStateValue] = value;
// const [stateValue2, setStateValue2] = value2;

import axios from 'axios'
import { GLOBAL_URL } from './constants'

const httpClient = axios.create({
    baseURL: `${GLOBAL_URL}/api/`,
});

export function setDefaultHeader(header, value) {

    httpClient.defaults.headers.common[header] = value
}

export async function apiCall(method, url, data, header = { 'content-type': 'application/json' }) {
    try {
        return await httpClient({
            method,
            url,
            data,
            headers: header,
            withCredentials: false
        })
    } catch (error) {
        if (error.response) {
            console.log('Error data : ', error.response.data);
            console.log('Error status : ', error.response.status);
            console.log('Error headers : ', error.response.headers);
        } else if (error.request) {
            console.log('Error request : ', error.request);
        } else {
            console.log('Error message : ', error.message);
        }
        console.log("errorconfig", error.config);
        // console.log("errorresponse", error.response);
        console.log("error", error);
        return false
    }
}

import React, { useState, useEffect, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";

import './login.css'
import { apiCall, setDefaultHeader } from "../utils/httpClient";
import ApiEndPoints from "../utils/apiEndPoints";
import Footer from "../footer/footer";
import Header from "../header/header";
import { useHistory } from "react-router-dom";
import strings from "../../language";
import detectBrowserLanguage from "detect-browser-language";

// const history = useHistory();
export default (props) => {
  // const simpleValidator = useRef(new SimpleReactValidator())
  const [errorMessage, setErrorMessage] = useState("");
  const simpleValidator = useRef(
    new SimpleReactValidator({
      locale: detectBrowserLanguage(),
    })
  );
  const [, forceUpdate] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [otp, setOtp] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [countryCode, setcountryCode] = useState("");
  const [type, settype] = useState("");
  const history = useHistory();

  useEffect(() => {
    setphoneNumber(history.location.state.phoneNumber);
    setcountryCode(history.location.state.countryCode);
    settype(history.location.state.type);
  }, []);
  function validateAllField() {
    if (simpleValidator.current.allValid()) {
      if (confirmpassword == password) {
        return true;
      } else {
        setErrorMessage(strings.passwordnotmatch);
      }
    } else {
      simpleValidator.current.showMessages(true);
      forceUpdate(1);
      return false;
    }
  }

  const routeChange = async () => {
    let path = `/`;
    history.push(path);
  };

  const onUpdateRestro = async () => {
    const lang = await localStorage.getItem("locale");
    const isValid = validateAllField();
    if (isValid) {
      const datas = {
        phone: phoneNumber.replace(/^0+/, ""),
        password: password,
        country_code: countryCode.substring(1),
        otp: otp,
      };
      try {
        const { data } = await apiCall(
          "post",
          ApiEndPoints.forgotUpdateRestro,
          datas
        );
        if (data.status == 200) {
          routeChange();
        } else if (data.status == 201) {
          setErrorMessage(lang == "en-US" ? data.message : lang == "en" ? data.message : data.fr_message);
        }
        // console.log(data)
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onpassword = (event) => {
    setpassword(event.target.value);
  };
  return (
    <div>
      <Header />
      <div>
        <div className="main-banner--home">
          <div
            className="bg"
            style={{ backgroundImage: 'url("images/BackGroundImage.svg")' }}
          />
        </div>
        <div className="form-section">
          <div className="outer">
            <div className="wrap">
              <div className="row center-lg">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 center-content-wrapper">
                  <div
                    className="single-caption"
                    style={{ fontFamily: "Poppins-Light" }}
                  >
                    <h2>{strings.welcome}</h2>
                    <div className="fs-text">{strings.gladSee}</div>
                  </div>

                  <div className="main-form">
                    <div>
                      <div className="form--group" style={{ marginBottom: 10 }}>
                        <input
                          type="txt"
                          name="otp"
                          placeholder={strings.enteryOtp}
                          className="form--control"
                          onChange={(event) => setOtp(event.target.value)}
                          style={{ fontFamily: "Roboto-Light" }}
                        />
                        <div className="errorTxt errorM" >
                          {simpleValidator.current.message(
                            strings.Verificationcode,
                            otp,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className="form--group" style={{ marginBottom: 10 }}>
                        <input
                          type="password"
                          name="password"
                          placeholder={strings.Newpassword}
                          className="form--control"
                          onChange={(event) => onpassword(event)}
                          style={{ fontFamily: "Roboto-Light" }}
                        />
                        <div className="errorTxt errorM">
                          {simpleValidator.current.message(
                            "password",
                            password,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className="form--group" style={{ marginBottom: 10 }}>
                        <input
                          type="password"
                          name="conpassword"
                          placeholder={strings.Confirmpassword}
                          className="form--control"
                          onChange={(event) =>
                            setConfirmpassword(event.target.value)
                          }
                          style={{ fontFamily: "Roboto-Light" }}
                        />
                        <div className="errorTxt errorM">
                          {simpleValidator.current.message(
                            "confirm password",
                            confirmpassword,
                            "required"
                          )}
                          {errorMessage}
                        </div>
                      </div>
                      <div className="spacer10" />
                      <div className="form--group">
                        <button
                          className="btn-submit"
                          onClick={() => onUpdateRestro()}
                          style={{ fontFamily: "Lato-Bold" }}
                        >
                          {strings.update}
                        </button>
                      </div>

                      <div className="form--group">
                        {/* <a
                          onClick={() => onForgotPassword()}
                          style={{ fontFamily: "Poppins-Light", color:"#000" }}>
                          Mot de passe oublié?
                          </a> */}
                        {/* <a style={{ color: "#fff" }} href="/CustomerRegistration" >Don't have an account?</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

import React, { useEffect, useState, useRef, useContext } from 'react'
import Footer from '../footer/footerApp';
import Header from '../header/header';
import SideNav from '../sideNav/sideNav';
import { useHistory } from 'react-router-dom';
import ApiEndPoints from '../utils/apiEndPoints'
import { apiCall } from '../utils/httpClient'
import { UserContext } from '../../component/Context/UserContext';
import Switch from "react-switch";
import { useAlert } from 'react-alert';
import _ from 'lodash';
import strings from '../../language';
export default (props) => {
  const alert = useAlert()
  const history = useHistory();
  const [, forceUpdate] = useState()
  const [userData, setUserData] = useContext(UserContext);
  const [dataArray, setDataArray] = useState([]);
  const [searchDataArray, setSearchDataArray] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    fetchItemList()
  }, [])
  function navigateItemAdd() {
    history.push('item_add')
  }
  async function fetchItemList() {
    try {
      const token = await localStorage.getItem('token');
      const lang = await localStorage.getItem("locale");
      var params = {
        lang:lang,
      }
      const { data } = await apiCall('post', ApiEndPoints.getitemslist,
      params, {
        'Accept': "application/json, text/plain, */*",
        'token': token
      }
      )
      if (data.status == 200) {
        console.log(" item list data.data",data.data)
        setDataArray(data.data)
        setSearchDataArray(data.data)
        setImageUrl(data.imageUrl)
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }
  const navigateItemDetails = (data) => {
    console.log("data itemDetail",data)
    const name = userData.data.name.trim().replace(/\s/g, '-')
    history.push('item_add', { data })
  }
  const changeItemStatus = async (item) => {
    const status = item.status == "1" ? "0" : "1"
    try {
      const token = await localStorage.getItem('token');
      const valData = {
        'id': item.id,
        'status': status
      }
      const { data } = await apiCall('post', ApiEndPoints.changeItemStatus,
        valData, {
        'Accept': "application/json, text/plain, */*",
        'token': token
      }
      )
      if (data.status == 200) {
        fetchItemList()
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }
  const deleteItem = async (item) => {
    try {
      const token = await localStorage.getItem('token');
      const { data } = await apiCall('get', ApiEndPoints.deleteItem + '?id=' + item.id,
        {}, {
        'Accept': "application/json, text/plain, */*",
        'token': token
      }
      )
      if (data.status == 200) {
        fetchItemList()
        alert.show(strings.ItemDelete, {
          timeout: 2000, // custom timeout just for this one alert
          type: 'success',
          onOpen: () => {
            console.log('hey')
          },
          onClose: () => {
            console.log('closed')
          }
        })
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }
  const searchItem = (e, searchVal) => {
    let SerarchArray = []
    // const dataArrays = _.reject(searchDataArray, ['type_of_item', undefined]);
    var a1 = _.filter(searchDataArray, (row) => (row.item_name.toLowerCase().match(searchVal.toLowerCase())))
    var a2 = _.filter(searchDataArray, (row) => row.type_of_cuisines.some(z => z.label.toLowerCase().match(searchVal.toLowerCase())))
    var a3 = _.filter(searchDataArray, (row) => row.type_of_item !== undefined && row.type_of_item.some(z => z.label.toLowerCase().match(searchVal.toLowerCase())))
    var a4 = _.filter(searchDataArray, (row) => row.type_of_service.some(z => z.label.toLowerCase().match(searchVal.toLowerCase())))
    SerarchArray = [...a1, ...a2, ...a3, ...a4]
    SerarchArray = _.uniq(SerarchArray, 'name')
    setDataArray(SerarchArray)
  }
  return (
    <>
      <SideNav searchItem={searchItem} />
      <div className="content-wrapper bg-gray-light" >
        <div className="content p-0 pt-3" style={{ height: "82vh", overflow: "auto" }}>
          <div className="container-fluid">
            <div className="row m-0">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                    <div className="row products-listings--main">
                      {dataArray.map((item, i) => {
                        return (
                          <div key={i} className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            {/* {console.log('item', item.status,item.status == 1 ? true : false)} */}
                            <div className="pro-box--lg">
                              <div className="pro-image" onClick={() => navigateItemDetails(item)}>
                                <span>
                                  {item.image ? <img src={item.image[0] ? item.image[0].file_name : '../images/product-image.png'} /> : <img src="images/product-image.png" />}
                                </span>
                              </div>
                              <div className="pro-title" >
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="title">{item.item_name}</div>
                                  <div className="radiobox">
                                    <div className="custom-control custom-switch" style={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}>
                                      <i className="nav-icon fas fal fa-trash"
                                        onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteItem(item) }}
                                        style={{ marginLeft: 5, marginRight: 5, color: "red" }}
                                      />
                                      <Switch
                                        height={20}
                                        width={40}
                                        onChange={() => changeItemStatus(item)}
                                        checked={item.status == 1 ? true : false} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                      }

                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 d-flex align-items-center justify-content-center">
                    <button
                      type="button"
                      onClick={() => navigateItemAdd()}
                      className="btn btn-success btn-lg"
                      style={{ width: 52, height: 52, borderRadius: 2000 }}
                    >
                      <i className="fas fa-plus" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

import React, { useEffect, useState } from 'react'
import Footer from '../footer/footerApp';
import SideNav from '../sideNav/sideNav';
import ApiEndPoints from '../utils/apiEndPoints'
import { apiCall } from '../utils/httpClient'
import _ from 'lodash';
import './report.css'
import DataTable from 'react-data-table-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import html2canvas from 'html2canvas';
import jsPdf from "jspdf";
import PDF from './pdf';
import String from '../../language/index';



export default (props) => {
  const [dataArray, setDataArray] = useState([]);
  const [searchDataArray, setSearchDataArray] = useState([]);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [weakly, setWeekly] = useState('')
  const [name, setname] = useState(0);
  const [address ,setaddress] =useState([]);
  const columns = [
    {
      name: String.order_id,
      selector: 'order_id',
      sortable: true,
    },
    {
      name: String.datetime,
      selector: 'date_time',
      sortable: true,
    },
    {
      name: String.ItemName,
      selector: 'item_name',
      sortable: true,
    },
    {
      name: String.Quantity,
      selector: 'item_quantity',
      sortable: true,
    },
    {
      name: String.FoodCharges,
      selector: 'food_choice',
      sortable: true,
    },
    {
      name: String.Price,
      selector: 'pricee',
      sortable: true,
    },
    {
      name: 'TVA',
      selector: 'TVA',
      sortable: true,
    },

  ];

  useEffect( () => {
    fetchItemList()
    ferchlocaldata()
  }, [])
 
   async function ferchlocaldata() 
   {
    const top = await localStorage.getItem('address').split(',');
    setaddress(top);
    setname(await localStorage.getItem('name'));
   }

  async function fetchItemList() {
    try {
      const token = await localStorage.getItem('token');
      const valData = {
        'restaurants_id': weakly,
      }
      const data = await apiCall('get', ApiEndPoints.getOrderList,
        //  ApiEndPoints.getOrderList,
        valData, {
        'Accept': "application/json, text/plain, */*",
        'token': token
      }
      )
      console.log("data",data);
      if (data.status == 200) {
        if (data.data.status == 200) {
          await setDataArray(data.data.complete_report_data)
          await setSearchDataArray(data.data.complete_report_data)

        }
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }
  const handleSubmit = async () => {
    try {
      const token = await localStorage.getItem('token');
      const valData = {
        'searchval': weakly,
      }
      const data = await apiCall('post', ApiEndPoints.getOrderListSearch,
        valData, {
        'Accept': "application/json, text/plain, */*",
        'token': token
      }
      )
      if (data.status == 200) {
        if (data.data.status == 200) {
          await setSearchDataArray(data.data.complete_result_data)
          await setDataArray(data.data.complete_result_data)
        }
      }
    } catch (error) {
      console.log('error: ', error);
    }

  }

  const searchItem = (e, searchVal) => {
    e.preventDefault();
    let SerarchArray = []
    if (searchVal.length > 0) {
      setFilterText(searchVal);
      const a1 = _.filter(searchDataArray, (row) => (row.order_id === searchVal))
      const a2 = _.filter(searchDataArray, (row) => row.date_time.toLowerCase().match(searchVal.toLowerCase()))
      const a3 = _.filter(searchDataArray, (row) => row.item_name.toLowerCase().match(searchVal.toLowerCase()))
      const a4 = _.filter(searchDataArray, (row) => (row.price === searchVal))
      const a5 = _.filter(searchDataArray, (row) => (row.item_quantity === searchVal))
      const a6 = _.filter(searchDataArray, (row) => (row.food_choice === searchVal))
      const a7 = _.filter(searchDataArray, (row) => (row.TVA === searchVal))
      SerarchArray = [...a1, ...a2, ...a3, ...a4, ...a5, ...a6,...a7]
      setDataArray(SerarchArray)
    } else {

      setDataArray(searchDataArray)
    }
  }

  const print = () => {
    document.getElementById("test").style.display = "block";
    const domElement = document.getElementById("pdfg");
    html2canvas(domElement, {
      allowTaint: true,
      useCORS: true,
      pagesplit:true,
     
    }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      var imgWidth = 210; 
      var pageHeight = 294;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPdf('p', 'mm');
      var position = 0;
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight+19);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight+19);
        heightLeft -= pageHeight;
      }
      doc.save("Invoice.pdf");
      document.getElementById("test").style.display = "none";
    });

  };
  const calorieTotal = (dataArray.reduce((totalCalories, dataArray) => totalCalories + dataArray.price, 0))
  const tvssTotal = (dataArray.reduce((tvsTotal, dataArray) =>parseFloat(parseFloat(tvsTotal) + parseFloat(dataArray.TVA_per)).toFixed(2), 0))
  const onClear = () => {

    setResetPaginationToggle(!resetPaginationToggle);
    setFilterText('');
    setDataArray(searchDataArray);
  };
  return (
    <>
      <SideNav />
      <div className="content-wrapper bg-gray-light">
        <div className="content p-0 pt-3">
          <div className="container-fluid">
            <div className="row m-0">
              <div className="col-lg-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="row products-listings--main order-list">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 gmap-listings--pro">
                      <form>
                        <div className="row">
                          <div className="col-md-1">
                            <a href="/report" value="Weekly Order"
                              className="btn btn-success">{String.all}</a>
                          </div>

                          <div className="col-md-6" >
                            <div className="col-md-4" style={{ float: 'left' }}>
                              <input type="radio" onChange={() => setWeekly(1)} checked={weakly === 1} name="week" value="1" /> <label>{String.Weekly}</label>
                            </div>
                            <div className="col-md-4" style={{ float: 'left' }}>
                              <input type="radio" onChange={() => setWeekly(2)} checked={weakly === 2} name="week" value="2" /><label>{String.Monthly} </label>
                            </div>
                            <div className="col-md-4" style={{ float: 'right' }}>
                              <input type="radio" name="week" onChange={() => setWeekly(3)} checked={weakly === 3} value="3" /><label> {String.Yearly}</label>
                            </div>
                          </div>
                          <div className="col-md-02 text-center">
                            <label for="email"></label>
                            <input type="button" name="search" value={String.Searchlist} onClick={() => handleSubmit()}
                              className="btn btn-success" />
                          </div>
                        </div>
                      </form>
                      <button className="btn btn-warning" style={{ margin: 48,color: "white", position: "absolute", zIndex: 1 }} onClick={() => print()}>{String.erreport}</button>
                      <div className="col-md-02" style={{ float: 'right' }}>
                        <input type="text" placeholder={String.Searchlist} aria-label="Search Input" value={filterText} onChange={e => searchItem(e, e.target.value)} />
                        <button type="button" className="btn btn-primary" onClick={onClear}>X</button>
                      </div>
                      <DataTable
                        columns={columns}
                        data={dataArray}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        selectableRows
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="test" style={{ "display": "none" }}>
        <div id="pdfg" className="col-lg-10" style={{}}>
        <PDF 
        address={address}
        dataArray ={dataArray}
        calorieTotal={calorieTotal}
        name ={name}
        tvssTotal ={tvssTotal}
        />
        </div>
      </div>
      <Footer />
    </>
  )
}

import React, { useEffect, useState, useRef, useContext } from 'react'
import Footer from '../footer/footer';
import Header from '../header/header';
import { useHistory, Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import ApiEndPoints from '../utils/apiEndPoints'
import { apiCall } from '../utils/httpClient'
import { UserContext } from '../../component/Context/UserContext';
import strings from '../../language';
export default (props) => {
  const history = useHistory();
  const [auth, setauth] = useState('0')
  const [userData, setUserData] = useContext(UserContext);

  const [, forceUpdate] = useState()
  const [currentItem, setCurrentItem] = useState(null)
  const [baseUrl, setBaseUrl] = useState('')
  const [showSearch, setShowSearch] = useState(false)
  const [searchText, setsearchText] = useState('')
  const [status, setStatus] = useState()

  useEffect(() => {
    const authval = localStorage.getItem('Auth')
    var restroStatus = userData.data.status == 1 ? true : false;
    setStatus(restroStatus)
    setauth(authval);
    history.location.pathname == "/item_list" && setShowSearch(true)
  }, [])
  const onLogout = async () => {
    await localStorage.clear();
    setauth(0)
    setUserData('')
    history.push('/home')
  }
  const navToAddon = () => {
    const name = userData.data.name.trim().replace(/\s/g, '-')
    history.push('/addons')
  }
  const navToCompleteOrder = () => {
    const name = userData.data.name.trim().replace(/\s/g, '-')
    history.push('/order')
  }
  const navToReportOrder = ()=>{
    const name = userData.data.name.trim().replace(/\s/g, '-')
    history.push('/report')
  }

  const navToqrcode = ()=>{
    history.push('/qrcode')
  }
  const navTodishtype = ()=>{
    history.push('/dishtype')
  }

  const navMyAccount = () => {
    const name = userData.data.name.trim().replace(/\s/g, '-')
    history.push('/myaccount')
  }
  const navItemList = () => {
    const name = userData.data.name.trim().replace(/\s/g, '-')
    history.push('/item_list')
  }
  const sidebarClosebtn = () => {
    // document.getElementById("sidebarTarget").style.marginLeft = "-250px";
    var element = document.getElementById("sidebar-mini");
    element.classList.add("sidebar-mini", "sidebar-closed", "sidebar-collapse");
    element.classList.remove('sidebar-open');
  }
  async function changeRestroList(event) {
    event.persist()
    try {
      const token = await localStorage.getItem('token');
      let val = {
        'status': event.target.checked == true ? "1" : "0",
        'lang': "en"
      }
      const { data } = await apiCall('post', ApiEndPoints.changerestrostatus,
        val, {
        'Accept': "application/json, text/plain, */*",
        'token': token
      }
      )
      if (data.status == 200) {
        setStatus(event.target.checked)
        setData(data)
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }
  async function setData(data) {
    await setUserData(data)
    console.log('userData.data.status', userData.data.status)
    await localStorage.setItem('userdata', JSON.stringify(data));
  }
  return (
    <div>
      {/* <Header bgColor={'black'} /> */}
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-white navbar-light bg-gray-light">
        {/* Right navbar links */}
        <ul className="navbar-nav align-items-center w-100 justify-content-start">

          <li className="nav-item">
            <a className="nav-link mob-menu" data-widget="pushmenu" href="#"><i className="fas fa-bars"></i></a>
          </li>
          <li className="nav-item mr-4 w-50">
            {/* SEARCH FORM */}
            {<div className="row">
              <div className="col-lg-5 col-md-5 col-xs-6 col-xs-12">
                <form className="form-inline">
                  <div
                    style={{ display: 'flex', overflow: 'unset', paddingLeft: 0, paddingRight: 0 }}
                    className="input-group input-group-md search-input-hd w-100">
                    {showSearch && <input
                      className="form-control form-control-navbar search-control border bg-white"
                      type="search"
                      placeholder="Chercher"
                      aria-label="Chercher"
                      onChange={(event) => setsearchText(event.target.value)}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          props.searchItem(event, searchText)
                        }
                      }}
                    />}
                  </div>
                </form>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                {showSearch && <button
                  type="button"
                  onClick={(e) => props.searchItem(e, searchText)}
                  style={{ marginTop: 7 }}
                  className="btn btn-success btn-md">
                  <i className="fa fa-search" />
                </button>}
              </div>
            </div>
            }
          </li>
        </ul>
      </nav>
      <aside className="main-sidebar sidebar-dark-primary elevation-1" id="sidebarTarget">
        <p className="sidebarClosebtn" id="sidebarClosebtn" onClick={() => sidebarClosebtn()}> <i className="fa fa-times"></i></p>
        <a className="brand-link text-center border-0 pb-0">
          <img
            src={userData ? userData.data.logo_image ? userData.imgpath + '/' + userData.data.logo_image : "../images/logo-main.png" : "../images/logo-main.png"}
            alt={userData ? userData.data.name : ''}
            className="brand-image img-circle elevation-3 mb-3"
          />
          <span className="brand-text font-weight-light d-block">
            {userData ? userData.data.name : ''}
          </span>
        </a>
        <div className="text-center pt-2 pb-2">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customSwitch1"
              // defaultChecked="checked"
              defaultChecked={status}
              onChange={(event) => changeRestroList(event)}

            />
            <label className="custom-control-label" htmlFor="customSwitch1" />
          </div>
        </div>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar Menu */}
          <nav className="mt-2 pt-3">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >

              <li className="nav-item">
                <a className="nav-link"
                  href="/item_list"
                >
                  <i className="nav-icon fas fal fa-cookie" />
                  <p style={{ color: "#fff" }}>{strings.items}</p>
                </a>
              </li>
              <li className="nav-item has-treeview">
                <a href="#" className="nav-link" onClick={() => navMyAccount()} >
                  <i className="nav-icon fas fa-store" />
                  <p style={{ color: "#fff" }}>{strings.myAccount}</p>
                </a>
              </li>
              <li className="nav-item has-treeview">
                <a onClick={() => navToAddon()} className="nav-link">
                  <i className="nav-icon fas fa-plus-circle" />
                  <p style={{ color: "#fff" }}>{strings.Addon}</p>
                </a>
              </li>

              <li className="nav-item has-treeview">
                <a onClick={() => navToCompleteOrder()} className="nav-link">
                  <i class="nav-icon fas fa-cube"></i>
                  <p style={{ color: "#fff" }}>{strings.Ordercomplete}</p>
                </a>
              </li>
              <li className="nav-item has-treeview">
                <a onClick={() => navToReportOrder()} className="nav-link">
                 
                  <i class="nav-icon fas fa-cube"></i>
                  <p style={{ color: "#fff" }}>{strings.orderreport}</p>
                </a>
              </li>
              <li className="nav-item has-treeview">
                <a onClick={() => navToqrcode()} className="nav-link">
                 <i className=" nav-icon fas fa-qrcode"></i> 
                  <p style={{ color: "#fff" }}>QR Code</p>
                </a>
              </li>
              <li className="nav-item has-treeview">
                <a onClick={() => navTodishtype()} className="nav-link">
                 <i className=" nav-icon fas fa-store"></i> 
                  <p style={{ color: "#fff" }}>{strings.typeofDishes}</p>
                </a>
              </li>
              {
                auth &&
                <li className="nav-item has-treeview">
                  <a onClick={() => onLogout()} className="nav-link">
                    <i className="nav-icon fas fa-sign-out-alt" />
                    <p style={{ color: "#fff" }}>{strings.logout}</p>
                  </a>
                </li>
              }
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
      {/* <Footer /> */}
    </div >
  )
}



import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import strings from "../../language";
import './header.css'

export default (props) => {
  const [auth, setauth] = useState("0");
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState("none");

  useEffect(() => {
    const authval = localStorage.getItem("Auth");
    setauth(authval);
  }, []);

  const onLogout = () => {
    localStorage.clear();
    setauth(0);
    let path = "/";
    history.push(path);
  };
  function redirectTo() {
    return <Redirect to="/" />;
  }
  useEffect(() => {
    if (openDrawer == "block") {
      // var maindiv = document.getElementById("mainPage");
      document.getElementById("mainPage").style["overflow-y"] = "hidden";
      document.getElementById("mainPage").style["height"] = "100vh";
    }
    if (openDrawer == "none") {
      document.getElementById("mainPage").style["overflow-y"] = "auto";
      document.getElementById("mainPage").style["height"] = "100vh";
    }
  }, [openDrawer]);
  return (
    <div>
      <header
        className={`desktop-header ${props.bgColor == "black" ? `header-inner` : null
          }`}
      >
        <div className="outer">
          <div className="wrap wrap--containt-hd">
            <div className="flexbox" style={{ justifyContent: "flex-start" }}>
              {/* <div className="flexitem" style={{ display: "flex", flex: 1, justifyContent:"center" }}>
                <div className="logo">
                  <a href="/home">
                    <img src="images/logo.svg" alt="header" />
                  </a>
                </div>
              </div> */}
              {/* <div className="flexitem">
                <div className="main-navs">
                  <ul className="inline-lists">
                    {!auth && (
                      <li>
                        <a href="/restaurantregistration">
                          {strings.becomeapartner}
                        </a>
                      </li>
                    )}
                    <li>
                      <a href="/">{strings.downloadApp}</a>
                    </li>
                    {!auth && (
                      <li>
                        <a href="/restaurantLogin">{strings.signin}</a>
                      </li>
                    )}
                    {auth && (
                      <li>
                        <a onClick={() => onLogout()}>{strings.logout}</a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
          */}

            </div>
          </div>
        </div>
      </header>
      <header
        className={`mobile-header ${props.bgColor == "black" ? `header-inner` : null
          }`}
      //style={{zIndex:333}}
      >
        <div className="outer">
          <div className="flexbox">
            <div className="flex-item logo-row">
              <div className="logo">
                <a href="/home">
                  <img src="images/logo.svg" alt="header" />
                </a>
              </div>
            </div>
            <div className="flex-item">
              <div className="main-navs">
                <ul className="inline-lists">
                  <li>
                    {/*<a className="hamburger">
                                          <i className="fal fa-bars" />
                                        </a>*/}
                    <a
                      style={{ paddingTop: 10 }}
                      className="w3-button w3-white w3-xxlarge"
                      onClick={() => setOpenDrawer("block")}
                    >
                      &#9776;
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex-item">
              <div className="main-navs">
                <ul className="inline-lists" />
              </div>
            </div>
          </div>
        </div>

        {/*Viren*/}

        <div
          className="w3-sidebar--menu"
          style={{
            display: openDrawer == "block" ? "block" : "none",
            zIndex: 5,
          }}
        >
          <div
            className="w3-sidebar w3-sidebar-open--menu  w3-bar-block w3-animate-left"
            id="mySidebar"
          >
            <div className="close-btn-box">
              <a
                className="w3-bar-item w3-close--button w3-large"
                onClick={() => setOpenDrawer("none")}
              >
                &times;
              </a>
            </div>
            <div className="side-menu--details">
              <div className="sidebar--menu">
                {!auth ? (
                  <a href="/restaurantLogin" className="side-menu-signinbtn">
                    {strings.signin}
                  </a>
                ) : (
                  <a onClick={() => onLogout()} className="side-menu-signinbtn">
                    {strings.logout}
                  </a>
                )}
              </div>

              <div className="sidebar--bottom-containt">
                <ul style={{ marginTop: 35 ,paddingLeft:0}}>
                  {!auth ? (
                    <li>
                      <a href="/restaurantregistration">
                        {strings.becomeapartnerside}
                      </a>
                    </li>
                  ) : null}  </ul>
                <div className=""  style={{ marginTop: 20}}>
                  <div className="sidebar__contai sidebar_bt_containt">
                    <div className="sidebar__contai">
                      <span>{strings.discoverKrustty}:</span>
                    </div>

                  </div>
                </div>
                <div className="footer">
                  <div
                    className="social-links inline-lists"
                    style={{ marginTop: 40}}
                  >
                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/krustty/?viewAsMember=true"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/krusttyapp/"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/krusttyapp"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                  </div>
                </div>
                <ul style={{ marginTop: 20 ,paddingLeft:0,marginBottom: 20}}>
                  <li>
                    <a href="#">{strings.downloadApp}</a>
                  </li>
                </ul>




                <div className="sidebar__containt">
                  <div className="sidebar_logo">
                    <img src="../images/googleplay.png" alt="footer" />
                  </div>
                </div>
                <div className="sidebar__containt" style={{ marginTop: 10 }}>
                  <div className="sidebar_logo">
                    <img src="../images/appstore.png" alt="footer" />
                  </div>
                </div>
                {/* <div className="sidebar__containt" style={{ marginTop: 15 }}>
                  <div className="sidebar_logo">
                    <img src="images/logo.svg" alt="header" />
                  </div>
                </div> */}


                {/* <div className="side-logo--btnMenu">
                  <div className="sidemenu_logo">
                    <img alt="" role="presentation" src="images/logo.svg" className="c2 f6 at" />
                  </div>
                  <div className="sidemenu_logo--containt">
                    <span className="bg bh ca b0">There's more to love in the app.</span>
                  </div>

                </div> */}
                {/* <div className="side-btm-btnMenu">
                  <div className="row">
                    <a href="" className="btn-download"><i className="fab fa-apple"> </i> iPhone</a>
                    <a href="" className="btn-download"><i className="fab fa-android"></i> Android</a>

                  </div>
                </div> */}
              </div>


            </div>
          </div>
        </div>

        {/*<div className="nav-drawer">
                  <div className="nav-contents">
                    <ul className="block-lists">
                      {
                        !auth &&
                        <li>
                          <a href="/restaurantregistration">{strings.becomeapartner}</a>
                        </li>
                      }
                      <li>
                        <a href="/">{strings.downloadApp}</a>
                      </li>
                      {
                        !auth
                        &&
                        <li>
                          <a href="/restaurantLogin">{strings.signin}</a>
                        </li>
                      }
                      {/* {auth && redirectTo()} */}
        {/*  {
                        auth &&
                        <li>
                          <a onClick={() => onLogout()}>{strings.logout}</a>
                        </li>
                      }
                    </ul>
                  </div>
                </div>*/}
      </header>
      {/* END OF MOBILE-HEADER */}
    </div>
  );
};

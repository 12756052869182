import React, { useEffect, useState, useRef, useContext } from 'react'
import Footer from '../footer/footerApp';
import Header from '../header/header';
import SideNav from '../sideNav/sideNav';
import { useHistory } from 'react-router-dom';
import ApiEndPoints from '../utils/apiEndPoints'
import { apiCall } from '../utils/httpClient'
import { UserContext } from '../../component/Context/UserContext';
import Switch from "react-switch";
import { useAlert } from 'react-alert';
import OrderModal from '../../component/orderDetails'
import _ from 'lodash';
import './order.css'
import strings from '../../language';
export default (props) => {
  const Alert = useAlert()
  const history = useHistory();
  const [, forceUpdate] = useState()
  const [userData, setUserData] = useContext(UserContext);
  const [dataArray, setDataArray] = useState([]);
  const [searchDataArray, setSearchDataArray] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [orderDetail, setOrderDetail] = useState({});
  const [detailModal, setDetailModal] = useState(false);
  useEffect(() => {
    fetchItemList()
  }, [])
  async function fetchItemList() {
    try {
      const lang = await localStorage.getItem('locale');
      const token = await localStorage.getItem('token');
      const valData = {
        'restaurants_id': 17,
      }
      const data = await apiCall('get',  ApiEndPoints.getOrderList+ '?lang='+lang,
        //  ApiEndPoints.getOrderList,
        valData, {
        'Accept': "application/json, text/plain, */*",
        'token': token
      }
      )
     
      if (data.status == 200) {
        if (data.data.status == 200) {
          console.log("data.data.complete_result_data",data.data.complete_result_data)
          setDataArray(data.data.complete_result_data)
          // setSearchDataArray(data.data.complete_result_data)
        }
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const searchItem = (e, searchVal) => {
    e.preventDefault();
    let SerarchArray = []
    const a1 = _.filter(searchDataArray, (row) => (row.item_name.toLowerCase().match(searchVal.toLowerCase())))
    const a2 = _.filter(searchDataArray, (row) => row.type_of_cuisines.some(z => z.label.toLowerCase().match(searchVal.toLowerCase())))
    const a3 = _.filter(searchDataArray, (row) => row.type_of_item.some(z => z.label.toLowerCase().match(searchVal.toLowerCase())))
    const a4 = _.filter(searchDataArray, (row) => row.type_of_service.some(z => z.label.toLowerCase().match(searchVal.toLowerCase())))
    SerarchArray = [...a1, ...a2, ...a3, ...a4]
    SerarchArray = _.uniq(SerarchArray, 'name')
    setDataArray(SerarchArray)
  }
  return (
    <>
      <SideNav searchItem={searchItem} />
      <div className="content-wrapper bg-gray-light">
        <div className="content p-0 pt-3">
          <div className="container-fluid">
            <div className="row m-0">
              <div className="col-lg-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="row products-listings--main order-list"
                  >
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 gmap-listings--pro">
                      {dataArray.length > 0 ? dataArray.map((item, index) => {
                        return (
                          <div
                            onClick={() => { setOrderDetail(item); setDetailModal(true) }}
                            className="" key={index} style={{
                              backgroundColor: "#fff", padding: 13,
                              borderWidth: 1,
                              display: "block",
                              boxShadow: ' 0px 0px 3px 1px rgba(0,0,0,0.1)',
                              borderRadius: 20, margin: 18,
                              cursor:'pointer'
                            }}>
                            <div className="col-md-11" style={{ display: "flex", justifyContent: "space-between" }}>
                              <h6>#{item.order_id}</h6>
                              {item.current_status == "0" ?
                                <span style={{ color: "#f7671f" }}>
                                  {strings.Waiting}
                                </span>
                                :
                                item.current_status == "3" ?
                                  <span style={{ color: "red" }}>
                                    {strings.Waiting}
                                  </span> :
                                  item.current_status == "5" ?
                                    <span style={{ color: "green" }}>
                                      {strings.Completed}
                                    </span> :
                                    item.current_status == "4" ?
                                      <span style={{ color: "green" }}>
                                        {strings.Confirmed}
                                      </span> :
                                      item.current_status == "6" ?
                                        <span style={{ color: "red" }}>
                                          {strings.orderCancelUser}
                                        </span> :
                                        <span style={{ color: "green" }}>
                                          {strings.Accepted}
                                        </span>
                              }
                            </div>
                            <div className="col-md-11 " style={{ display: "flex", justifyContent: "space-between" }}>
                              <div > <i className="fas fa-euro-sign" /> {item.sub_total}</div>
                            </div>
                            <div className="col-md-11 " style={{ display: "flex", justifyContent: "space-between" }}>
                              {item.no_of_people > 0 ? <span > {strings.People}  : {item.no_of_people} |  {item.service_typee}</span>
                                : <span >  {item.service_typee}</span>
                              }
                              <span><i className="fas fa-clock" /> {item.time ? item.time.replace(/(\d{1,2}:\d{2}):\d{2}/, "$1") : null}</span>
                            </div>
                          </div>)
                      })
                        : null
                        // <div style={{ height: "60vh", display: "flex", alignItems: "center", }}>
                        //   {isLoader ? <Shimmer /> : `Order is not available`}
                        // </div>
                      }
                      {/* {isLoader && <Shimmer />} */}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {detailModal &&
        <OrderModal
          orderDetail={orderDetail}
          detailModal={detailModal}
          setDetailModal={setDetailModal}
        />}
      <Footer />
    </>
  )
}

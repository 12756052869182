import React, { useEffect, useState, useRef } from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import { useHistory, Redirect } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import strings from "../../language";
import "./home.css";
export default (props) => {
  const history = useHistory();
  const [, forceUpdate] = useState();
  const [redirect, setRedirect] = useState(false);
  const [email, setEmail] = useState("");
  const [isReload, setIsReload] = useState(localStorage.getItem("Auth"));
  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        phoneNumber: "Phone number is invalid",
        countryCode: "Country code is invalid",
      },
    })
  );
  useEffect(() => {
    setRedirect(true);
  }, []);
  const stateRef = useRef();
  stateRef.current = isReload;

  function redirectTo() {
    return <Redirect to="/" />;
  }
  const onClickBecomePartner = () => {
    // if (simpleValidator.current.allValid()) {
    let path = `restaurantRegistration`;
    history.push(path, { email: email ? email : "" });
    // } else {
    //   simpleValidator.current.showMessages(true);
    //   forceUpdate(1)
    //   return false;
    // }
  };
  if (redirect) {
    if (history.location.state) {
      if (history.location.state.userData) {
        // window.location.reload()
      }
    }
  }
  return (
    <div>
      <Header />
      <div className="main-banner--home">
        <div
          className="landing-partner"
          style={{ backgroundImage: 'url("images/partnerlanding.svg")'}}
         
         // style={{ backgroundImage: 'url("images/cta-img.svg")' }}
        />
        <div className="caption caption-main--card main-home--title">
          <div className="cp-title">
            {strings.facilitatesales}
          </div>
          <div className="cp-title tittle" style={{padding:10}}>{strings.startnow}:</div>
          <div>
            <div
              className="cta-section"
              style={{ display: "flex", backgroundColor: "transparent" }}
            >
              <div
                className="innerpadding"
                style={{ paddingTop: 0, paddingLeft: 0 }}
              >
                <div className="outer">
                  <div className="wrap">
                    <div className="cta-contents ">
                      <div className="contact-form">
                        <div className="flexbox flexbox--inputbox">
                          <div className="flex-item">
                            <div className="input-group">
                              <input
                                type="text"
                                placeholder={strings.emailphone}
                                className="form--control landing-input"
                                value={email}
                                //style={{ width: 300 }}
                                onChange={(event) =>
                                  setEmail(event.target.value)
                                }
                              />
                            </div>
                            <div className="errorTxt">
                              {simpleValidator.current.message(
                                "email",
                                email,
                                "email|required"
                              )}
                            </div>
                          </div>
                          {/* <div className="flex-item">
                            <div
                              className="terms_conditions"
                              style={{ background: "red" }}
                            >
                              <input
                                type="checkbox"
                                id={1}
                                name={1}
                                className="chkbx"
                              />
                            </div>
                          </div> */}
                          <div className="flex-item full">
                            <div className="submit-btn">
                              <input
                                type="button"
                                onClick={() => onClickBecomePartner()}
                                defaultValue={strings.becomeapartner}
                                className="btn-submit btn-partner"
                                //style={{ width: 300 }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="pre-book-caption">
          <div className="wrap wrap--containt-hd">
            {strings.putBar}
          </div>
        </div> */}
      </div>

      <Footer />
    </div>
  );
};

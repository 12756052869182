import React from 'react'
// import { Navbar } from 'react-bootstrap'
// import './footer.css';

export default ()=>{
  return (

    <footer className="main-footer">
    <strong>
    Copyright © 2020 Krustty SAS. 
      </strong>
    All rights reserved.
      <div className="float-right d-none d-sm-inline-block">
      <b>Version</b> 3.0.5
      </div>
  </footer>
  )
}
import React from 'react'
import moment from 'moment'
import strings from "../language";

function orderDetails(props) {
    return (
        <div className="cart--popup--main">
            <div className="cart-drawer--wrapper" style={{ zIndex: 9999 }}>
                <div onClick={() => props.setDetailModal(false)} style={{ right: 0, left: 8, top: 8, position: 'absolute', height: 40, width: 40, backgroundColor: 'red', borderRadius: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <i className="fa fa-times" aria-hidden="true" style={{ color: 'rgb(255, 255, 255)' }} />
                </div>
                <div style={{
                    height: '85vh', padding: 20, borderBottomWidth: 1,
                    borderBottomColor: '#c9c9c9', borderBottomStyle: 'solid'
                }}>
                    <div style={{
                        flex: 1,
                        display: "flex",
                        height: "6%",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 20
                    }} >
                        {/* {strings.Commande} */}
                    </div>
                    <div style={{ height: "10%" }}>
                        {props.orderDetail &&
                            props.orderDetail.current_status == 0 ?
                            <img src='../images/r1.png' style={{ height: 45, width: "100%" }} />
                            : props.orderDetail.current_status == 1 ?
                                <img src='../images/r2.png' style={{ height: 45, width: "100%" }} />
                                : props.orderDetail.current_status == 4 ?
                                    <img src='../images/r3.png' style={{ height: 45, width: "100%" }} />
                                    :
                                    props.orderDetail.current_status == 5 ?
                                        <img src='../images/r4.png' style={{ height: 45, width: "100%" }} />
                                        :
                                        props.orderDetail.current_status == 6 || props.orderDetail.current_status == 3 ?
                                            <img src='../images/r5.png' style={{ height: 45, width: "100%" }} />
                                            :
                                            props.orderDetail.current_status == 7 ?
                                                <img src='../images/r3.png' style={{ height: 45, width: "100%" }} />
                                                :
                                                null

                        }
                    </div>
                    <div className="qty-content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <label style={{ divAlign: 'center' }}>
                            {strings.ordercompleted}
                        </label>
                    </div>
                    <div style={{
                        alignItems: "center",
                        // paddingLeft: 10,
                        // paddingRight: 10,
                        borderBottomWidth: 0.5,
                        borderBottomColor: "#b2b2b2",
                        borderBottomStyle: 'solid',
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingTop: 10,
                        paddingBottom: 10
                    }}>
                        <div style={{ display: 'flex', flexDirection: "row" }}>
                            <i class="far fa-calendar-alt" style={{ paddingRight: 5, color: '#f15a24', fontSize: 20 }}></i>
                            <div style={{ color: '#f15a24' }}>
                                {
                                    moment(props.orderDetail.date).format('DD-MMM')
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "row" }}>
                            <i class="far fa-clock" style={{ paddingRight: 5, color: '#f15a24', fontSize: 20 }}></i>
                            <div style={{ color: '#f15a24' }}>
                                {props.orderDetail.time ?
                                    props.orderDetail.time.replace(/(\d{1,2}:\d{2}):\d{2}/, "$1") : null}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "row" }}>
                            <i class="fas fa-concierge-bell" style={{ paddingRight: 5, color: '#f15a24', fontSize: 20 }}></i>
                            <div style={{ color: '#f15a24', fontSize: 15, }}>
                                {props.orderDetail.service_typee}
                            </div>
                        </div>
                    </div>
                    {props.orderDetail.group_id ?
                        <div style={{ alignItems: 'center', marginTop: 10, flexDirection: 'row', display: 'flex' }}>
                            <div style={{ flex: 1 }}>
                                <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <div style={{ fontSize: 15 }}>{props.orderDetail.service_type === 'Prasent' ? 'Continue' : 'Group Reservation'}</div>
                                    <div style={{ marginLeft: 5, fontSize: 16 }}>#{props.orderDetail.group_id}</div>
                                </div>
                                {
                                    props.orderDetail.no_of_people > '0' ?
                                        <div>{props.orderDetail.no_of_people} People</div>
                                        : <div />
                                }
                            </div>
                            <img src={'images/Asset13group.png'}
                                resizeMode={"contain"} style={{ height: 45, width: 45, alignSelf: "center" }} />
                        </div>
                        :
                        <div style={{ marginTop: 10 }}>
                            <div style={{ fontSize: 15 }}>
                                {props.orderDetail.service_typee}</div>
                            {
                                 props.orderDetail.no_of_people > '0' ?
                                    <div>{props.orderDetail.no_of_people} {strings.People}</div>
                                    : <div />
                            }
                        </div>
                    }
                    <div style={{ flex: 1, marginTop: 10, height: '35vh', overflow: 'auto' }}>
                        {
                            props.orderDetail.items ?
                                props.orderDetail.items.map((item) => {
                                    return (
                                        <div key={item.id}>
                                            <div>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ width: '95%', display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "space-between" }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 2, alignItems: 'center' }}>

                                                            <div style={{ color: '#f1612e' }}>{item.items_name}</div>

                                                            <div style={{ paddingLeft: 10, color: '#f1612e' }}>
                                                                X
                                                            </div>
                                                            <div style={{ color: '#f1612e', paddingLeft: 1 }}>
                                                                {item.quantity}
                                                            </div>
                                                            <div >{item.totalPrice}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <div style={{ color: '#575757', fontSize: 18 }}>{item.price * item.quantity}
                                                                <i className="fas fa-euro-sign" style={{ paddingLeft: 5, paddingTop: 5, fontSize: 18, color: '#575757' }} ></i>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    // JSON.parse(item.addon).map((data) => {
                                                    item.addon ?
                                                        JSON.parse(item.addon).map((data, i) => {
                                                            return (

                                                                (data.sel === true) &&

                                                                <div key={i}>
                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                        <div style={{ width: '95%', display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "space-between" }}>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 2, alignItems: 'center' }}>

                                                                                <div style={{ color: '#a9a9a9', fontSize: 12, }}>{data.subaddonNameEn}</div>
                                                                            </div>
                                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                <div style={{ color: '#575757', fontSize: 18 }}>{data.price}
                                                                                    <i className="fas fa-euro-sign" style={{ paddingLeft: 5, paddingTop: 5, fontSize: 18, color: '#575757' }} ></i>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>


                                                            )
                                                        })
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                : null
                        }
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '95%', display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "space-between" }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 2, alignItems: 'center' }}>
                                                        <div style={{ color: '#f1612e' }}>{strings.servicecharges}</div>



                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ color: '#575757', fontSize: 18 }}>{props.orderDetail.service_charges}
                                                            <i className="fas fa-euro-sign" style={{ paddingLeft: 5, paddingTop: 5, fontSize: 18, color: '#575757' }} ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {props.orderDetail.description &&
                                                <div style={{
                                                    margin: 20,
                                                    backgroundColor: '#e2e2e2',
                                                    minHeight: 40,
                                                    justifyContent: 'flex-start',
                                                    padding: 10,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    borderRadius: 7
                                                }}>
                                                    <div numberOfLines={3} ellipsizeMode='tail' style={{ color: "#666", fontSize: 14, fontWeight: "500" }}>
                                                        <div style={{ color: "#000", fontSize: 14, fontWeight: "bold" }}>{strings.desc}:{' '}</div>
                                                        {props.orderDetail.description}
                                                    </div>
                                                </div>}
                                        </div>

                </div>
                    <div className="cart-btn">
                        <div style={{
                            paddingLeft: 15, paddingRight: 15,
                            marginBottom: 20
                        }}>

                            <div style={{
                                display: 'flex',
                                flexDirection: "row", justifyContent: "space-between", borderBottomWidth: 1,
                                borderBottomColor: '#c9c9c9', padding: 5, borderBottomStyle: 'solid'
                            }}>
                                <div >
                                    <div style={{ color: "#1c2753", fontSize: 19, fontWeight: "500" }}>
                                        {strings.Total}
                                    </div>
                                </div>
                                <div>
                                    <div style={{ color: "#1c2753", fontSize: 19, fontWeight: "500" }}>
                                        {parseFloat(props.orderDetail.total).toFixed(1)}
                                        <i className="fas fa-euro-sign" style={{ paddingLeft: 5, paddingTop: 5, fontSize: 17, color: '#1c2753' }} ></i>
                                        {/* <i className="fas fa-euro-sign" style={{ color: '#1c2753', paddingTop: 5, fontSize: 17, fontWeight: "400" }}></i> */}
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: "row", justifyContent: "space-between", borderBottomWidth: 1,
                                borderBottomColor: '#c9c9c9', padding: 5, alignItems: "center", borderBottomStyle: 'solid'
                            }}>
                                <div >
                                    {
                                        props.orderDetail.payment_method == "Online" ?
                                            <img src={'images/debitcard.png'} style={{ height: 30, width: 30, }} />
                                            :
                                            props.orderDetail.payment_method == "Paylater" ?
                                                <img src={'images/cardmachine.png'} style={{ height: 30, width: 30, }} />
                                                :
                                                <img src={'images/money.png'} style={{ height: 30, width: 30, }} />
                                    }
                                </div>
                                <div>
                                    <div style={{ color: "#575757", fontSize: 15 }}>
                                        {props.orderDetail.payment_method}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default orderDetails;

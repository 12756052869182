import React, { useEffect, useState } from 'react'
import Footer from '../footer/footerApp';
import Header from '../header/header';
import SideNav from '../sideNav/sideNav';
import strings from '../../language';
import QRCode from "react-qr-code";
import base64 from 'react-native-base64'
export default (props) => {

  const [reataurantid, setreataurantid] = useState('');
  useEffect(() => {
    ferchrestroID()
  }, [])

  async function ferchrestroID() {
    const restid = await (localStorage.getItem('id'));
    setreataurantid(restid);
  }


  return (
    <>
      <SideNav />
      <div className="content-wrapper bg-gray-light" >
        <div className="content p-0 pt-3" style={{ height: "82vh", overflow: "auto" }}>
          <div className="container-fluid">
            <div className="row m-0">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                    <div className="row products-listings--main">
                     
                          <div  className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{textAlign:"center"}} >
                            {/* {console.log('item', item.status,item.status == 1 ? true : false)} */}
                            <div className="pro-box--lg" >
                              <div className="pro-image" style={{padding:30}}>
                              <QRCode value={"https://krustty.com/restaurant_details/" + base64.encode(reataurantid)} />
                              </div>
                                  <div className="title" style={{textAlign:"center"}}>{strings.website}</div>
                            </div>
                          </div>
                          <div  className="col-lg-6 col-md-6 col-sm-12 col-xs-12"  style={{textAlign:"center"}}>
                            <div className="pro-box--lg">
                              <div className="pro-image" style={{padding:30}}>
                              <QRCode value={reataurantid} />
                              </div>
                               
                                  <div className="title" style={{textAlign:"center"}} >{strings.MobileQR}</div>
                                
                            </div>
                          </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

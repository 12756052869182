// web
import React, { useContext, useEffect } from "react";
// import ReactDOM from "react-dom";
// import { View } from 'react-native'
import history from '../Screen/utils/history'
// import Spinner from '../Screen/spinner'
import HomeScreen from '../Screen/home/home'
import ForgotPasswordScreen from '../Screen/forgotPassword/forgotPassword';
import ForgotpasswordotpScreen from '../Screen/forgotpasswordotp/forgotpasswordotp';
import Otpverification from '../Screen/otp/otpverification';
import RestaurantRegistrationScreen from '../Screen/restaurantRegistration/restaurantRegistration';
import RestaurantLoginScreen from '../Screen/restaurantLogin/restaurantLogin';
import PrivacypolicyScreen from '../Screen/privacypolicy/privacypolicy';
// import MapScreen from '../Screen/map/map';
import OrdersScreen from '../Screen/orders/orders'
import ItemListScreen from '../Screen/itemList/itemList'
import ReportScreen from '../Screen/report/report'
import DishtypeScreen from '../Screen/dishtype/dishtype'
import AddonsScreen from '../Screen/addons/addons'
import ItemAddScreen from '../Screen/itemAdd/itemAdd'
import MyAccountScreen from '../Screen/myAccount/myAccount'
import OrderScreen from '../Screen/order/order'
import PublicRoute from './PublicRoute'
import Qrcode from '../Screen/qrcode/qrcode';
import TermsCondition from '../Screen/termCondition/termscondition';


import {
  Router,
  Switch,
  Route,
  Redirect
  // Redirect, useHistory, withRouter

} from "react-router-dom";
import { UserContext } from '../component/Context/UserContext';
import PropTypes from "prop-types";




function AuthLoading(props) {
  const [userData, setUserData] = useContext(UserContext);
  useEffect(() => {
    const setdata = async () => {
      const userdata = JSON.parse(await localStorage.getItem('userdata'));
      // console.log('userdatauserdatauserdata', userdata)
      setUserData(userdata)
    }
    setdata()
  }, []);
  return (
    <Router history={history}>
      <Switch>
        <Route path="/myaccount"
          render={(props) => (
            (userData === null || !userData) ?
              (<Redirect to="/home" />)
              :
              (<MyAccountScreen />)
          )}
        />
        <Route path="/item_list"
          render={(props) => (
            (userData === null || !userData) ?
              (<Redirect to="/home" />)
              :
              (<ItemListScreen />)
          )}
        />
        <Route path="/addons"
          render={(props) => (
            (userData === null || !userData) ?
              (<Redirect to="/home" />)
              :
              (<AddonsScreen />)
          )}
        />
        <Route path="/item_add"
          render={(props) => (
            (userData === null || !userData) ?
              (<Redirect to="/home" />)
              :
              (<ItemAddScreen />)
          )}
        />
        <Route path="/orders"
          render={(props) => (
            (userData === null || !userData) ?
              (<Redirect to="/home" />)
              :
              (<OrdersScreen />)
          )}
        />
        <Route path="/order"
          render={(props) => (
            (userData === null || !userData) ?
              (<Redirect to="/home" />)
              :
              (<OrderScreen />)
          )}
        />
        <Route path="/report"
          render={(props) => (
            (userData === null || !userData) ?
              (<Redirect to="/home" />)
              :
              (<ReportScreen />)
          )}
        />

        <Route path="/dishtype"
          render={(props) => (
            (userData === null || !userData) ?
              (<Redirect to="/home" />)
              :
              (<DishtypeScreen />)
          )}
        />
        <Route path="/qrcode"
          render={(props) => (
            (userData === null || !userData) ?
              (<Redirect to="/home" />)
              :
              (<Qrcode />)
          )}
        />
        <Route path="/forgotppassword"
          render={(props) => (
            (userData === null || !userData) ?
              (<ForgotPasswordScreen />)
              :
              (<Redirect to="/home" />)
          )}
        />
        <Route path="/forgotpasswordotp"
          render={(props) => (
            (userData === null || !userData) ?
              (<ForgotpasswordotpScreen />)
              :
              (<Redirect to="/home" />)
          )}
        />
        {/* <Route path="/otpverification">
          <Otpverification />
        </Route> */}


        <Route path="/otpverification"
          render={(props) => (
            (userData === null || !userData) ?
              (<Otpverification />)
              :
              (<Redirect to="/home" />)
          )}
        />
        {/*       
        <Route path="/restaurantregistration">
          <RestaurantRegistrationScreen />
        </Route> */}
        <Route path="/restaurantregistration"
          render={(props) => (
            (userData === null || !userData) ?
              (<RestaurantRegistrationScreen />)
              :
              (<Redirect to="/home" />)
          )}
        />

        <Route path="/restaurantlogin"
          render={(props) => (
            (userData === null || !userData) ?
              (<RestaurantLoginScreen />)
              :
              (<Redirect to="/home" />)
          )}
        />
        <Route path="/privacypolicy">
          <PrivacypolicyScreen />
        </Route>
        <Route path="/termscondition">
          <TermsCondition />
        </Route>
        <Route path="/home">
          {userData ?
            <ItemListScreen /> :
            <HomeScreen />}
        </Route>
        <PublicRoute path="/">
          {userData ?
            <ItemListScreen /> :
            <HomeScreen />}
        </PublicRoute>
      </Switch>
    </Router>
  )
}

export default AuthLoading;
import React, { useState, useEffect, useRef } from 'react'

// import './login.css'
import { apiCall, setDefaultHeader } from '../utils/httpClient'
import ApiEndPoints from '../utils/apiEndPoints'
import Footer from '../footer/footer';
import Header from '../header/header';
import { useHistory } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import Popup from "reactjs-popup";
import strings from '../../language';

export default (props) => {
  const simpleValidator = useRef(new SimpleReactValidator())
  const [otp, setotp] = useState('')
  const [, forceUpdate] = useState('')
  const [phoneNumber, setphoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [type, settype] = useState('')
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(true);
  const [showtimer, setshowtimer] = useState(true);
  const [showPopup, setShowPopup] = useState(false)
  const history = useHistory();
  function toggle() {
    setIsActive(!isActive);
  }

  function reset() {
    setSeconds(0);
    setIsActive(false);
  }


  useEffect(() => {
    setphoneNumber(history.location.state.phoneNumber)
    setCountryCode(history.location.state.countryCode)
    settype(history.location.state.type)
    let interval = null;
    if (isActive) {
      if (seconds == 0) {
        clearInterval(interval);
        setshowtimer(false)
        setSeconds(60);
        setIsActive(false);
      } else {
        interval = setInterval(() => {
          setSeconds(seconds => seconds - 1);
        }, 1000);
      }

    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);



  function validateAllField() {
    if (simpleValidator.current.allValid()) {
      return true;
    } else {
      simpleValidator.current.showMessages(true);
      forceUpdate(1)
      return false;
    }
  }

  const routeChange = async (val) => {
    await localStorage.setItem('Auth', val);
    let path = ``;
    history.push(path);
  }


  const onRegestrationOtp = async () => {
    const isValid = validateAllField()
    const lang = await localStorage.getItem("locale");
    if (isValid) {
      const datas = {
        'otp': otp,
      }
      try {
        const { data } = await apiCall('post',
          ApiEndPoints.restrootpverify, datas)
        if (data.status == 200) {
          data.admin_aproval == 0 ?
            setShowPopup(true)
            :
            console.log(data)
          // routeChange('02')
        } else if (data.status == 201) {
          setErrorMessage(lang == "en-US" ? data.message : lang == "en" ? data.message : data.fr_message)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  const closePopup = () => {
    setShowPopup(false)
    let path = `/home`;
    history.push(path);
  }
  const onLogin = async () => {
    const isValid = validateAllField()
    const lang = await localStorage.getItem("locale");
    if (isValid) {
      const datas = {
        'mobileno': phoneNumber.replace(/^0+/, ""),
        'otp': otp,
        'country_code': countryCode.substring(1),
        'type': type
      }
      try {
        const { data } = await apiCall('post',
          ApiEndPoints.otpVerifyCustomer, datas)
        // console.log('data', data)
        if (data.status == 200) {
          routeChange('01')
        } else if (data.status == 201) {
          setErrorMessage(lang == "en-US" ? data.message : lang == "en" ? data.message : data.fr_message)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }


  const navTopassword = (event) => {
    let path = `forgotpasswordotp`;
    history.push(path, { phoneNumber: phoneNumber, countryCode: countryCode, });
  }
  const onOtp = (event) => {
    setotp(event.target.value)
  }
  const onResendOtp = async () => {
    const lang = await localStorage.getItem("locale");
    const datas = {
      'mobileno': phoneNumber.replace(/^0+/, ""),
      'country_code': countryCode.substring(1),
      'type': 0
    }
    try {
      const { data } = await apiCall('post',
        ApiEndPoints.resendotp, datas)
      if (data.status == 200) {
        setSeconds(60);
        setIsActive(true);
        setshowtimer(true)

      } else if (data.status == 201) {
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onRegotpResend = async () => {
    const datas = {
      'phone': phoneNumber.replace(/^0+/, ""),
      'country_code': countryCode.substring(1),
      'type': 0
    }
    try {
      const { data } = await apiCall('post',
        ApiEndPoints.otpresendrestro, datas)
      if (data.status == 200) {
        setSeconds(60);
        setIsActive(true);
        setshowtimer(true)

      } else if (data.status == 201) {
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <Header />
      <div>
        <div className="main-banner--home">
          <div
            className="bg"
            style={{ backgroundImage: 'url("images/BackGroundImage.svg")' }}
          />
        </div>
        <div className="form-section">
          <div className="outer">
            <div className="wrap">
              <div className="row center-lg">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 center-content-wrapper">
                  <div className="single-caption">
                    <h2>{strings.welcome}</h2>
                    <div className="fs-text">
                      {strings.pleaseEnterOtp}
                    </div>
                  </div>
                  <div className="main-form">
                    <div>
                      <div className="form--group">
                        <input
                          type="text"
                          name="otp"
                          placeholder={strings.enteryOtp}
                          className="form--control"
                          onChange={(event) => onOtp(event)}
                        />
                        <div className="errorTxt">
                          {simpleValidator.current.message('otp', otp, 'required')}
                        </div>
                        <div className="errorTxt">
                          {errorMessage}
                        </div>
                      </div>
                      <div className="spacer10" />
                      <div className="form--group">
                        <button className="btn-submit"
                          onClick={() => type == 1 ? onRegestrationOtp() : onLogin()}>
                          {strings.submit}
                         </button>
                      </div>

                      {
                        showtimer ?
                          <div className="form--group">
                            <a style={{
                              color: "#fff",
                              height: 50, width: 50,
                              textAlign: "center",
                              textAlignLast: "center",
                              background: "#3624DD",
                              paddingTop: 25,
                              padding: 10, borderRadius: 80,
                            }} >
                              {seconds}
                            </a>
                          </div>
                          :
                          <div className="form--group">
                            <a style={{ color: "#fff" }} onClick={() => type == 1 ? onRegotpResend() : onResendOtp()} >
                              {strings.Resendverificationcode}?
                          </a>
                          </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        open={showPopup}
        closeOnDocumentClick
        onClose={() => closePopup()}
        style={{ height: 120 }}
      >
        <div style={{ height: 120, width: "100%", display: 'flex', flexDirection: "column" }}>
          <div>
            <a className="close" style={{ fontSize: 50 }} onClick={() => closePopup()}>
              &times;
          </a>
          </div>
          <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
            <p>
              {strings.waitingtxt}
              {strings.waitingmess}
                        </p>
          </div>
        </div>
      </Popup>
      <Footer />
    </div>
  )
}

import React from "react";
// import { Navbar } from 'react-bootstrap'
import "./footer.css";
import strings from "../../language";

export default ({hasPadding = 0}) => {
  return (
    <footer className="footer">
      <div className={`innerpaddingfooter ${hasPadding ===1? `inner_footer`  : hasPadding ===2&&`inner_footer2`}`} >
        <div className="outer">
          <div className="wrap">
            <div className="row">
              <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a href="/home">
                      <img src="../images/logo.svg" alt="footer" />
                    </a>
                  </div>
                  {/* <div className="copyright">
                    Copyright © 2020 Krustty SAS. All rights reserved.
                  </div> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div className="footer-widget">
                  <div className="widget-title">{strings.yourservice}</div>

                  <div className="ct-link">
                    <a href="#">{strings.contactus} </a>
                  </div>
                  <div className="ct-link">
                    <a href="#">{strings.privacyPolicy}</a>
                  </div>
                  <div className="ct-link">
                    <a href="/termscondition">{strings.termsConditions}</a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 padding">
                <div className="footer-widget padding">
                  <div className="widget-title">{strings.knowyou}</div>

                  <div className="ct-link">
                    <a href="#">{strings.whoweare}</a>
                  </div>
                  <div className="ct-link">
                    <a href="#">Blog</a>
                  </div>
                </div>
                {/* <div  className="copyright">
                    Copyright © 2020 Krustty SAS. All rights reserved.
                  </div> */}
              </div>

              <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                <div className="footer-widget">
                  <div className="widget-title" style={{ fontSize: 19 }}>
                    {strings.stayconnected}
                  </div>
                  <div
                    className="social-links inline-lists"
                    style={{ marginTop: 19 }}
                  >
                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/krustty/?viewAsMember=true"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/krusttyapp/"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/krusttyapp"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                  </div>

                  {/* <div className="ct-link">
                    <a href="mailto:contact@helthy.info">
                      contact@krustty.com
                    </a>
                  </div> */}
                  <div className="ct-link">
                    {/* <a href="tel:+91 1234567890">+91 1234567890</a> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                <div className="download-actions">
                  <ul className="block-lists" style={{ marginTop: 10 }}>
                    <li>
                      <a href="">
                        <span>
                          <img src="../images/googleplay.png" alt="footer" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <span>
                          <img src="../images/appstore.png" alt="footer" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
/*<div className="social-links inline-lists">
                                      <li>
                                        <a target="_blank" href="https://www.linkedin.com/company/krustty/?viewAsMember=true">
                                          <i className="fab fa-linkedin" />
                                        </a>
                                      </li>
                                      <li>
                                        <a  target="_blank" href="https://www.instagram.com/krusttyapp/">
                                          <i className="fab fa-instagram" />
                                        </a>
                                      </li>
                                      <li>
                                        <a  target="_blank" href="https://www.facebook.com/krusttyapp">
                                          <i className="fab fa-facebook-f" />
                                        </a>
                                      </li>
                                    </div>*/

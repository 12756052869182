import React, { useEffect, useState, useRef } from 'react'
import Footer from '../footer/footer';
import Header from '../header/header';
import SideNav from '../sideNav/sideNav';
import { useHistory } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

export default (props) => {
  const history = useHistory();
  const [, forceUpdate] = useState()

  return (
    <>
      <SideNav />
      <div className="content-wrapper bg-gray-light">
        {/* Main content */}
        <div className="content p-0 pt-3">
          <div className="container-fluid">
            <div className="row m-0">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div className="form-horizontal">
                      <div className="form-group row">
                        <label htmlFor="email" className="col-sm-3 col-form-label">
                          Add-on Title
                  </label>
                        <div className="col-sm-9">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="choose-upto"
                          className="col-sm-3 col-form-label"
                        >
                          Choose Upto
                  </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            id="choose-upto"
                            placeholder
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="choose-upto"
                          className="col-sm-3 col-form-label"
                        >
                          Choose Upto
                  </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            id="choose-upto"
                            placeholder
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">&nbsp;</label>
                        <div className="col-sm-9">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                              <input
                                type="text"
                                className="form-control text-center"
                                placeholder="Option"
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                              <input
                                type="text"
                                className="form-control text-center"
                                placeholder="Price"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">&nbsp;</label>
                        <div className="col-sm-9">
                          <button
                            type="button"
                            className="btn btn-block btn-success btn-lg"
                          >
                            Success
                    </button>
                        </div>
                      </div>
                    </div>
                    {/* END OF FORM-HORIZONTAL */}
                  </div>
                  {/* END OF COL */}
                </div>
                {/* END OF ROW */}
              </div>
              {/* /.col-md-6 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content */}
      </div>
      <footer className="main-footer">
        <strong>
          Copyright © 2016-2020 <a href>ITinformatix</a>.
          </strong>
          All rights reserved.
          <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 3.0.5
          </div>
      </footer>
      {/* <Footer /> */}
    </>
  )
}

import React, { useEffect, useState, useRef } from "react";
import Footer from "../footer/footerApp";
import Header from "../header/header";
import SideNav from "../sideNav/sideNav";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import { apiCall, setDefaultHeader } from "../utils/httpClient";
import ApiEndPoints from "../utils/apiEndPoints";
import _ from "lodash";
import { useAlert } from "react-alert";
import "./dishtype.css";
import strings from "../../language";
let dragged;
let over;
export default (props) => {
  const alert = useAlert();
  // error
  const history = useHistory();
  const [showPopup, setShowPopup] = useState(false);
  const [dropType, setDropType] = useState(false);
  const [dishData, setDishData] = useState([]);
  const [serachDropDownData, setSerachDropDownData] = useState([]);
  const [dropDownData, setDropDownData] = useState([]);
  const [dishVal, setDishVal] = useState([]);

  const [dropDownVal, setDropDownVal] = useState([]);

  useEffect(() => {
    getRestaurantTypeOf();
  }, []);

  const getRestaurantTypeOf = async () => {
    try {
      const token = await localStorage.getItem("token");
      const lang = await localStorage.getItem("locale");
      const data = await apiCall(
     
     
    
        "Get",
        ApiEndPoints.dishlistapi + "?lang=" + lang,
        {},
        {
          Accept: "application/json, text/plain, */*",
          token: token,
        }
      );
      console.log('data: dishlist ', data);
      if (data.data.status == 200) {
         setDishData(data.data.data);
         setDishVal(data.data.dishdata);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleSearch = (serachVal) => {
    var specialArray = _.filter(serachDropDownData, (row) =>
      row.label.toLowerCase().match(serachVal.toLowerCase())
    );
    setDropDownData(specialArray);
  };

  const handleDropDown = (item, index) => {
    const newData = [...dropDownData];
    newData[index].status = !newData[index].status;
    setDropDownData(newData);
  };


  const openPopup = (type, data) => {
    setDropDownData(data);
    setSerachDropDownData(data);
    setDropType(type);
    setShowPopup(!showPopup);
  };


  const saveDropVal = () => {
    if (dropType == "dish")
    setDishVal(_.filter(dropDownData, { status: 1 }));
    setDropDownVal([]);
    setShowPopup(!showPopup);
  };



 

  const  saveorder = async () => {
    try {
     
      const token = await localStorage.getItem("token");
      const lang = await localStorage.getItem("locale");
      var items_id =  _.map(dishVal, "type_of_item_id").toString();
    
      const data = await apiCall(
     
        "Get",
        ApiEndPoints.addupdateItemorder + "?item_id=" + items_id,
        {},
        {
          Accept: "application/json, text/plain, */*",
          token: token,
        }
      )

      if (data.data.status == 200) {
        setAsync();
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function setAsync(data) {
    alert.show(strings.successmsg, {
      timeout: 2000, // custom timeout just for this one alert
      type: "success",
      onOpen: () => { }, // callback that will be executed after this alert open
      onClose: () => { }, // callback that will be executed after this alert is removed
    });
  }


  function dragStart(e) {
    dragged = e.currentTarget;
  }

  function dragdishEnd(e) {
    dragged.style.display = "flex";

    e.target.classList.remove("drag-up");
    over.classList.remove("drag-up");

    e.target.classList.remove("drag-down");
    over.classList.remove("drag-down");

    // var data = data;
    var from = Number(dragged.dataset.id);
    var to = Number(over.dataset.id);
    var data = [...dishVal];
    data.splice(to, 0, data.splice(from, 1)[0]);

    //set newIndex to judge direction of drag and drop
    data = data.map((doc, index) => {
      doc.newIndex = index + 1;
      return doc;
    });
    console.log('data: ', data);
    setDishVal(data);
    // this.setState({ data: data });
  }



  function dragOverdish(e) {
    console.log("over", over);
    over = e.target;
    e.preventDefault();

    dragged.style.display = "none";

    if (e.target.tagName !== "LI") {
      return;
    }

    const dgIndex = JSON.parse(dragged.dataset.item).newIndex;
    const taIndex = JSON.parse(e.target.dataset.item).newIndex;
    const animateName = dgIndex > taIndex ? "drag-up" : "drag-down";

    if (over && e.target.dataset.item !== over.dataset.item) {
      over.classList.remove("drag-up", "drag-down");
    }

    if (!e.target.classList.contains(animateName)) {
      e.target.classList.add(animateName);
      over = e.target;
    }
  }
  console.log('dishVal: ', dishVal);
  console.log('dishVal: ', dishVal.length);

  if(dishVal.length>0)
  {
  var dishItems = dishVal.map((item, i) => {
  console.log('item: ', item);
  //  if(item.typeofitemname!=null)
  //  {
    return (
      <li
        data-id={i}
        key={i}
        style={{
          height: "60px",
          border: "solid 1px #cccccc",
          margin: 5,
          borderRadius: "5px",
          background: "#fff",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
        draggable="true"
        onDragEnd={dragdishEnd.bind(this)}
        onDragStart={dragStart.bind(this)}
        data-item={JSON.stringify(item)}
      >
        <label className="col-form-label">&nbsp;</label>
        <div
          className="col-sm-8 col-form-label"
        >
          <label className="col-sm-12">{item.typeofitemname}</label>
        </div>
        <div
          className="col-sm-1"
         
        >
          <i className="nav-icon fas fal fa-trash" style={{ color: "red" }} />
        </div>
        <label className="col-sm-1">&nbsp;</label>
      </li>
    );
      // }
  });

}
 
 
  return (
    <>
      <SideNav />
      <div className="content-wrapper bg-gray-light">
        {/* Main content */}
        <div className="content p-0 pt-3">
          <div className="container-fluid">
            <div className="row m-0">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form-horizontal">
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.orderoftypeofdishes}
                            <span className="required">*</span>
                          </label>
                        
                          {/* <input
                            type="button"
                            style={{ textAlign: "left" }}
                            className="form-control"
                            defaultValue={
                              dishVal.length > 0
                                ? _.map(dishVal, "typeofitemname").toString()
                                : strings.selectyourdish
                            }
                           onClick={() => openPopup("dish", dishData)}
                          /> */}
                        </div>
                        <div className="col-sm-6">
                          <label className="col-sm-1 col-form-label">
                          </label>
                        </div>
                      </div>
                      <ul
                        onDragOver={dragOverdish.bind(this)}
                        className="form-group row"
                      >
                        <label className="col-form-label" />
                        {dishItems}
                      </ul>
                      <div className="form-group row">
                        <div className="col-sm-4">
                          <label className="col-form-label">
                            &nbsp;
                        </label>
                          <button
                            onClick={() => saveorder()}
                            type="button"
                            className="btn btn-block btn-success btn-lg"
                          >
                            {strings.save}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popup
        open={showPopup}
        closeOnDocumentClick
        onClose={() => setShowPopup(false)}
        contentStyle={{ maxWidth: 300 }}
      >
        <div
          style={{
            height: 360,
            minHeight: 100,
          }}
        >
          <input
            type="text"
            placeholder="Chercher"
            style={{
              height: 50,
              width: "90%",
              borderWidth: 0,
              paddingLeft: 14,
            }}
            onChange={(event) => handleSearch(event.target.value)}
          />
          <div
            style={{
              position: "absolute",
              bottom: 100,
              right: 10,
            }}
          >
            <img
              style={{
                height: 20,
                width: 15,
              }}
              src="images/scrollSign.png"
            />
          </div>
          <div
            className="scroll-height"
            style={{
              height: 250,
              overflowY: "auto",
              overflowX: "hidden",
              minHeight: 100,
              padding: 10,
            }}
          >
            {dropDownData.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleDropDown(item, index)}
                  style={{
                    height: 40,
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: '90%',
                  }}
                >
                  <label>
                    {item.typeofitemname}
                  </label>
                  {item.status ? (
                    <img
                      src="images/fill-checkbox.png"
                      style={{ height: 30, width: 30 }}
                    />
                  ) : (
                    <img
                      src="images/checkbox.png"
                      style={{ height: 30, width: 30 }}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">&nbsp;</label>
            <div className="col-sm-8">
              <button
                onClick={() => saveDropVal()}
                type="button"
                className="btn btn-block btn-success btn-lg"
              >
                {strings.save}
              </button>
            </div>
            <label className="col-sm-2 col-form-label">&nbsp;</label>
          </div>
        </div>
      </Popup>
      <Footer />
    </>
  );
};

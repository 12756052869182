import React, { useEffect, useState, useRef } from "react";
import Footer from "../footer/footerApp";
import Header from "../header/header";
import SideNav from "../sideNav/sideNav";
import { useHistory } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Popup from "reactjs-popup";
import { apiCall, setDefaultHeader } from "../utils/httpClient";
import ApiEndPoints from "../utils/apiEndPoints";
import _ from "lodash";
import { useAlert } from "react-alert";
import "./itemadd.css";
import strings from "../../language";
let dragged;
let over;
export default (props) => {
  const alert = useAlert();
  // error
  const history = useHistory();
  const [, forceUpdate] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [timePopup, setShowTimePopup] = useState(false);
  const [dropType, setDropType] = useState(false);

  const [cuisinesData, setCuisinesData] = useState([]);
  const [foodChoiceData, setFoodChoiceData] = useState([]);
  const [typeOfserviceData, setTypeOfserviceData] = useState([]);
  const [dishData, setDishData] = useState([]);
  const [timeforDropDown, settimeforDropDown] = useState([]);
  const [imageForSave, setImageForSave] = useState([]);

  const [serachDropDownData, setSerachDropDownData] = useState([]);
  const [dropDownData, setDropDownData] = useState([]);
  const [dishName, setDishName] = useState("");
  const [price, setPrice] = useState("");
  const [serviceIn, setServiceIn] = useState("");
  const [discount, setDiscount] = useState("");
  const [buyone, setBuyone] = useState(0);
  const [description, setDiscription] = useState("");

  const [cuisinesVal, setCuisinesVal] = useState([]);
  const [serviceVal, setServiceVal] = useState([]);
  const [foodChoiceVal, setFoodChoiceVal] = useState([]);
  const [dishVal, setDishVal] = useState([]);

  const [dropDownVal, setDropDownVal] = useState([]);
  const [serviceTimeVal, setserviceTimeVal] = useState([
    { openTime: "", closeTime: "" },
  ]);
  const [serviceTime, setServiceTime] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [itemId, setItemId] = useState("");
  const [selectAddonPopup, setSelectAddonPopup] = useState(false);
  const [addOnArry, setAddOnArry] = useState([]);
  const [selectaddOnArry, setselectAddOnArry] = useState([]);

  useEffect(() => {
    getRestaurantTypeOf();
  }, [imageArray]);

  const getRestaurantTypeOf = async () => {
    try {
      const token = await localStorage.getItem("token");
      if (history.location.state) {
        const data = history.location.state.data;
        var openTimeshow = data ? data.service_open_time.split(",") : null;
        var closeTimeshow = data ? data.service_close_time.split(",") : null;

        const userdata = await JSON.parse(localStorage.getItem("userdata"))
          .data;
        const openTimeshows = userdata.close_time.split(",");
        const closeTimeshows = userdata.open_time.split(",");

        const timeforDropDowns = openTimeshow[2]
          ? [
            {
              openTimeshow: openTimeshow[0],
              closeTimeshow: closeTimeshow[0],
              showData: `${closeTimeshow[0]}-${openTimeshow[0]}`,
            },
            {
              openTimeshow: openTimeshow[1],
              closeTimeshow: closeTimeshow[1],
              showData: `${closeTimeshow[1]}-${openTimeshow[1]}`,
            },
            {
              openTimeshow: openTimeshow[2],
              closeTimeshow: closeTimeshow[2],
              showData: `${closeTimeshow[2]}-${openTimeshow[2]}`,
            },
          ]
          : openTimeshow[1]
            ? [
              {
                openTimeshow: openTimeshow[0],
                closeTimeshow: closeTimeshow[0],
                showData: `${closeTimeshow[0]}-${openTimeshow[0]}`,
              },
              {
                openTimeshow: openTimeshow[1],
                closeTimeshow: closeTimeshow[1],
                showData: `${closeTimeshow[1]}-${openTimeshow[1]}`,
              },
            ]
            : openTimeshow[0]
              ? [
                {
                  openTimeshow: openTimeshow[0],
                  closeTimeshow: closeTimeshow[0],
                  showData: `${closeTimeshow[0]}-${openTimeshow[0]}`,
                },
              ]
              : [];

        let timeArray = [];
        timeArray = openTimeshows[2]
          ? [
            {
              openTimeshow: openTimeshows[0],
              closeTimeshow: closeTimeshows[0],
              showData: `${closeTimeshows[0]}-${openTimeshows[0]}`,
            },
            {
              openTimeshow: openTimeshows[1],
              closeTimeshow: closeTimeshows[1],
              showData: `${closeTimeshows[1]}-${openTimeshows[1]}`,
            },
            {
              openTimeshow: openTimeshows[2],
              closeTimeshow: closeTimeshows[2],
              showData: `${closeTimeshows[2]}-${openTimeshows[2]}`,
            },
          ]
          : openTimeshows[1]
            ? [
              {
                openTimeshow: openTimeshows[0],
                closeTimeshow: closeTimeshows[0],
                showData: `${closeTimeshows[0]}-${openTimeshows[0]}`,
              },
              {
                openTimeshow: openTimeshows[1],
                closeTimeshow: closeTimeshows[1],
                showData: `${closeTimeshows[1]}-${openTimeshows[1]}`,
              },
            ]
            : openTimeshows[0]
              ? [
                {
                  openTimeshow: openTimeshows[0],
                  closeTimeshow: closeTimeshows[0],
                  showData: `${closeTimeshows[0]}-${openTimeshows[0]}`,
                },
              ]
              : null;

        timeArray.map((item, i) => {
          timeforDropDowns.map((data, index) => {
            if (item.showData == data.showData) timeArray[i].status = true;
          });
        });

        let timefor = _.filter(timeArray, { status: true });
        setServiceTime(timefor);
        settimeforDropDown(timeArray ? timeArray : timeforDropDowns);

        setImageArray(data.image);
        setItemId(data.id);
        setselectAddOnArry(data.type_of_addon ? data.type_of_addon : []);
        setCuisinesVal(data.type_of_cuisines ? data.type_of_cuisines : []);
       
        setServiceVal(data.type_of_service ? data.type_of_service : []);
        setFoodChoiceVal(data.food_choices ? data.food_choices : []);
        setDishVal(data.type_of_item ? data.type_of_item : []);

        setDishName(data.item_name);
        setPrice(data.price);
        setServiceIn(data.preparation_time);
        setDiscount(data.discount);
        setBuyone(data.buy_one_get_one_offer);
        setDiscription(data.description);
      } else {
        const userdata = await JSON.parse(localStorage.getItem("userdata"))
          .data;
        const openTimeshow = userdata.close_time.split(",");
        const closeTimeshow = userdata.open_time.split(",");
        const timeforDropDowns = openTimeshow[2]
          ? [
            {
              openTimeshow: openTimeshow[0],
              closeTimeshow: closeTimeshow[0],
              showData: `${closeTimeshow[0]}-${openTimeshow[0]}`,
            },
            {
              openTimeshow: openTimeshow[1],
              closeTimeshow: closeTimeshow[1],
              showData: `${closeTimeshow[1]}-${openTimeshow[1]}`,
            },
            {
              openTimeshow: openTimeshow[2],
              closeTimeshow: closeTimeshow[2],
              showData: `${closeTimeshow[2]}-${openTimeshow[2]}`,
            },
          ]
          : openTimeshow[1]
            ? [
              {
                openTimeshow: openTimeshow[0],
                closeTimeshow: closeTimeshow[0],
                showData: `${closeTimeshow[0]}-${openTimeshow[0]}`,
              },
              {
                openTimeshow: openTimeshow[1],
                closeTimeshow: closeTimeshow[1],
                showData: `${closeTimeshow[1]}-${openTimeshow[1]}`,
              },
            ]
            : openTimeshow[0]
              ? [
                {
                  openTimeshow: openTimeshow[0],
                  closeTimeshow: closeTimeshow[0],
                  showData: `${closeTimeshow[0]}-${openTimeshow[0]}`,
                },
              ]
              : null;
        // console.log('datadata', userdata.type_of_service)
        settimeforDropDown(timeforDropDowns);
        setServiceIn(userdata.prepration_time);
        //setServiceVal(userdata.type_of_service);
        setCuisinesVal(userdata.cuisines ? userdata.cuisines : []);
      }
      const lang = await localStorage.getItem("locale");
      const data = await apiCall(
        "Get",
        ApiEndPoints.getRestaurantTypeOf + "?lang=" + lang,
        {},
        {
          Accept: "application/json, text/plain, */*",
          token: token,
        }
      );

      if (data.data.status == 200) {
        setCuisinesData(data.data.data.type_of_cuisines);
        setFoodChoiceData(data.data.data.food_choice);
        setTypeOfserviceData(data.data.data.type_of_service);
        setDishData(data.data.data.type_of_item);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchAddonList() {
    const lang = await localStorage.getItem("locale");
    // alert(lang)
    try {
      const token = await localStorage.getItem("token");
      const { data } = await apiCall(
        "get",
        ApiEndPoints.getAddonlist + "?lang=" + lang,
        {
          Accept: "application/json, text/plain, */*",
          token: token,
        }
      );
      if (data.status == 200) {
        setAddOnArry(data.data);
        setSelectAddonPopup(true);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }
  const handleSearch = (serachVal) => {
    var specialArray = _.filter(serachDropDownData, (row) =>
      row.label.toLowerCase().match(serachVal.toLowerCase())
    );
    setDropDownData(specialArray);
  };

  const handleDropDown = (item, index) => {
    const newData = [...dropDownData];
    newData[index].status = !newData[index].status;
    setDropDownData(newData);
  };
  const handleAddon = (item, index) => {
    const newData = [...addOnArry];
    newData[index].status = !newData[index].status;
    setAddOnArry(newData);
  };

  const openPopup = (type, data) => {
    setDropDownData(data);
    setSerachDropDownData(data);
    setDropType(type);
    setShowPopup(!showPopup);
  };
  const saveAddon = () => {
    setselectAddOnArry(_.filter(addOnArry, { status: true }));
    // const val =_.map(_.filter(addOnArry, { status: true }), 'value').toString()
    // console.log("val",val)
    setSelectAddonPopup(false);
  };

  const saveDropVal = () => {
    if (dropType == "cuisines") {
      setCuisinesVal(_.filter(dropDownData, { status: true }));
      setDropDownData([]);
    } else if (dropType == "service") {
      setServiceVal(_.filter(dropDownData, { status: true }));
    } else if (dropType == "choices") {
      setFoodChoiceVal(_.filter(dropDownData, { status: true }));
    } else if (dropType == "dish") {
      setDishVal(_.filter(dropDownData, { status: true }));
    } else if (dropType == "servicetime") {
      // setDishVal(_.filter(dropDownData, { status: true }))
    }
    setDropDownVal([]);
    setShowPopup(!showPopup);
  };
  const handleServiceTime = (item, index) => {
    const newData = [...timeforDropDown];
    newData[index].status = !newData[index].status;
    settimeforDropDown(newData);
  };
  const saveServiceTime = () => {
    setServiceTime(_.filter(timeforDropDown, { status: true }));
    setShowTimePopup(false);
  };

  const handleImage = (e) => {
    const fileObj = [];
    const fileArray = [];
    fileObj.push(e.target.files);

    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(fileObj[0]);
    }
    setImageForSave(fileArray);
  };
  function validateAllField() {
    if (!dishName) {
      alert.show(strings.entername, {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }
    if (serviceVal.length <= 0) {
      alert.show(strings.selecttypeOfService, {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }
    // if (cuisinesVal.length <= 0) {
    //   alert.show('Please select cuisines', {
    //     timeout: 2000, // custom timeout just for this one alert
    //     type: 'error',
    //   })
    //   return false;
    // }

    if (dishVal.length <= 0) {
      alert.show(strings.typeofDishes, {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }

    // if (!discount) {
    //   alert.show('Please enter discount.', {
    //     timeout: 2000, // custom timeout just for this one alert
    //     type: 'error',
    //   })
    //   return false;
    // }

    if (!price) {
      alert.show(strings.selectAvgPrice, {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }
    if (!serviceIn) {
      alert.show(strings.selectService+" in", {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }
    if (!serviceTime.length > 0) {
      alert.show(strings.selectService+" time", {
        timeout: 2000, // custom timeout just for this one alert
        type: "error",
      });
      return false;
    }
    // if (!closeTime) {
    //   alert.show('please select service time', {
    //     timeout: 2000, // custom timeout just for this one alert
    //     type: 'error',
    //   })
    //   return false;
    // }
    return true;
  }
  const addItem = () => {
    const isValid = validateAllField();
    if (isValid) {
      addItems();
    }
  };

  const addItems = async () => {
    try {
      let formData = new FormData(); //formdata object
      formData.append("id", itemId);
      formData.append("item_name", dishName);
      formData.append("fr_item_name", dishName);
      formData.append("description", description);
      formData.append("fr_description", description);
      serviceVal &&
        formData.append(
          "type_of_service_id",
          _.map(serviceVal, "id").toString()
        );
      foodChoiceVal &&
        formData.append(
          "food_choice_id",
          _.map(foodChoiceVal, "id").toString()
        );
      formData.append("preparation_time", serviceIn);
      formData.append(
        "service_close_time",
        _.map(serviceTime, "closeTimeshow").toString()
      );
      formData.append(
        "service_open_time",
        _.map(serviceTime, "openTimeshow").toString()
      );
      formData.append("discount", discount);
      formData.append("buy_one_get_one_offer", buyone);
      dishVal &&
        formData.append("type_of_item_id", _.map(dishVal, "id").toString());
      formData.append("price", price);
      cuisinesVal &&
        formData.append(
          "type_of_cuisines_id",
          _.map(cuisinesVal, "id").toString()
        );
      formData.append(
        "types_of_addon_id",
        _.map(selectaddOnArry, "value").toString()
      );
      // _.map(_.filter(addOnArry, { status: true }), 'value').toString()
      imageForSave.map((file, index) => {
        formData.append("file_name", file[index], file[index].name);
      });
      const data = await apiCall("post", ApiEndPoints.saveItem, formData, {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      });
      if (data.data.status == 200) {
        history.push("/item_list");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteImage = async (id, i) => {
    const img = imageArray;
    img.splice(i, 1);
    setImageArray(img);
    const { data } = await apiCall(
      "get",
      ApiEndPoints.itemImgdelete + "?id=" + id
    );
    if (data.status == 200) {
      alert.show(strings.imageDelete, {
        timeout: 2000, // custom timeout just for this one alert
        type: "success",
        onOpen: () => { }, // callback that will be executed after this alert open
        onClose: () => { }, // callback that will be executed after this alert is removed
      });
    }
  };
  function dragStart(e) {
    dragged = e.currentTarget;
  }
  function dragEnd(e) {
    dragged.style.display = "flex";

    e.target.classList.remove("drag-up");
    over.classList.remove("drag-up");

    e.target.classList.remove("drag-down");
    over.classList.remove("drag-down");

    // var data = data;
    var from = Number(dragged.dataset.id);
    var to = Number(over.dataset.id);
    var data = [...selectaddOnArry];
    data.splice(to, 0, data.splice(from, 1)[0]);

    //set newIndex to judge direction of drag and drop
    data = data.map((doc, index) => {
      doc.newIndex = index + 1;
      return doc;
    });
    setselectAddOnArry(data);
    // this.setState({ data: data });
  }


  function dragdishEnd(e) {
    dragged.style.display = "flex";

    e.target.classList.remove("drag-up");
    over.classList.remove("drag-up");

    e.target.classList.remove("drag-down");
    over.classList.remove("drag-down");

    // var data = data;
    var from = Number(dragged.dataset.id);
    var to = Number(over.dataset.id);
    var data = [...dishVal];
    data.splice(to, 0, data.splice(from, 1)[0]);

    //set newIndex to judge direction of drag and drop
    data = data.map((doc, index) => {
      doc.newIndex = index + 1;
      return doc;
    });
    setDishVal(data);
    // this.setState({ data: data });
  }

  function dragOver(e) {
    console.log("over", over);
    e.preventDefault();

    dragged.style.display = "none";

    if (e.target.tagName !== "LI") {
      return;
    }

    const dgIndex = JSON.parse(dragged.dataset.item).newIndex;
    const taIndex = JSON.parse(e.target.dataset.item).newIndex;
    const animateName = dgIndex > taIndex ? "drag-up" : "drag-down";

    if (over && e.target.dataset.item !== over.dataset.item) {
      over.classList.remove("drag-up", "drag-down");
    }

    if (!e.target.classList.contains(animateName)) {
      e.target.classList.add(animateName);
      over = e.target;
    }
  }

  function dragOverdish(e) {
    console.log("over", over);
    e.preventDefault();

    dragged.style.display = "none";

    if (e.target.tagName !== "LI") {
      return;
    }

    const dgIndex = JSON.parse(dragged.dataset.item).newIndex;
    const taIndex = JSON.parse(e.target.dataset.item).newIndex;
    const animateName = dgIndex > taIndex ? "drag-up" : "drag-down";

    if (over && e.target.dataset.item !== over.dataset.item) {
      over.classList.remove("drag-up", "drag-down");
    }

    if (!e.target.classList.contains(animateName)) {
      e.target.classList.add(animateName);
      over = e.target;
    }
  }

  var dishItems = dishVal.map((item, i) => {
    console.log('item dishItems: ', item);
    return (
      <li
        data-id={i}
        key={i}
        style={{
          height: "60px",
          border: "solid 1px #cccccc",
          margin: 5,
          borderRadius: "5px",
          background: "#fff",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
        draggable="true"
        onDragEnd={dragdishEnd.bind(this)}
        onDragStart={dragStart.bind(this)}
        data-item={JSON.stringify(item)}
      // className="col 12"
      // style={{
      //   background: "#fff",
      //   height: "60px",
      //   borderRadius: 5,
      //   paddingTop: 5,
      //   paddingBottom: 5,
      //   display: 'flex',
      //   alignItems: "center"
      // }}
      >
        <label className="col-form-label">&nbsp;</label>
        <div
          className="col-sm-8 col-form-label"
         // onClick={() => handleAddon(item)}
        >
          <label className="col-sm-12">{item.label}</label>
        </div>
        <div
          className="col-sm-1"
          onClick={() => {
            //  if (window.confirm('Are you sure you wish to delete this item?')) deleteAddon(item, i)
          }}
        >
          <i className="nav-icon fas fal fa-trash" style={{ color: "red" }} />
        </div>
        <label className="col-sm-1">&nbsp;</label>
      </li>
    );
  });
  var listItems = selectaddOnArry.map((item, i) => {
  console.log('item addon: ', item);
    return (
      // <li
      //   data-id={i}
      //   key={i}
      //   style={{ height: "60px", border: "solid 1px #cccccc", margin: "10px 30%", borderRadius: "5px", backgroundColor: "green", color: "#ffffff" }}
      //   draggable='true'
      //   onDragEnd={dragEnd.bind(this)}
      //   onDragStart={dragStart.bind(this)}
      //   data-item={JSON.stringify(item)}
      // >{"ww5w"}</li>

      <li
        data-id={i}
        key={i}
        style={{
          height: "60px",
          border: "solid 1px #cccccc",
          margin: 5,
          borderRadius: "5px",
          background: "#fff",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
        draggable="true"
        onDragEnd={dragEnd.bind(this)}
        onDragStart={dragStart.bind(this)}
        data-item={JSON.stringify(item)}
      // className="col 12"
      // style={{
      //   background: "#fff",
      //   height: "60px",
      //   borderRadius: 5,
      //   paddingTop: 5,
      //   paddingBottom: 5,
      //   display: 'flex',
      //   alignItems: "center"
      // }}
      >
        <label className="col-form-label">&nbsp;</label>
        <div
          className="col-sm-8 col-form-label"
          onClick={() => handleAddon(item)}
        >
          <label className="col-sm-12">{item.name}</label>
        </div>
        <div
          className="col-sm-1"
          onClick={() => {
            //  if (window.confirm('Are you sure you wish to delete this item?')) deleteAddon(item, i)
          }}
        >
          <i className="nav-icon fas fal fa-trash" style={{ color: "red" }} />
        </div>
        <label className="col-sm-1">&nbsp;</label>
      </li>
    );
  });
  function setDiscountVal(params) {
    setDiscount(params);
    console.log("params", params);
  }
  return (
    <>
      <SideNav />
      <div className="content-wrapper bg-gray-light">
        {/* Main content */}
        <div className="content p-0 pt-3">
          <div className="container-fluid">
            <div className="row m-0">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form-horizontal">
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.nameOfdish}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={strings.nameOfdish}
                            defaultValue={dishName}
                            onChange={(event) =>
                              setDishName(event.target.value)
                            }
                          />
                        </div>


                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.choiceofFood}
                          </label>
                          <input
                            type="button"
                            style={{ textAlign: "left" }}
                            className="form-control"
                            defaultValue={
                              foodChoiceVal.length > 0
                                ? _.map(foodChoiceVal, "label").toString()
                                : strings.choiceofFood
                            }
                            onClick={() =>
                              openPopup("choices", foodChoiceData)
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.priceIn}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={strings.priceIn}
                            defaultValue={price}
                            onChange={(event) => setPrice(event.target.value)}
                          />
                        </div>

                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.discount}
                          </label>
                          <input
                            type="text"
                            placeholder={strings.remise}
                            className="form-control"
                            value={discount == 0 ? "" : discount}
                            onChange={
                              (event) => setDiscountVal(event.target.value)
                              //setDiscount(event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.typeofServices}
                            <span className="required">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="button"
                            style={{ textAlign: "left" }}
                            onClick={() =>
                              openPopup("service", typeOfserviceData)
                            }
                            defaultValue={
                              serviceVal.length > 0
                                ? _.map(serviceVal, "label").toString()
                                : strings.typeofServices
                            }
                          />
                        </div>
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.typeOfCusinies}
                          </label>
                          <input
                            type="button"
                            className="form-control"
                            style={{ textAlign: "left" }}
                            onClick={() =>
                              openPopup("cuisines", cuisinesData)
                            }
                            defaultValue={
                              cuisinesVal.length > 0
                                ? _.map(cuisinesVal, "label").toString()
                                : strings.typeOfCusinies
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.typeofDishes}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="button"
                            style={{ textAlign: "left" }}
                            className="form-control"
                            defaultValue={
                              dishVal.length > 0
                                ? _.map(dishVal, "label").toString()
                                : strings.typeofDishes
                            }
                           onClick={() => openPopup("dish", dishData)}
                          />
                           {/* <button
                                    type="button"
                                    className="btn btn-success btn-md"
                                    style={{marginTop:9,height:33}}
                                    onClick={() => openPopup("dish", dishData)}
                                  >
                                    <i className="fa fa-plus" />
                                  </button> */}

                        </div>
                       
                        <div className="col-sm-6">
                          <label className="col-sm-1 col-form-label">
                            {/* {strings.buyoneget} */}
                          </label>
                          <div className="row align-items-center">
                            <label className="col-sm-6 col-form-label text-center">
                              {strings.buyoneget}
                            </label>
                            <div className="col-sm-2">
                              <input
                                style={{ width: 20 }}
                                type="radio"
                                placeholder={strings.buyoneget}
                                className="form-control"
                                defaultValue="Acheter un en obtenir un gratuitement"
                                onChange={() => setBuyone(1)}

                              // buyone==1? checked:
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <ul
                        onDragOver={dragOverdish.bind(this)}
                        className="form-group row"
                      >
                        <label className="col-form-label" />
                        {dishItems}
                      </ul> */}

                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.servicein}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder={strings.servicein}
                            className="form-control"
                            defaultValue={serviceIn}
                            onChange={(event) =>
                              setServiceIn(event.target.value)
                            }
                          />
                        </div>
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.selectPhoto}
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="customFile"
                            onChange={(event) => handleImage(event)}
                            // name="filename"
                            multiple
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.OpeningClosingTime}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="button"
                            style={{ textAlign: "left" }}
                            className="form-control"
                            defaultValue={
                              serviceTime.length > 0
                                ? _.map(serviceTime, "showData").toString()
                                : strings.OpeningClosingTime
                            }
                            onClick={() => setShowTimePopup(!timePopup)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <label className="col-form-label">
                            {strings.chooseAddon}
                          </label>
                          <input
                            onClick={() => fetchAddonList()}
                            type="button"
                            style={{ textAlign: "left" }}
                            className="form-control"
                            defaultValue={
                              selectaddOnArry.length > 0
                                ? _.map(selectaddOnArry, "name").toString()
                                : strings.chooseAddon
                            }
                          />
                        </div>
                      </div>
                      <ul
                        onDragOver={dragOver.bind(this)}
                        className="form-group row"
                      >
                        <label className="col-form-label" />
                        {listItems}
                      </ul>
                     
                      {/* <div className="form-group row">
                        <label className="col-form-label"></label>
                        {selectaddOnArry.map((item, i) => {
                          return (
                            <div
                              style={{
                                padding: 10,
                                backgroundColor: "#fff",
                                margin: 3,
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"space-between"
                              }}>
                              <label
                                style={{
                                  width: 150
                                }}
                                className="col-form-label">
                                {item.label}
                              </label>
                              <i className="nav-icon fas fal fa-trash"
                                style={{ color: "red" }}
                              />
                            </div>
                          )
                        })}
                      </div> */}
                      <div className="form-group row">
                        <div className="col-sm-11">
                          <label className="col-form-label">
                            {strings.discription}
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            defaultValue={description}
                            onChange={(event) =>
                              setDiscription(event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group row" />
                      <div className="form-group row">
                        <div className="col-sm-10 row">
                          <label className="col-form-label">
                            &nbsp;
                        </label>
                    
                          { imageArray.map((item, i) => {
                            if(item !=null)
                            {
                              return (
                                <div key={i} className="col-sm-3">
                                  <div onClick={() => deleteImage(item.id, i)}>
                                    <img
                                      src="images/cancel.png"
                                      style={{
                                        position: "absolute",
                                        height: 20,
                                        width: 20,
                                        left: 5,
                                        top: 5,
                                      }}
                                    />
                                  </div>
                                  <img
                                    src={item.file_name}
                                    style={{
                                      height: 180,
                                      width: 180,
                                      resize: "block",
                                    }}
                                  />
                                </div>
                              );
                            }
                         
                          })}
                        </div>
                        <label className="col-form-label">
                          &nbsp;
                        </label>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-11">
                          <label className="col-form-label">
                            &nbsp;
                        </label>
                          <button
                            onClick={() => addItem()}
                            type="button"
                            className="btn btn-block btn-success btn-lg"
                          >
                            {strings.save}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* .scroll-height::-webkit-scrollbar {
  display: none;
} */}

      <Popup
        open={showPopup}
        closeOnDocumentClick
        onClose={() => setShowPopup(false)}
        contentStyle={{ maxWidth: 300 }}
      >
        <div
          style={{
            height: 360,
            minHeight: 100,
          }}
        >
          <input
            type="text"
            placeholder="Chercher"
            style={{
              height: 50,
              width: "90%",
              borderWidth: 0,
              paddingLeft: 14,
            }}
            onChange={(event) => handleSearch(event.target.value)}
          />
          <div
            style={{
              position: "absolute",
              bottom: 100,
              right: 10,
            }}
          >
            <img
              style={{
                height: 20,
                width: 15,
              }}
              src="images/scrollSign.png"
            />
          </div>
          <div
            className="scroll-height"
            style={{
              height: 250,
              overflowY: "auto",
              overflowX: "hidden",
              minHeight: 100,
              padding: 10,
            }}
          >
            {dropDownData.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleDropDown(item, index)}
                  style={{
                    height: 40,
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: '90%',
                  }}
                >
                  <label>
                    {item.status}
                    {item.label}
                  </label>
                  {item.status ? (
                    <img
                      src="images/fill-checkbox.png"
                      style={{ height: 30, width: 30 }}
                    />
                  ) : (
                    <img
                      src="images/checkbox.png"
                      style={{ height: 30, width: 30 }}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">&nbsp;</label>
            <div className="col-sm-8">
              <button
                onClick={() => saveDropVal()}
                type="button"
                className="btn btn-block btn-success btn-lg"
              >
                {strings.save}
              </button>
            </div>
            <label className="col-sm-2 col-form-label">&nbsp;</label>
          </div>
        </div>
      </Popup>

      <Popup
        open={selectAddonPopup}
        closeOnDocumentClick
        onClose={() => setSelectAddonPopup(false)}
        contentStyle={{maxWidth:300}} 
      >
        <div
          style={{
            height: 360,
            minHeight: 100,
          }}
        >
          <input
            type="text"
            placeholder="Search"
            style={{
              height: 50,
              width: "80%",
              borderWidth: 0,
              paddingLeft: 14,
            }}
            onChange={(event) => handleSearch(event.target.value)}
          />
          <div
            style={{
              position: "absolute",
              bottom: 100,
              right: 10,
            }}
          >
            <img
              style={{
                height: 20,
                width: 15,
              }}
              src="images/scrollSign.png"
            />
          </div>
          <div
            className="scroll-height"
            style={{
              height: 250,
              overflowY: "auto",
              overflowX: "hidden",
              minHeight: 100,
              padding: 10,
            }}
          >
            {addOnArry.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleAddon(item, index)}
                  style={{
                    height: 40,
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: 250,
                  }}
                >
                  <label>{item.name}</label>
                  {item.status ? (
                    <img
                      src="images/fill-checkbox.png"
                      style={{ height: 30, width: 30 }}
                    />
                  ) : (
                    <img
                      src="images/checkbox.png"
                      style={{ height: 30, width: 30 }}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">&nbsp;</label>
            <div className="col-sm-8">
              <button
                onClick={() => saveAddon()}
                type="button"
                className="btn btn-block btn-success btn-lg"
              >
                {strings.save}
              </button>
            </div>
            <label className="col-sm-2 col-form-label">&nbsp;</label>
          </div>
        </div>
      </Popup>

      <Popup
        open={timePopup}
        closeOnDocumentClick
        onClose={() => setShowTimePopup(false)}
        contentStyle={{maxWidth:300}} 
      >
        <div
          style={{
            height: 360,
            minHeight: 100,
          }}
        >
          <div
            style={{
              height: 305,
              overflowY: "scroll",
              overflowX: "hidden",
              minHeight: 100,
              padding: 10,
            }}
          >
            {timeforDropDown.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleServiceTime(item, index)}
                  style={{
                    height: 40,
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: 250,
                  }}
                >
                  <label>
                    {item.status}
                    {item.showData}
                  </label>
                  {item.status ? (
                    <img
                      src="images/fill-checkbox.png"
                      style={{ height: 30, width: 30 }}
                    />
                  ) : (
                    <img
                      src="images/checkbox.png"
                      style={{ height: 30, width: 30 }}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">&nbsp;</label>
            <div className="col-sm-8">
              <button
                onClick={() => saveServiceTime()}
                type="button"
                className="btn btn-block btn-success btn-lg"
              >
                {strings.save}
              </button>
            </div>
            <label className="col-sm-2 col-form-label">&nbsp;</label>
          </div>
        </div>
      </Popup>
      <Footer />
    </>
  );
};

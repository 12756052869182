import React, { useState, useEffect } from 'react'
import Header from '../header/header';
import Footer from '../footer/footer';
export default () => {
  var lang = localStorage.getItem('locale');
  useEffect(() => {
    getlang()
  }, [])


   async function getlang(){
     lang =  await localStorage.getItem('locale');
   

   }


  return (<div style={{ flex: 1, }}>
    <Header bgColor={'black'} />
    {lang==='fr'?
    <div className="main-banner--home">
      <div className="innerpadding">
        <div className="outer">
          <div className="wrap">


            <h2>A. MENTIONS LEGALES (Partenaire) </h2>

            <p>Le site " https://www.krustty.com" et les applications mobiles et tablettes Krustty sont édités par : </p>
            <h6>Krustty SAS </h6>
            <p>Siège social : 4 Avenue Du General Balfourier 75016 Paris, France</p>
            <p>RCS PARIS 851 796 953</p>
            <p>N° TVA Intracom : FR76851796953</p>
            <p>Téléphone : +33 951044029</p>
            <p>Email : contact@krustty.com</p>
            <br />
            <p>Le site https://www.krustty.com est hébergé par : </p>
            <p>IBM Cloud</p>
            <p>17 Avenue de l'Europe, </p>
            <p>92275 Bois-Colombes</p>
            <p>France</p>
            <p><a href="https://www.ibm.com/cloud">https://www.ibm.com/cloud</a></p>


            <h2>B. CONDITIONS D'UTILISATIONS</h2>

            <p>Dernière mise à jour : 17 mai 2021 </p>
            <p>Les présentes Conditions d’Utilisation ainsi que la Politique de Confidentialité forment votre contrat avec Krustty (si vous êtes propriétaire d’un restaurant acceptant les réservations par le biais du Application/Site Krustty, ces dispositions s’appliquent également à vous en plus des conditions générales et particulières de vente que vous avez acceptées lors de votre inscription). Lisez-les attentivement ; ces dispositions vous engagent dès lors que vous utilisez le Application/Site Krustty. </p>
            <p>Attention : Si vous n'êtes pas en accord avec tout ou partie des présentes Conditions d’Utilisation, il vous est vivement recommandé de ne pas utiliser le Application/Site Krustty. </p>

            <h4>TITRE 1. CONDITIONS GENERALES D’UTILISATION </h4>
            <p>Définitions </p>
            <p><b>« Krustty » </b>: désigne la société Krustty SAS. </p>
            <p><b>« Site Krustty » </b>: désigne l’ensemble des sites web et mobile accessibles à l’adresse " https://www.krustty.com" et des applications mobiles et tablettes Krustty. </p>
            <p><b>« Utilisateur  » </b>:  désigne toute personne physique âgée de plus de 18 ans, capable de souscrire des contrats, visitant le Application/Site Krustty et/ou titulaire d’un compte sur le Application/Site Krustty.</p>
            <p><b>«  Conditions d’Utilisation  » </b>: désigne l’ensemble des présentes conditions générales d’utilisation et conditions particulières d’utilisation. </p>
            <p><b>«Contenu Restaurateur » </b>: désigne les éléments de la fiche restaurant fournis à Krustty par le restaurateur ou que le restaurateur a autorisé Krustty à téléchargé sur le Application/Site Krustty, notamment les prix des plats et des boissons, le prix moyen d’un repas, le nom du chef, les disponibilités du restaurant, la carte, le calendrier, les thématiques du restaurant et les photos du restaurant. </p>
            <p><b>« Contenu Utilisateur  » </b>:  désigne l’ensemble des éléments fournis par Partenaire sur le SApplication/Site Krustty, composés notamment de texte, document, image, photo, avis ou droit de réponse. </p>

            <p>Acceptation et modification des Conditions d’Utilisation</p>
            <p>Un service de recherche et de réservation en ligne de plats des restaurant est proposé par Krustty à Partenaire, sous réserve de son acceptation inconditionnelle des présentes Conditions d’Utilisation. </p>
            <p>Partenaire déclare et reconnaît avoir lu l'intégralité des termes des présentes Conditions d’Utilisation. En outre, la connexion à l'un quelconque des services proposés sur le Site Krustty emporte une acceptation sans réserve par Partenaire des présentes Conditions d’Utilisation </p>
            <p>Krustty se réserve la possibilité de modifier à tout moment, en tout ou partie, les Conditions d’Utilisation. Il appartient en conséquence à Partenaire  de consulter régulièrement la dernière version des Conditions d’Utilisation affichée sur le Application/Site Krustty. Partenaire  est réputé accepter cette dernière version à chaque nouvelle connexion sur le Application/Site Krustty. </p>
            <p>En accédant au Application/Site Krustty ou en l’utilisant de quelque façon que ce soit, Partenaire accepte d’être lié par les présentes Conditions d’Utilisation. </p>
            <h5>1. Description du Site Krustty</h5>
            <p>L’utilisation du Application/Site Krustty est soumise à des limitations de responsabilité de la part de Krustty. Merci de vous référer à l’article 8 « Limitation de Responsabilité » pour en connaître le détail. </p>
            <h5>1.1 Plateforme de mise en relation </h5>
            <p>Le Application/Site Krustty est une plateforme permettant la mise en relation de Partenaire avec les restaurants présents sur le Application/Site Krustty. A ce titre, Application/Site Krustty agit exclusivement comme un intermédiaire dans le cadre de la réservation desdits plats par Partenaire. </p>
            <h6>1.2 Recherche et réservation de tables de restaurant en temps réel</h6>
            <p>Le Application/Site Krustty permet notamment à Partenaire de rechercher et de réserver en temps réel une table dans un restaurant. </p>
            <p>Le Application/Site Krustty permet également à Partenaire d’annuler sa réservation en cas d’imprévu. </p>
            <h6>1.3 Offres promotionnelles proposées par les restaurants référencés sur Krustty</h6>
            <p>Le Application/Site Krustty permet à Partenaire , si et seulement s'il réserve par le biais du Application/Site Krustty, de bénéficier d’offres promotionnelles proposées par les restaurants référencés sur le Application/Site Krustty. Tous les restaurants ne proposent pas d’offres promotionnelles. Les conditions de validité de ces offres promotionnelles sont explicitées sur le Application/Site Krustty. Partenaire  s'oblige à vérifier les conditions de validité d'une offre promotionnelle avant de réserver sur le Application/Site Krustty et ne pourra en aucun cas réclamer au restaurant le bénéfice d’une offre promotionnelle en dehors des conditions de validité telles qu'explicitées sur le Application/Site Krustty, et/ou en dehors de la procédure de réservation avec offre promotionnelle. </p>
            <p>Parmi ces conditions de validité, Partenaire  et l’ensemble de ses convives doivent se présenter au restaurant réservé à l’horaire de réservation. Partenaire  est informé que si lui et l’ensemble de ses convives ne se sont pas présentés au restaurant dans les 15 minutes suivant l’horaire de réservation, le restaurant pourra ne pas appliquer l’offre promotionnelle associée à la réservation à Partenaire  ainsi qu’à l’ensemble de ses convives. Par souci de clarté, cela signifie que si Partenaire  ou un des convives est en retard de plus de 15 minutes, à la fois Partenaire  et tout le groupe de ses convives perdront le bénéfice automatique de l’offre promotionnelle et que le restaurant pourra seul décider d’appliquer ou non l’offre promotionnelle à tout ou partie des convives.</p>
            <h5>2. Gratuité du Site Krustty </h5>
            <p>Les services proposés sur le Application/Site Krustty sont généralement gratuits. Des tarifs pourront éventuellement être applicables, notamment en fonction de l'évolution des services proposés, l'évolution du réseau, de la technique et/ou des contraintes légales. Partenaire  en sera alors dûment informé par la modification des présentes Conditions d’Utilisation et/ou par l'insertion dans le Application/Site Krustty de conditions particulières relatives aux services payant</p>
            <h4>3. Services et sites tiers </h4>
            <h5>3.1 Services offerts par des tiers </h5>
            <p>Partenaire reconnaît que le Application/Site Krustty renvoie à des prestations de service payantes. En particulier, toute réservation effectuée sur le Application/Site Krustty renvoie à une prestation de restauration effectuée par un tiers restaurateur qui sera payante.</p>
            <p>Partenaire  est informé que tout prix ou gamme de prix concernant un tiers prestataire affiché sur le Application/Site Krustty n’est fourni qu'à titre indicatif et par souci de commodité. En aucun cas Krustty ne garantit l'exactitude de telles informations.</p>
            <h5>3.2 Liens vers des sites tiers </h5>
            <p>Le Application/Site Krustty peut contenir des liens renvoyant à des sites gérés par des tiers. Ces hyperliens sont uniquement proposés à titre indicatif. Krustty ne contrôle pas ces sites et n'est pas responsable de leur contenu ni du respect de la vie privée ou d'autres pratiques de ces sites. La présence de liens vers ces sites ne saurait constituer une approbation des éléments présents sur ces sites ni une quelconque association avec les gestionnaires de ces sites. Il appartient à Partenaire  de faire toutes vérifications qui lui semblent nécessaires ou opportunes avant d’utiliser ces sites et/ou de procéder à une quelconque transaction avec l'un d’eux. </p>
            <h4>Accès et Utilisation du Site Krustty </h4>
            <h5>4.1 Conditions d’éligibilité</h5>
            <p>L’utilisation du Application/Site Krustty est soumise aux conditions d’éligibilité cumulatives suivantes : en tant qu’Utilisateur (i) vous avez au moins 18 ans ; (ii) vous disposez de la capacité juridique requise pour vous soumettre à des obligations juridiques contraignantes; (iii) et les réservations que vous effectuerez sur le Application/Site Krustty, pour vous ou pour toute autre personne pour laquelle vous êtes légalement autorisé à agir, seront légitimes.
          </p>
            <h5>4.2 Accès au Site Krustty</h5>
            <p>Krustty se réserve le droit de suspendre, supprimer ou de modifier tout ou partie du Application/Site Krustty ou des services offerts sur le Application/Site Krustty sans préavis. Krustty se réserve de plus le droit de suspendre l’accès au Application/Site Krustty, à tous les, ou à une partie des, Utilisateurs pour maintenance, en cas d’urgence (cyberattaque, etc.) ou pour toute autre raison, et ce à tout moment. </p>
            <h5>4.3 Conditions d’ouverture d’un compte</h5>
            <p>En ouvrant un compte, Partenaire  accepte, expressément et sans réserve, les termes des présentes Conditions d’Utilisation. </p>
            <p>Partenaire  s'engage à transmettre des renseignements exacts, à jour et véritables notamment sur sa civilité, son nom, son ou ses prénoms, son adresse email, son numéro de téléphone, nécessaires à sa bonne identification, en vue de l'ouverture et du maintien de son compte.</p>
            <h5>4.4 Confidentialité des identifiants du compte </h5>
            <p>Lors de la création de son compte, Partenaire  choisit un identifiant et un mot de passe (ciaprès « Identifiants ») lui permettant d'accéder à son compte.</p>
            <p>Les Identifiants sont personnels et confidentiels. Ils ne peuvent être changés que sur demande de Partenaire  ou à l'initiative de Krustty. </p>
            <p>Partenaire  est seul et entièrement responsable de l'utilisation de son compte et/ou des Identifiants le concernant et s'engage à mettre tout en œuvre pour conserver secret ses Identifiants et à ne pas les divulguer, à qui que ce soit, sous quelque forme que ce soit. </p>
            <p>En cas de perte ou de vol d'un des Identifiants le concernant, Partenaire  est responsable de toute conséquence dommageable de cette perte ou de ce vol, et doit utiliser, dans les plus brefs délais, la procédure lui permettant de les modifier, qui peut être consultée sur la page accessible à l'adresse https://www.krustty.com/mon-compte/informations</p>
            <h5>4.5 Clôture du compte </h5>
            <p>Partenaire  a la possibilité de clôturer son compte à tout moment. Pour cela, il doit envoyer sa demande de clôture de compte par email à : contact@krustty.com. </p>
            <p>Krustty s’efforcera de traiter toute demande de clôture de compte dans un délai raisonnable. </p>
            <p>Partenaire  est informé qu’à compter de la clôture de son compte, celui-ci ne pourra plus bénéficier des services offerts par Krustty, ses réservations en cours seront annulées et ses Avantagess éventuellement cumulés seront supprimés. </p>
            <h5>4.6 Obligations de Partenaire  – Actions Prohibées </h5>
            <p>Partenaire  s’engage à utiliser le Application/Site Krustty conformément aux présentes Conditions d’Utilisation et au droit en vigueur. Notamment, Partenaire  s’engage à ne pas réaliser les actions prohibées ci-dessous:</p>
            <ul>
              <li>Le fait de ne pas se rendre au restaurant sans avoir annulé la réservation au préalable (no-show) constitue une action prohibée. </li>
              <li>Le contenu et les données du Application/Site Krustty (incluant, mais sans s'y limiter, les messages, données, informations, textes, musiques, sons, photos, graphiques, vidéos, cartes, icônes, logiciels, codes ou tout autre élément), tout comme l'infrastructure utilisée pour fournir ces contenus et ces informations appartiennent à Krustty ; Partenaire  accepte de ne pas modifier, copier, distribuer, transmettre, afficher, mettre à disposition, reproduire, publier, octroyer une licence, créer des œuvres dérivées, transférer, ou vendre ou revendre toute information, tout logiciel, produit ou service obtenus depuis ou grâce au Application/Site Krustty. </li>
              <li>Sont strictement interdits toute copie, transmission, reproduction, ré-édition, redistribution ou tout envoi du contenu du Application/Site Krustty, en totalité ou en partie, sans l'autorisation préalable et écrite de Krustty. Pour obtenir cette autorisation, contacter Krustty à l'adresse suivante : </li>
            </ul>
            <p>Krustty SAS </p>
            <p>4 Avenue du Général Balfourier 75016 Paris, France.  </p>
            <p>En outre, Partenaire  s’engage à ne pas :</p>
            <ul>
              <li>Utiliser le Application/Site Krustty ou ses contenus à des fins illégales, illégitimes ou frauduleuses ; </li>
              <li>Déposer sur le Application/Site Krustty de Contenu Utilisateur ou Contenu Restaurateur inexact, illégal et notamment portant atteinte à la vie privée, injurieux, indécent, menaçant, incitant à la haine ou la violence, ou dont les droits de propriété intellectuelle ne lui appartiennent pas et sans la permission expresse du titulaire de droits ; </li>
              <li>Utiliser, surveiller, extraire ou copier l’architecture, le contenu ou les données du Application/Site Krustty ou les actions des Utilisateurs sur le Application/Site Krustty à l'aide d'un robot, d'une araignée, d'un scraper, d’un logiciel espion, d’un enregistreur de frappe clavier ou de tout autre programme ou dispositif automatique ou procédé manuel, à quelque fin que ce soit; </li>
              <li>Violer les restrictions des fichiers d’exclusion des robots sur le Application/Site Krustty ou contourner les mesures visant à empêcher ou limiter l’accès au Application/Site Krustty; </li>
              <li>Prendre de mesure qui impose, ou pourrait imposer une charge déraisonnable ou excessive aux infrastructures du Application/Site Krustty;</li>
              <li>Établir de lien invisible menant au Application/Site Krustty pour toute raison; </li>
              <li>« Encadrer », « refléter » ou incorporer toute partie du Application/Site Krustty dans un autre site; et   </li>
              <li>Essayer de modifier, traduire, adapter, réviser, décompiler, désassembler, assembler à rebours tout programme logiciel utilisé par Krustty en lien avec le Application/Site Krustty ou ses services.</li>
            </ul>
            <h4>4.7 Sanction des manquements contractuels </h4>
            <h5>4.7.1 Suspension ou arrêt définitif du ou des services du Application/Site Krustty </h5>
            <p>En cas d'inexécution ou de non-respect, total ou partiel, par Partenaire  de l'une des obligations ou stipulations prévues par les présentes Conditions d’Utilisation ou en cas d’exécution par Partenaire  d’une ou plusieurs actions prohibées prévue par les présentes Conditions d’Utilisation, ou pour tout autre motif raisonnable Krustty pourra modifier, suspendre, limiter ou supprimer l'accès à tout ou partie du service du Application/Site Krustty, et notamment désactiver le compte de Partenaire , sans préavis et sans que Partenaire  ne puisse réclamer aucune indemnité quelconque, et sans préjudice des indemnités que Krustty pourrait réclamer par ailleurs en justice.</p>
            <h5>4.7.2 Dommages-intérêts </h5>
            <p>Sans préjudice des sanctions opérées par Krustty en vertu de l’article 4.7.1, Krustty sera également en droit de réclamer des indemnités destinées à compenser le préjudice subi.</p>
            <h4>5. Avis et Contenu Utilisateur </h4>
            <p>Partenaire  doit suivre les règles suivantes pour publier tout Contenu Utilisateur et notamment tout avis sur le site concernant un restaurant référencé sur le Application/Site Krustty. </p>
            <h5>5.1 Conditions de publication des avis </h5>
            <ul>
              <li>Pour publier un avis, Partenaire  doit avoir un compte sur le Application/Site Krustty qui l'identifie ou avoir été invité à déposer un avis par un email envoyé par Krustty et avoir réservé ou avoir été invité dans un restaurant par l'intermédiaire du Application/Site Krustty, tout en ayant honoré la réservation dans ce restaurant. Afin d'éviter tout conflit d'intérêt et pour des raisons d'objectivité évidentes, si Partenaire  travaille dans le domaine de la restauration, celui-ci n'est pas autorisé à publier d'avis sur le Application/Site Krustty.</li>
              <li>Afin d'éviter tout conflit d'intérêt et pour des raisons d'objectivité évidentes, si Partenaire  travaille dans le domaine de la restauration, celui-ci n'est pas autorisé à publier d'avis sur le Application/Site Krustty. </li>
              <li>L'avis doit contenir plus de 200 caractères et concerner exclusivement le restaurant au sein duquel Partenaire  s'est rendu. Tout avis mentionnant un autre restaurant pourra être refusé par Krustty.</li>
            </ul>
            <h5>5.2 Motifs de rejet du Contenu Utilisateur </h5>
            <p>Partenaire  est informé que son Contenu Utilisateur peut être rejeté pour les motifs suivants : </p>
            <ul>
              <li>si les « Conditions de publication des avis » énoncées ci-dessus ne sont pas respectées par Partenaire  ; </li>
              <li>si Krustty estime que sa responsabilité civile ou pénale peut être engagée ;</li>
              <li>si le Contenu Utilisateur ou les éléments relatifs à l’identité de l’auteur comportent des injures ou grossièretés ; </li>
              <li>si le Contenu Utilisateur textuel comporte des caractères aléatoires ou des suites de mots sans aucune signification ;</li>
              <li>si le Contenu Utilisateur est sans rapport avec le restaurant noté ; </li>
              <li>si le Contenu Utilisateur est ou est soupçonné d’être une contrefaçon ; </li>
              <li>si l’avis soulève ou si Krustty estime que l’avis comporte des éléments de conflits d’intérêts ou de fraude </li>
              <li>si le Contenu Utilisateur textuel est mal écrit au point d'en être inintelligible ; si un Utilisateur formule un commentaire inapproprié sur un autre Contenu Utilisateur ou son auteur ;</li>
              <li>si le Contenu Utilisateur comporte des informations personnelles ou des éléments susceptibles d'aboutir à un vol d'identité, telles que le nom ou prénom d'individus qui ne sont pas des personnes publiques, un numéro de téléphone, une adresse physique précise ou une adresse email </li>
              <li>adresses email ou numéros de téléphone ; ou</li>
              <li>si le Contenu Utilisateur est clairement du spam. </li>
            </ul>
            <p>Pour plus de précision sur les conditions d’acceptation des photos, cliquez ici . </p>
            <p>En cas d’identification d’un Utilisateur ayant déposé un avis frauduleux ou que Krustty estime être frauduleux, et après avoir appliqué les procédures liées au rejet ou à la suppression d’avis, Krustty pourra désactiver le compte de Partenaire  concerné (ce qui entraine l’annulation des réservations en cours et la suppression des Avantagess éventuellement cumulés), et supprimera l’ensemble des avis liés à cet Utilisateur.</p>
            <h5>5.3 Modération des avis </h5>
            <p>Krustty n’a aucune obligation de modérer ou de supprimer les avis. Krustty procède à une modération des avis, dans le cadre d’une obligation de moyen, dans le but de s'assurer de la conformité des avis aux présentes Conditions d’Utilisation en vue de publier, rejeter ou supprimer cet avis. Chaque avis est soumis à une modération a priori, par filtres automatiques et, en cas de signalement particulier, à une modération humaine. Le délai de modération est de 2 semaines au maximum. Partenaire  peut demander la modération d'un
avis déjà publié à l'adresse contact@krustty.com en précisant les motifs de sa demande de modération. </p>
            <p>Partenaire  qui a publié un avis peut demander a posteriori la suppression de cet avis à l'adresse contact@krustty.com. Si Krustty a connaissance d’une fermeture ou d’un changement de propriétaire d’un restaurant, les avis s’y rapportant seront supprimés. Partenaire  est informé qu'il peut être contacté par Krustty à des fins de vérification de l'authenticité de son avis, par email et/ou par téléphone. Partenaire  est informé que son avis peut être transmis à des sites partenaires de Krustty et être publié sur ces sites partenaires. Afin de faciliter la lecture des avis par les Utilisateurs du Application/Site Krustty, Partenaire  autorise Krustty à publier à côté de l’avis déposé les éléments suivants : date d'inscription au site, nombre d'avis déposés, prénom et 1ère lettre du nom, statut et date du repas. Conformément à la loi ‘Informatique et Libertés’ du 6 janvier 1978 modifiée, Partenaire  dispose d'un droit d'accès, de modification, de rectification et de suppression des données le concernant, ainsi que d'un droit d'opposition pour motifs légitimes. Partenaire  peut exercer ces droits à l'adresse suivante contact@krustty.com en indiquant bien « demande de droit d’accès » dans la ligne d’objet. </p>
            <h5>5.4 Avis de la part de plusieurs convives </h5>
            <p>Partenaire  qui réserve un restaurant pour plusieurs convives est invité à indiquer les adresses email de ses convives pour que leur soient transmises les informations pratiques concernant la réservation (adresse du restaurant, heure de réservation…) Partenaire  reconnaît dans ce cas avoir reçu l'autorisation préalable de ses convives pour transmettre leurs adresses email à Krustty. Chaque convive est ensuite invité à poster un avis sur son expérience dans le restaurant réservé par Partenaire . </p>
            <p>Les données personnelles des convives collectées dans ce contexte ne seront pas réutilisées par La Krustty.</p>
            <h5>5.5 Droit de réponse </h5>
            <p>Chaque Restaurateur dispose d'un droit de réponse afin notamment de : </p>
            <ul>
              <li>donner sa version des faits ; </li>
              <li>remercier le consommateur pour sa contribution ; et/ou </li>
              <li>indiquer les éventuels changements intervenus dans le restaurant depuis la rédaction de l'avis.</li>
            </ul>
            <p>Le droit de réponse doit être exercé dans les 3 mois de la diffusion de l’avis auquel il répond par le biais de l’outil Krustty Admin , ou en écrivant à l’adresse email contact@krustty.com, le cas échéant. La demande d’exercice du droit de réponse par email doit comporter les éléments suivants : </p>
            <ul>
              <li>références de l'avis ;</li>
              <li>identification de son auteur ; </li>
              <li>mention des passages contestés ; </li>
              <li>teneur de la réponse sollicitée (la réponse ne peut être plus longue que l'avis de Partenaire  auquel elle répond). </li>
            </ul>
            <p>La réponse devra être conforme aux dispositions des présentes Conditions d’Utilisation et sera modérée dans les mêmes conditions que les avis des Utilisateurs et publiée le cas échéant à la suite de l’avis auquel elle répond.</p>
            <h5>5.6 Note des plats/restaurants</h5>
            <p>Chaque Utilisateur ayant réservé un restaurant par le Application/Site Krustty et honoré sa réservation dans ce restaurant et ces plats est invité à donner une note correspondant à son expérience de consommation. </p>
            <p>Partenaire  est informé que la note indiquée sur le Application/Site Krustty à côté de chaque plat/restaurant correspond à une moyenne pondérée des notes données. </p>
            <h5>5.7 Utilisation du Contenu Utilisateur </h5>
            <p>Partenaire  convient qu’en publiant le Contenu Utilisateur sur le Application/Site Krustty il accorde automatiquement à Krustty, une autorisation irrévocable, permanente, non exclusive, sans contrepartie pécuniaire d’utiliser, de copier, d’afficher, d’adapter, de modifier, de traduire, de distribuer, de faire distribuer ou de promouvoir, d'incorporer à des publicités ou autres documents, de créer des œuvres dérivées de celui-ci, de mettre en valeur, de distribuer ledit Contenu Utilisateur, dans le monde entier, mais aussi de permettre à des tiers d’agir de même, et ce sur tout support en ligne ou non. En outre, en publiant un Contenu Utilisateur sur le Application/Site Krustty, Partenaire  accorde automatiquement à Krustty tous les droits nécessaires pour interdire tout affichage, agrégation, copie ou exploitation ultérieure dudit Contenu Utilisateur par un tiers à quelque fin que ce soit. Partenaire  s’interdit d’exercer son droit moral sur le Contenu Utilisateur de manière incompatible avec les autorisations ci-dessus accordées. Partenaire  déclare et garantit qu’il est en droit d’accorder les autorisations ci-dessus accordées. </p>
            <h4>6.Politique de Confidentialité </h4>
            <p>Krustty a mis en place une Politique de Confidentialité afin de protéger au mieux les données personnelles des Utilisateurs. Cliquer ici pour consulter notre Politique de Confidentialité actuellement en vigueur, qui s’applique également à l’utilisation du Application/Site Krustty   </p>
            <h5>7. Propriété intellectuelle</h5>
            <h4>7.1 Respect du droit national et international de la propriété intellectuelle </h4>
            <p>Partenaire  s'engage à ne pas soumettre, copier, revendre, rééditer, ou, en général, rendre disponible par quelque forme que ce soit tout contenu, donnée, information ou élément, reçu de Krustty ou disponible sur le Application/Site Krustty, à une autre personne physique ou morale, de tous pays. Partenaire  s'engage à respecter les dispositions ci-après relatives à la propriété intellectuelle.</p>
            <h4>7.2 Propriété des droits </h4>
            <p>Tous les droits, patrimoniaux et moraux, de propriété intellectuelle, afférents aux contenus et aux éléments d'information du Application/Site Krustty appartiennent en propre à Krustty, sous réserve de tout droit patrimonial pouvant appartenir à un tiers et pour lesquels la Société Krustty a obtenu les cessions de droits ou les autorisations nécessaires. </p>
            <p>Les droits conférés à Partenaire  en vue de l'utilisation du Application/Site Krustty et des services fournis par Krustty n'emportent aucune cession ni aucune autorisation d'exploiter ou d'utiliser l'un quelconque des éléments du Application/Site Krustty. </p>
            <h4>7.3 Protection de tous les éléments : Marques, dessins, logos, liens hypertextes, informations, etc. </h4>
            <p>Tous les éléments (marques, dessins, textes, liens hypertextes, logos, images, vidéos, éléments sonores, logiciels, mise en page, bases de données, codes...) contenus sur le Application/Site Krustty et dans les sites associés sont protégés par le droit national et international de la propriété intellectuelle. Ces éléments restent la propriété exclusive de Krustty et/ou de ses partenaires ou concédants. </p>

            <h4>7.4 Interdiction d'utilisation sans autorisation </h4>
            <p>Par conséquent, sauf autorisation préalable et écrite de Krustty et/ou de ses partenaires, Partenaire  ne peut procéder à une quelconque reproduction, représentation, réédition, redistribution, adaptation, traduction et/ou transformation partielle ou intégrale, ou un transfert sur un autre support de tout élément composant ou présent sur le Application/Site Krustty.</p>
            <h4>7.5 Sanctions</h4>
            <p>Partenaire  reconnaît et prend connaissance que le non-respect des dispositions du présent article 7 est constitutif d'un acte de contrefaçon répréhensible tant civilement que pénalement. </p>
            <h4>8. Limitations de responsabilité</h4>
            <h5>8.1 Avertissement </h5>
            <p>Il appartient à Partenaire  de faire toutes vérifications qui semblent nécessaires ou opportunes avant de procéder à une quelconque réservation dans l'un des restaurants référencés sur le Application/Site Krustty. </p>
            <p>Krustty ne garantit aucunement et de quelque façon que ce soit le Contenu Restaurateur ou les services et/ou pratiques commerciales des tiers présents sur le Application/Site Krustty. En ce sens, Krustty ne garantit pas à Partenaire  qu'il soit satisfait des produits, services et/ou pratiques commerciales qu'il a obtenus suite à une réservation par le biais du Application/Site Krustty. </p>
            <p>Krustty ne garantit aucunement et de quelque façon que ce soit la teneur, l’objectivité ou l’exactitude du Contenu Utilisateur, et notamment des avis déposés sur le Application/Site Krustty.</p>
            <h5>8.2 Modification du Site Krustty </h5>
            <p>Toutes les informations contenues sur le Application/Site Krustty sont susceptibles d'être modifiées à tout moment, compte tenu de l'interactivité du Application/Site Krustty, sans que cela puisse engager la responsabilité de Krustty. </p>
            <h5>8.3 Utilisation du Site Krustty </h5>
            <p>Compte tenu des spécificités du réseau Internet, Krustty n'offre aucune garantie d’accès au Application/Site Krustty ou de continuité du service, n'étant tenue à cet égard que d'une obligation de moyen. </p>
            <p>Krustty décline toute responsabilité en cas de de difficulté d’accès au Application/Site Krustty ou pour tout dommage ou perte lié à l'utilisation ou l'impossibilité d'utiliser le Application/Site Krustty ou son contenu, sauf exception prévue par la loi. </p>
            <p>Krustty ne garantit pas que les informations présentées soient détaillées, complètes, vérifiées ou exactes. Le Contenu Restaurateur, les informations, fiches descriptives, et, en général, tout contenu présent sur le Application/Site Krustty sont fournis en "l'état", sans aucune garantie expresse ou tacite de quelque sorte que ce soit. </p>
            <p>Partenaire  reconnaît expressément que les photos présentes sur le Application/Site Krustty ne soient pas contractuelles. </p>
            <p>De façon générale, Partenaire  accepte et reconnaît que la réservation auprès d’un restaurant ne soit pas garantie. En ce sens, Krustty ne garantit pas l'effectivité du service de réservation. La disponibilité est vérifiée en temps réel informatiquement. Toutefois, Krustty ne pouvant pas matériellement vérifier l'exactitude des renseignements collectés et/ou donnés par les restaurants, Partenaire  accepte que la responsabilité de Krustty ne puisse être engagée si Partenaire  ne parvient pas à bénéficier des prestations du restaurant. En effet, les paramétrages du logiciel de réservation en temps réel dépendent pour partie des informations fournies et/ou enregistrées par le restaurant et peuvent ne pas correspondre à la réalité. Ainsi, par exemple et de façon non exhaustive, Partenaire  reconnaît et accepte que la responsabilité de Krustty ne soit en aucun cas recherchée en cas d'annulation de réservation, en cas d'établissement fermé, et ce pour quelque raison que ce soit, ou encore en cas de refus de prestation, et ce pour quelque raison que ce soit. </p>
            <p>De même, Partenaire  accepte que la responsabilité de Krustty ne puisse pas être engagée si Partenaire  ne parvient pas à bénéficier des promotions ou offres spéciales proposées par le restaurant. Partenaire  reconnaît et accepte que la responsabilité de Krustty ne soit en aucun cas recherchée dans le cas où le restaurant n'honorerait pas une promotion ou une offre spéciale, et ce pour quelque raison que ce soit. </p>
            <h5>8.4 Garanties de Partenaire  </h5>
            <p>Partenaire  déclare qu'il connaît parfaitement les caractéristiques et les contraintes de l'Internet. Il reconnaît notamment qu'il est impossible de garantir que les données que Partenaire  aura transmises via Internet seront entièrement sécurisées. Krustty ne pourra être tenue responsable des incidents qui pourraient découler de cette transmission. </p>
            <p>Partenaire  s'engage à indemniser Krustty à hauteur des coûts que Krustty devrait supporter à la suite de toute réclamation ou contestation, judiciaire ou extrajudiciaire, liées à l'utilisation des services de Krustty et notamment le dépôt de Contenu Utilisateur sur le Application/Site Krustty par Partenaire  et garantit Krustty de toute condamnation à ce titre.</p>
            <p>En tout état de cause, Partenaire  reconnaît expressément et accepte d'utiliser le Application/Site Krustty à ses propres risques et sous sa responsabilité exclusive.</p>
            <h5>8.5 Notification et retrait de contenu illicite </h5>
            <p>La Krustty informe tout Utilisateur qu'il peut notifier une réclamation ou une objection quant à des éléments ou des contenus du Application/Site Krustty. </p>
            <p>Si Partenaire  estime que des éléments ou des contenus du Application/Site Krustty sont illicites et/ou contrefont des droits d'auteur qu'il détient, Partenaire  doit adresser une notification à Krustty par Lettre Recommandée avec Accusé de Réception adressée au ‘Service Juridique’ ou par courriel à contact@krustty.com avec la mention « A l’attention du Service Juridique » dans la ligne d’objet et contenant tous les éléments justificatifs de titularité des droits le cas échéant. Une fois cette procédure suivie et après vérification de l'exactitude de la notification, Krustty s'efforcera, dans une mesure raisonnable et dans les meilleurs délais, de retirer le contenu illicite. </p>
            <p>Krustty agit comme intermédiaire dans le cadre de la fourniture de ses services à Partenaire . A ce titre, conformément à l’article 6 I.3 de la loi pour la confiance dans l’économie numérique (LCEN) du 21 juin 2004, modifiée, Krustty ne saurait voir sa responsabilité pénale engagée à raison des informations stockées sur le Application/Site Krustty si elle n'avait pas effectivement connaissance de l'activité ou de l'information illicites (notamment Contenu Utilisateur et Contenu Restaurateur) ou si, dès le moment où elle en a eu connaissance, elle a agi promptement pour retirer ces informations ou en rendre l'accès impossible. </p>
            <h4>9. Dispositions diverses </h4>
            <h5>9.1 Nullité partielle - Dissociation - Titres </h5>
            <p>Dans l'hypothèse où une disposition des présentes conditions générales serait nulle, illégale, inopposable ou inapplicable d'une manière quelconque, la validité, la légalité, l’opposabilité ou l'application des autres dispositions des présentes Conditions d’Utilisation n'en serait aucunement affectée ou altérée, ces autres dispositions demeurant en vigueur et conservant leur plein et entier effet. </p>
            <p>Krustty pourra le cas échéant procéder à la rédaction d'une nouvelle clause ayant pour effet de rétablir la volonté commune des Parties telle qu'exprimée dans la clause initiale, et ce, dans le respect du droit en vigueur applicable aux présentes Conditions d’Utilisation.  </p>
            <p>Les titres des articles des présentes n'ont qu'une valeur indicative et ne doivent pas être considérés comme faisant partie intégrante des présentes Conditions d’Utilisation. </p>
            <h5>9.2 Absence de renonciation </h5>
            <p>Sauf stipulation contraire prévue éventuellement dans les présentes Conditions d’Utilisation, aucune tolérance, inaction, abstention ou omission, aucun retard de Krustty pour se prévaloir de l'un quelconque de ses droits conformément aux termes des présentes, ne portera atteinte audit droit, ni ne saurait impliquer une renonciation pour l'avenir à se prévaloir d'un tel droit. Au contraire, ledit droit demeurera pleinement en vigueur. </p>
            <h5>9.3 Loi applicable </h5>
            <p>Les présentes Conditions d’Utilisation et les relations qui se nouent entre Krustty et Partenaire  sont soumises au droit français. Dans la mesure permise par la loi, en cas de rédaction des présentes Conditions d’Utilisation en plusieurs langues ou de traduction, seule la version française fera foi. </p>
            <h5>9.4 Compétence juridictionnelle </h5>
            <p>Toute contestation et/ou difficulté d'interprétation ou d'exécution des présentes Conditions d‘Utilisation relèvera des tribunaux compétents de la ville de Paris. </p>
            <p>En application de la directive européenne 2013/11/EU du 21 mai 2013 relative au règlement extrajudiciaire des litiges de consommation, veuillez-trouver ci-dessous le lien vers la plateforme européenne de règlement en ligne des litiges de consommation :https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=FR </p>

            <h3>TITRE 3. CONDITIONS PARTICULIERES D’UTILISATION DU SERVICE DE RESERVATION EN LIGNE </h3>
            <p>Les présentes Conditions Particulières d’Utilisation viennent compléter le Titre 1 : Conditions Générales d’Utilisation et les conditions générales de vente du restaurant concerné (le « Restaurant »). En cas de contradiction entre les dispositions du présent Titre 3 : Conditions Particulières d’Utilisation du Service de Réservation en ligne avec PréAutorisation et, soit celles du Titre 1 : Conditions Générales d’Utilisation, ou soit celles des
conditions générales de vente du Restaurant, les dispositions du présent Titre 3 : Conditions Particulières d’Utilisation du Service de Réservation en ligne avec PréAutorisation prévalent. </p>
            <p>Le Restaurant met en œuvre un service de réservation avec pré-autorisation par empreinte bancaire (le « Service de réservation avec pré-autorisation ») permettant de gérer la réservation par Partenaire  d’une table à une date et une heure données et les conséquences de toute non-présentation ou de toute annulation tardive ou modification tardive par Partenaire  de ladite réservation </p>
            <h5>1. Description du service de réservation </h5>
            <p>Le Restaurant fournit le Service de réservation avec pré-autorisation par lequel le Restaurant relève l’empreinte bancaire de la carte bancaire de Partenaire . Ce relevé d’empreinte bancaire permet d’obtenir, auprès de l’établissement bancaire de Partenaire , une pré-autorisation de débit. </p>
            <p>Le Restaurant a fixé un montant forfaitaire par couvert réservé. Ce montant est précisé lors de la réservation. Le montant de l’empreinte bancaire correspondra au montant forfaitaire multiplié par le nombre de couverts réservés (1 couvert par personne). </p>
            <p>Cette pré-autorisation ne constitue pas un pré-paiement du repas de Partenaire . Le paiement du repas s’effectuera uniquement sur place. Le Service de réservation avec pré-autorisation ne constitue qu’une garantie pour le Restaurant qui ne prélève aucune somme au moment de la réservation. </p>
            <p>Toutefois, en cas de non-présentation, d’annulation tardive ou modification tardive, l’empreinte bancaire sera utilisée et le compte bancaire de Partenaire  débité du montant indiqué. Ce débit interviendra dans le délai de sept jours suivant la date du repas non-honoré.   </p>
            <h5>2. Informations nécessaires à la réservation </h5>
            <p>Pour toute réservation, Partenaire  devra fournir, de façon obligatoire, les éléments suivants :</p>
            <ul>
              <li>Civilité </li>
              <li>Nom</li>
              <li>Prénom</li>
              <li>Date de naissance </li>
              <li> Adresse email </li>
              <li>Numéro de téléphone portable </li>
              <li>Titulaire de la carte bancaire (cartes compatibles : VISA, MASTERCARD) ; </li>
              <li>Titulaire de la carte ticket restaurant </li>
              <li>Numéro de carte bancaire ; </li>
              <li>Date d’expiration  </li>
              <li>Code de sécurité ; </li>
            </ul>
            <p>L’entier processus attaché au Service de réservation avec pré-autorisation est sécurisé (formulaire affiché sur une page internet sécurisée et protocole de sécurisation bancaire).
Krustty de ces renseignements destinés à l’utilisation du Service de réservation avec préautorisation est obligatoire. Il n’est pas possible de réserver de table auprès du Restaurant sans fournir les éléments demandés. </p>
            <h4>3. Lutte contre la fraude – plafond d’autorisation bancaire : droit de ne pas valider la réservation </h4>
            <p>Toutes les opérations de pré-autorisation effectuées via le Service de réservation avec préautorisation sont contrôlées par un outil de prévention des fraudes. Dans ce cadre, le Restaurant peut être amené à bloquer la pré-autorisation. Le Restaurant se réserve également le droit d’annuler toute réservation pour laquelle il peut raisonnablement penser qu’elle est frauduleuse, abusive ou qu’elle implique une activité illicite, et ce, même si l’outil de prévention des fraudes n’a pas bloqué la pré-autorisation. </p>
            <p>Il est enfin possible que l’opération de pré-autorisation soit refusée par l’établissement bancaire de Partenaire  en raison de la limite d’utilisation de la carte bancaire telle que définie par ledit établissement bancaire, et ce, même dans l’hypothèse où le compte bancaire de Partenaire  présente les fonds nécessaires à la réservation avec pré-autorisation. </p>
            <h4>4. Confirmation de la réservation</h4>
            <p>Une fois la réservation validée par le Restaurant, Partenaire  recevra un email/SMS reprenant les informations relatives à la réservation (lieu, date, heure, nombre de couverts et montant de l’empreinte bancaire). Il appartient à Partenaire  de vérifier les informations figurant dans ce message et à signaler au Restaurant dans les meilleurs délais tout éventuel oubli ou erreur. </p>
            <p>Dans l’hypothèse où Partenaire  ne recevrait pas de confirmation par email/SMS, Partenaire  est invité à contacter le Restaurant dans les meilleurs délais afin d’obtenir ladite confirmation de la part du Restaurant. </p>
            <h4>5. Conditions de modification ou d’annulation de la réservation et mise en œuvre de la pré-autorisation </h4>
            <p>Partenaire  peut annuler/modifier sa réservation jusqu’à 12 heures avant l’heure prévue du repas. Au-delà, le Restaurant débitera la somme correspondant au montant de l’empreinte bancaire. </p>
            <p>Le débit du compte bancaire renseigné interviendra dans un délai de 7 jours suivant la date du repas non-honoré. </p>
            <p>Le service de réservation avec pré-autorisation étant fournie au Restaurant par la société Krustty SAS, la dénomination de cette société apparaîtra sur le relevé bancaire de Partenaire . </p>
            <p>Partenaire  est informé que la modification de la réservation est soumise aux disponibilités de couverts du Restaurant. Si la modification de la réservation n’est pas possible compte tenu des disponibilités de couverts du Restaurant, le Restaurant proposera à Partenaire  d’annuler sa réservation.</p>
            <h4>6. Absence de droit de rétractation</h4>
            <p>Conformément à l’article L221-28, 12° du Code de la consommation, Partenaire  ne dispose d’aucun droit de rétractation pour les prestations de services de restauration fournies à une date ou à une période déterminée. </p>
            <h4>7. Gestion des litiges </h4>
            <p>En cas de litige relatif aux conditions d’annulation/de modification ou au fonctionnement du Service de réservation avec pré-autorisation et/ou des présentes Conditions d’Utilisation particulières, Partenaire  doit d’abord s’adresser directement au Restaurant, ensuite (en cas de non-résolution) à  Krustty en utilisant l’adresse de contact suivante : contact@krustty.com.</p>
            <h3>C. POLITIQUE DE CONFIDENTIALITE </h3>
            <p>Krustty est un service permettant à ses utilisateurs (« vous », « votre », « vos ») de choisir et de réserver des repas dans les restaurants. Notre site, les sites mobiles et les applications associées (collectivement, notre « site ») sont gérés et traités par Krustty SAS (« Krustty», « nous », « notre », « nos ») qui en est le propriétaire et le responsable de traitement. En accédant à notre site et en utilisant nos services, vous reconnaissez avoir lu et compris la présente Politique de confidentialité, ainsi que les pratiques de collecte et de gestion des informations qui y sont décrites. </p>
            <p>La dernière mise à jour de la présente Politique de confidentialité a été effectuée le 25 mai 2018. Nous pouvons à tout moment modifier de nouveau cette Politique de confidentialité et nous vous invitons à la consulter régulièrement afin d'être informé des éventuelles mises à jour. </p>
            <p><b>Portée </b></p>
            <p>La présente Politique de confidentialité décrit en détail notre politique et nos pratiques concernant la collecte, l'utilisation et la divulgation de vos données personnelles. </p>
            <p>Nous sommes conscients de la confiance que vous nous accordez lorsque vous nous soumettez vos données personnelles. Nous y attachons la plus grande importance et nous nous engageons au plus haut point afin de garantir la sécurité et la confidentialité des données personnelles que vous nous confiez lorsque vous consultez notre site ou utilisez nos services.</p>
            <ul>
              <li>Collecte de vos données personnelles </li>
              <li>Utilisation de vos données personnelles </li>
              <li>Partage des données personnelles collectées </li>
              <li>Durée de conservation de vos données personnelles </li>
              <li>Cookies et autres technologies Web </li>
              <li>Vos droits </li>
              <li>Protection de vos données personnelles </li>
              <li>Informations concernant les mineurs </li>
              <li>Liens externes </li>
              <li>Transferts internationaux </li>
              <li>Modifications apportées à la politique de confidentialité </li>
              <li>Modalités pour nous contacter </li>
            </ul>
            <h5>1. Collecte de vos données personnelles </h5>
            <p>On entend par « Données personnelles » (ou données à caractère personnel) toute information se rapportant à une personne physique à partir de laquelle ladite personne peut être identifiée directement ou indirectement, notamment ses nom et prénom, son numéro de téléphone, ses adresses postale et électronique, sa date de naissance, son mot de passe et ses informations de paiement. Sont exclues les données pour lesquelles l'identité de la personne a été supprimée (données anonymes). </p>
            <p>Dans le cadre de notre activité, nous collectons et traitons les données personnelles de différentes manières. Nous pouvons collecter des données personnelles que vous nous fournissez directement, mais nous recueillons également des données en enregistrant la manière dont vous interagissez avec nos services, par exemple à l'aide de cookies sur notre site. Nous pouvons aussi recevoir des informations de la part de tiers. </p>
            <p>Vous pouvez décider de ne pas nous communiquer vos données personnelles. Cependant, vous devez généralement nous fournir un certain nombre d'informations vous concernant , par exemple pour réserver une table dans un restaurant, recevoir des offres pertinentes proposées par Krustty ou les restaurants, et profiter d'autres avantages auxquels votre statut d'utilisateur inscrit vous donne accès. De plus, certaines informations peuvent également être requises pour que vous soyez en mesure de poser une question et pour nous permettre d'y répondre. Les informations obligatoires sont identifiées sur notre site par un astérisque. En l'absence de tels renseignements, votre demande pourrait ne pas être traitée ou être traitée tardivement. </p>
            <ul>
              <li><b>Les informations que vous nous fournissez</b> :Nous recueillons et stockons les informations que vous nous fournissez sur notre site ou que vous nous envoyez par tout autre moyen. </li>
              <li><b>Informations sur des tiers </b>: Si vous faites une réservation au nom d'un tiers ou invitez des personnes via Krustty, vous devrez entrer leurs données personnelles. Vous devez obtenir le consentement de ces tiers avant de nous fournir leurs données personnelles et vous garantissez l'avoir dûment obtenu</li>
              <li><b>Informations issues d'autres sources :</b>Il se peut également que des informations vous concernant nous soient transmises par des entités affiliées, nos partenaires commerciaux et d'autres tiers indépendants et que celles-ci viennent s'ajouter aux autres informations que nous avons collectées à votre sujet. Ainsi, si vous accédez à notre site après avoir cliqué sur un lien au sein d'un site appartenant à l'un de nos partenaires commerciaux et que vous vous êtes enregistré auprès de ce partenaire, ce dernier peut partager avec nous des informations vous concernant que vous avez pu lui indiquer, notamment vos coordonnées et votre profil. Autre exemple : si vous accédez à des services tiers (services de réseaux sociaux, par exemple) depuis notre site ou avant de vous rendre sur notre site, nous pouvons collecter des renseignements tels que vos noms et prénom, votre adresse email et toute autre information mise à notre disposition à travers ces services. Nous travaillons avec nos partenaires actuels et à venir afin d'améliorer et de personnaliser votre utilisation de notre site conformément aux pratiques exposées dans la présente Politique de confidentialité. </li>
              <li><b>Collecte automatique d'informations :</b>:Nous collectons automatiquement certaines informations à partir de votre ordinateur ou appareil mobile lorsque vous vous rendez sur notre site. Par exemple, nous recueillons vos données de session, notamment votre adresse IP, le logiciel de navigation utilisé et le site de référence. Il se peut également que nous collections des informations relatives à votre activité en ligne (contenu visionné, pages visitées et recherches par exemple). En collectant automatiquement ce type d'informations, nous cherchons notamment à mieux cerner vos centres d'intérêt et préférences et à personnaliser au mieux votre expérience utilisateur. </li>
              <li><b>Les données de géolocalisation et autres informations extraites de vos appareils mobiles :</b>   Dès lors que vous utilisez une application Krustty sur votre appareil mobile, nous pouvons collecter et utiliser les informations vous concernant, généralement de la même manière et aux mêmes fins que lorsque vous utilisez notre site Internet. En outre, nous pouvons recueillir des informations concernant votre localisation avec votre consentement si votre appareil mobile a été configuré pour envoyer ce type d'informations à l'application Krustty (voir les paramètres de confidentialité de votre appareil mobile).Nous pouvons, par exemple, utiliser ces informations pour vous indiquer les avis sur les restaurants proches de vous. Vous pouvez modifier les paramètres de confidentialité de votre appareil mobile à tout moment et désactiver la fonctionnalité de partage des informations sur votre localisation avec l'application Krustty. Toutefois, sachez que si vous désactivez le partage des informations de géolocalisation, cela peut nuire à certaines fonctions de l'application. Pour obtenir de l'aide concernant les
paramètres de confidentialité de votre appareil mobile, nous vous invitons à contacter le fabricant de votre appareil mobile. </li>
            </ul>
            <h5>2. Utilisation de vos données personnelles </h5>
            <p>Nous utilisons les données personnelles collectées à votre sujet à des fins spécifiques, par exemple : pour vous proposer nos services, gérer votre inscription et votre compte, notamment votre accès à notre site et son utilisation, publier vos avis, vos notes et vos photos et effectuer et gérer nos activités commerciales, opérations promotionnelles, programmes incitatifs ou opérations de marketing (y compris les campagnes d'emailing, le programme de fidélisation, le ciblage, le reciblage, le profilage, et la segmentation). </p>
            <p>Comme indiqué ci-dessus, nos activités de traitement de vos données personnelles peuvent également inclure des activités de profilage (c'est-à-dire toute forme de traitement automatisé des données personnelles consistant à utiliser de telles données pour évaluer certains aspects personnels concernant une personne physique, en particulier pour analyser ou prévoir certains aspects concernant les préférences personnelles, intérêts, comportements, lieux ou mouvements de cette personne physique) afin de définir les différents types de clients pour nos produits et services, de maintenir notre site à jour et pertinent (optimisation de l'expérience client), de développer notre activité et d'exécuter notre stratégie de marketing. </p>
            <p>Nous utilisons aussi vos données personnelles pour notre processus de recrutement et pour la formation professionnelle de nos équipes. </p>
            <p>Pour plus d'informations concernant nos activités de traitement de vos données personnelles, veuillez vous reporter à la section ci-dessous : </p>
            <table class="table">
              <thead>
                <tr>
                  <th>Finalité/activité</th>
                  <th>Données personnelles </th>
                  <th>Fondement juridique </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Pour gérer votre inscription et votre compte, y compris pour vous permettre d'accéder à notre site et de l'utiliser.</td>
                  <td><ul>
                    <li>Nom </li>
                    <li>Adresse postale</li>
                    <li>Adresse email </li>
                    <li> Numéro de téléphone portable </li>
                    <li>Mot de passe </li>
                    <li>Adresse IP </li>
                    <li>Données du navigateur et/ou de l'appareil </li>

                  </ul></td>
                  <td>Nécessité pour l'exécution d'un contrat conclu avec vous</td>
                </tr>
                <tr>
                  <td>Pour publier vos avis, notes,messages et autres contenus.</td>
                  <td><ul>
                    <li>Nom </li>
                    <li>Adresse email </li>
                    <li> Contenu fourni par vos soins </li>
                  </ul></td>
                  <td>Nécessité pour l'exécution d'un
                  contrat conclu avec vous. Nos intérêts
                  légitimes (recueillir les suggestions
                  des utilisateurs sur leur expérience au
restaurant).</td>
                </tr>
                <tr>
                  <td>Pour effectuer et gérer les
réservations de restaurant</td>
                  <td><ul>
                    <li>Nom </li>
                    <li>Adresse postale</li>
                    <li>Adresse email </li>
                    <li> Numéro de téléphone portable </li>
                    <li>Adresse IP </li>
                    <li>Données du navigateur et/ou de l'appareil </li>

                  </ul></td>
                  <td>Nécessité pour l'exécution d'un contrat conclu avec vous.</td>
                </tr>
                <tr>
                  <td>Pour exécuter et gérer le
programme de fidélisation</td>
                  <td><ul>
                    <li>Nom </li>
                    <li>Adresse email </li>
                    <li> Numéro de téléphone portable </li>
                    <li>Adresse IP </li>
                    <li>Données du navigateur et/ou de l'appareil </li>

                  </ul></td>
                  <td>Nécessité pour l'exécution d'un
                  contrat conclu avec vous. Nos intérêts
                  légitimes (tenir nos dossiers à jour,
                  gérer efficacement le programme de
fidélisation, prévenir la fraude).</td>
                </tr>
                <tr>
                  <td>Pour communiquer avec vous en
                  général, particulièrement en ce
qui concerne :<ul>
                      <li>notre site ; </li>
                      <li>vos questions et
commentaires ;</li>
                      <li>pour vous informer des
                      changements apportés à
                      nos conditions ou à notre
                      politique de confidentialité
; </li>
                      <li> pour vous demander de
                      laisser un avis ou de
répondre à un sondage ;</li>
                      <li>pour administrer notre site
                      et diagnostiquer les
problèmes. </li>


                    </ul></td>
                  <td><ul>
                    <li>Nom </li>
                    <li>Adresse postale</li>
                    <li>Adresse email </li>
                    <li> Numéro de téléphone portable </li>
                    <li>Adresse IP </li>
                    <li>Données du navigateur et/ou de l'appareil </li>

                  </ul></td>
                  <td>Nos intérêts légitimes (tenir nos
                  dossiers à jour et étudier comment les
                  clients utilisent nos produits/services,
                  répondre à vos questions et
                  commentaires, gérer efficacement
                  notre activité, les services
                  informatiques, la sécurité du réseau,
prévenir la fraude).</td>
                </tr>
                <tr>
                  <td>Pour vous informer des
                  promotions et offres spéciales,
                  ainsi que des services que nous
                  proposons et qui peuvent vous
intéresser.</td>
                  <td><ul>
                    <li>Nom </li>
                    <li>Adresse email </li>
                    <li>Adresse IP </li>
                    <li>Données de session</li>
                    <li>Activité en ligne sur notre site</li>
                    <li>Identifiant</li>
                    <li>Données de localisation</li>
                    <li> Numéro de téléphone portable </li>
                    <li>Données du navigateur et/ou de l'appareil </li>

                  </ul></td>
                  <td>Votre consentement à recevoir des
                  communications marketing par emails, notifications push ou messages
                  texte.
</td>
                </tr>
                <tr>
                  <td>Pour organiser des tirages au
                  sort, des concours ou des
enquêtes.</td>
                  <td><ul>
                    <li>Nom </li>
                    <li>Adresse postale</li>
                    <li>Adresse email </li>
                    <li> Preuve de résidence et
pièce d'identité </li>
                    <li>Coordonnées bancaires
(IBAN et BICS) </li>
                    <li>Contenu fourni par vos soins </li>

                  </ul></td>
                  <td>Nécessité pour l'exécution d'un
                  contrat conclu avec vous.
                  Notre intérêt légitime (vérifier
                  l'éligibilité, étudier comment les clients
                  utilisent nos produits/services, les
                  développer et poursuivre l'expansion
                  de notre activité).
</td>
                </tr>
                <tr>
                  <td>Pour gérer votre candidature et le
                  processus de recrutement dans
                  le cadre de votre candidature à
un poste chez Krustty.</td>
                  <td><ul>
                    <li>Nom </li>
                    <li>Adresse email </li>
                    <li>Numéro de téléphone</li>
                    <li>Adresse postale</li>
                    <li>CV</li>

                  </ul></td>
                  <td>Nécessité de prendre des mesures à
                  la demande du candidat avant de
                  conclure un contrat de travail.
                  Notre intérêt légitime (administrer
                  notre processus de recrutement)
</td>
                </tr>
                <tr>
                  <td>Pour administrer et protéger notre
activité, pour résoudre des différends ou des problèmes et pour prévenir des activités potentiellement interdites ou illégales.</td>
                  <td><ul>
                    <li>Nom </li>
                    <li>Adresse postale</li>
                    <li>Adresse email </li>
                    <li>Numéro de
téléphone</li>
                    <li>Identifiant</li>
                    <li>Adresse IP </li>
                    <li>Données de session </li>

                  </ul></td>
                  <td>Notre intérêt légitime (pour la gestion
                  de notre entreprise, pour prévenir la
                  fraude et les autres activités interdites
ou illégales).</td>
                </tr>
                <tr>
                  <td>Pour former nos équipes.</td>
                  <td><ul>
                    <li>Nom </li>
                    <li>Adresse postale</li>
                    <li>Adresse email </li>
                    <li>Numéro de
téléphone</li>
                    <li>Identifiant</li>
                    <li>Adresse IP </li>
                    <li>Données de session </li>
                    <li>Contenu fourni
par vos soins </li>

                  </ul></td>
                  <td>Pour le respect d'une obligation légale
                  à laquelle nous sommes soumis en
                  tant qu'employeur.
                  Notre intérêt légitime (maintien des
                  compétences et enrichissement des
connaissances de nos équipes).</td>
                </tr>
              </tbody>
            </table>
            <h5>La désactivation de votre compte Krustty</h5>
            <p>Nous pouvons utiliser les informations collectées vous concernant pour prendre la décision de
désactiver votre compte, notamment dans les cas suivants :</p>
            <ul>
              <li>non présentation au restaurant au jour et à l'horaire de réservation, à la suite d'une
réservation sur notre site sans annulation préalable (No show), et ce à plusieurs reprises ;</li>
              <li>utilisation de notre site et/ou de nos services contraire à nos Conditions d'utilisation ;</li>
              <li>cas avéré de fraude sur notre site, incluant notamment le dépôt d'un avis frauduleux.
</li>

            </ul>
            <p>Vous serez prévenu de la désactivation de votre compte par e-mail</p>
            <p>La désactivation de votre compte entraîne l'annulation de toutes vos réservations en cours ainsi
que la suppression des Avantagess cumulés, le cas échéant.</p>
            <h6>Marketing</h6>
            <p>Nous voulons que vous puissiez facilement bénéficier de nos services et/ou offres spéciales des
            restaurants présentés sur notre site. Pour ce faire, nous vous envoyons des e-mails et/ou
            notifications Push comportant des informations sur les offres et services susceptibles de vous
intéresser.</p>
            <p>Vous recevrez des communications marketing de notre part si vous avez donné votre
            consentement, si vous nous avez demandé ou si vous nous avez acheté des biens ou des
            services (dans ce cas, nous ne vous adresserons que des communications liées à des produits
et services similaires) et si vous ne vous êtes pas désabonné des communications marketing.</p>
            <p>Notez que vous aurez la possibilité, à chaque fois que vous recevrez un e-mail de ce type,
            d'indiquer que vous ne souhaitez plus en recevoir ou de refuser les notifications Push dans
            l'application ou en modifiant les paramètres de votre appareil mobile. Vous aurez également la
            possibilité de vous désabonner à tout moment des communications marketing. Pour cela, cliquez
            sur le lien de désabonnement figurant en bas de chacun des communications que vous recevrez
de notre part.</p>
            <p>Notez que nous pouvons vous envoyer des communications de service, notamment des
            messages administratifs concernant votre compte ou des messages transactionnels relatifs à
l'une de vos réservations jusqu'à ce que vous fermiez votre compte.</p>
            <h5>3. Partage des données personnelles collectées</h5>
            <p>Nous pouvons partager vos données personnelles avec d'autres parties, y compris d'autres
            sociétés du groupe et des tiers, tels que des restaurants, des sites affiliés ou des applications et
            des prestataires de services qui travaillent pour nous, et plus généralement n'importe lequel de
            nos partenaires commerciaux ou fournisseurs. Si nous vendons ou cédons notre activité ou une
            partie de celle-ci, et que vos données personnelles se rapportent à cette partie vendue ou cédée
            de notre activité, ou si nous fusionnons avec une autre entreprise, nous partagerons vos données
            personnelles avec le nouveau propriétaire de l'entreprise ou notre partenaire de fusion,
            respectivement. Si nous sommes légalement obligés de le faire, nous partagerons les données
            personnelles pour protéger nos clients, notre site, ainsi que notre entreprise, nos droits et notre
établissement.</p>
            <p>Si vous choisissez de créer un profil sur notre site, certaines des informations de votre profil
            seront rendues publiques et pourront donc être consultées en cliquant sur votre nom d'utilisateur.
            Vous aurez la possibilité de consulter votre profil tel qu'il est présenté publiquement. Nous
            demandons à tous les tiers de respecter la sécurité de vos données personnelles et de les traiter
            conformément à la loi. Nous n'autorisons pas nos prestataires tiers à utiliser vos données
            personnelles à leurs propres fins et nous ne leur permettons de traiter vos données personnelles
            qu'à des fins spécifiques et conformément à nos instructions.
</p>
            <p>Pour plus d'informations sur les entités qui pourraient avoir accès à vos données personnelles,
veuillez vous reporter à la section ci-dessous :</p>
            <ul>
              <li><b>Restaurants -</b> Nous partageons les informations vous concernant avec les restaurants
afin de gérer et d'effectuer votre réservation et afin de traiter les plaintes et litiges s'y
rapportant. Lors de votre réservation, vous avez le choix de recevoir ou non des
communications marketing de la part du restaurant concerné. Si vous avez consenti à
recevoir ces communications marketing de la part du restaurant, le restaurant est alors
responsable du traitement des informations vous concernant que nous lui transmettons.</li>
              <li><b>Prestataires </b>tiers qui nous fournissent des services ou agissent en notre nom, notamment
en matière d'analyse commerciale, de traitement des données ou des paiements, de
service client, marketing, relations publiques, diffusion d'enquêtes ou tirages au sort, ou
encore à des fins de prévention des fraudes. Notez que vos informations de paiement
sont traitées par le prestataire de services de paiement lui-même, et non par nos soins ou
par le restaurant ; ni lui ni nous n'y avons accès. Les données relatives à votre carte ne
sont conservées par le prestataire de services de paiement que pour l'exécution de la
transaction, et uniquement pour la durée nécessaire à la gestion des éventuelles
réclamations des titulaires de carte. Ces informations de paiement sont cryptées et
sécurisées . Nous pouvons également autoriser des prestataires tiers à collecter des
informations en notre nom, y compris, par exemple, si cela est nécessaire pour
l'exécution des fonctionnalités de notre site ou pour améliorer la diffusion de publicités en
ligne qui soient ciblées en fonction de vos centres d'intérêt. Les prestataires tiers ont
accès aux informations et peuvent les collecter dans la mesure où cela est nécessaire
pour leur permettre de remplir leurs fonctions. Ils ne sont pas autorisés à les partager ou
à les utiliser pour quelque autre fin que ce soit.</li>
              <li><b>Sites affiliés</b> . Si vous avez été redirigé vers notre site depuis un autre site Internet ou une
autre application, nous pourrons éventuellement partager avec cet affilié des informations
vous concernant, (votre prénom et l'initiale de votre nom de famille) et concernant votre
réservation.</li>

            </ul>
            <h5>4. Durée de conservation des données collectées</h5>
            <p>Nous pouvons conserver dans nos archives certaines des informations associées à votre
            compte, notamment à des fins d'analyses ou pour la tenue de nos registres. La durée de
            conservation de vos données personnelles dépend de la finalité pour laquelle nous les collectons
et de l'utilisation que nous en faisons.</p>
            <p>Nous ne conserverons vos données personnelles que pendant la durée strictement nécessaire
            aux finalités énoncées à l'article 2 « Utilisation de vos données personnelles » ou pour satisfaire
            à nos obligations légales. Lorsqu'elles sont utilisées à des fins de marketing, la période de
            conservation de vos données personnelles est de 3 ans à compter de la date de votre dernière
            activité. Nous pouvons conserver certaines données personnelles pendant une durée plus
            longue, y compris après la fermeture de votre compte, si une telle mesure est nécessaire pour
            remplir nos obligations légales ou pour maintenir la sécurité, pour empêcher la fraude et les abus
            et pour défendre ou faire valoir nos droits. Si vous choisissez de fermer votre compte, vos
            données personnelles cesseront généralement d'être visibles par d'autres personnes sur notre
            site dans un délai de 14 jours.
</p>
            <h5>5. Cookies et autres technologies Web</h5>
            <p>Nous collectons les informations via les cookies et d'autres technologies similaires (pixels
            invisibles). Les cookies sont de petits fichiers texte placés automatiquement sur votre ordinateur
            ou votre appareil mobile lorsque vous visitez un site. Les cookies et pixels invisibles sont stockés
            par votre navigateur Internet. Les cookies contiennent des informations basiques sur votre
            utilisation d'Internet. Votre navigateur renvoie ces cookies à notre site à chaque fois que vous le
            visitez de façon à ce qu'il reconnaisse votre ordinateur ou votre appareil mobile afin de
            personnaliser et d'améliorer votre expérience de navigation.
</p>
            <p>Vous trouverez plus de détails sur les cookies et les technologies similaires (du type pixels
invisibles) que nous utilisons dans notre politique de cookies.
</p>
            <p>Vous trouverez également de plus amples informations sur les cookies en général et,
notamment, comment voir quels cookies sont installés sur votre appareil (mobile) et comment les
gérer et les supprimer ces cookies sur www.allaboutcookies.org/fr/ et
http://www.youronlinechoices.com/fr/.</p>
            <p>Nous utilisons des cookies sur notre site pour remplir les objectifs généraux suivants :
</p>
<ul>
  <li>Identifier votre navigateur lorsque vous revenez sur notre site, enregistrer et se souvenir
de vos éventuelles préférences définies lors de votre précédente visite. Par exemple, si
vous vous êtes inscrit sur notre site, les cookies peuvent nous permettre de conserver
vos informations de connexion, afin que vous n'ayez pas à ressaisir vos informations de
connexion à chaque fois.</li>
  <li>Personnaliser votre expérience de navigation et le contenu de notre site. Ainsi, lorsque
vous consultez une page Internet, un cookie est automatiquement installé par nous, nos
prestataires de services ou nos partenaires afin de reconnaître votre navigateur lorsque
vous accédez à Internet et, ainsi, afficher des informations en fonction de ce qui semble
vous intéresser.</li>
  <li>Mesurer et documenter l'efficacité des différentes fonctionnalités, offres et
communications électroniques (en déterminant quels sont les emails que vous avez
ouverts et si vous avez cliqué sur un lien dans l'email).</li>
 
</ul>
            <p>L'option Paramètres figurant sur la barre d'outils de la plupart des navigateurs devrait vous
indiquer comment empêcher votre navigateur d'accepter de nouveaux cookies, comment recevoir
une notification lorsque vous recevez un nouveau cookie, ou comment désactiver les cookies qui
ne vous sont pas utiles. Notez que si vous refusez les cookies, vous ne pourrez peut-être pas
accéder à plusieurs des outils , contenus ou fonctionnalités proposés par notre site.</p>
<h6>Pixels-espions</h6>
<p>Il est, en outre, possible que notre site utilise des pixels-espions (également appelés GIF
invisibles, balises web ou pixels invisibles), lesquels sont de minuscules images numériques
dotées d'un identifiant unique, dont le fonctionnement est similaire à celui des cookies, et qui sont
placés dans le code d'une page Internet. Nous les utilisons afin de comprendre les schémas de
navigation de nos utilisateurs qui accèdent à une page à partir d'une autre page au sein de notre
site afin de fournir ou de transmettre des informations aux cookies, mais aussi pour savoir si vous
accédez à notre site à partir d'une publicité en ligne affichée sur un site tiers et, enfin, afin
d'améliorer les performances de notre site. Il se peut également que nous autorisions nos
fournisseurs de services à utiliser des pixels-espions afin de nous aider à déterminer quels 
emails ont été ouverts par leurs destinataires et suivre les actions de nos utilisateurs. Nous
pouvons, ainsi, mieux mesurer la pertinence de notre contenu et l'efficacité de nos offres</p>
<h6>Données collectées par des partenaires commerciaux et régies publicitaires afin de diffuser des
publicités ciblées</h6>
<p>Nous autorisons des tiers à collecter des informations concernant vos activités en ligne à travers
des cookies et d'autres technologies. Ces tiers incluent des régies publicitaires, qui recueillent
des renseignements sur vos centres d'intérêt lorsque vous regardez ou interagissez avec l'une de
leurs publicités présentes sur divers sites Internet. Ces informations recueillies par ces tiers sont
ensuite utilisées pour prévoir vos comportements ou préférences et pour afficher des publicités
ciblées sur des sites tiers. Notez que nous n'avons aucun contrôle ni accès aux cookies et autres
technologies que peuvent employer ces tiers pour collecter des informations sur vos centres
d'intérêt et que leurs pratiques en la matière n'entrent pas dans le champ d'application de la
présente Politique de confidentialité. Certaines de ces entreprises sont membres du groupement
Network Advertising Initiative, qui offre un point d'accès unique pour choisir de ne pas recevoir de
publicités ciblées de la part des entreprises membres. Pour en savoir plus, cliquez ici.
</p>
<h6>Signaux d'anti-pistage DNT</h6>
<p>Votre navigateur Internet peut transmettre un signal « DNT » (« Do Not Track ») pour informer
Krustty et les autres sites web que vous ne souhaitez pas être pisté. En raison des différences
entre les configurations par défaut des navigateurs Internet et la manière dont ceux-ci intègrent
les signaux DNT, les exploitants des sites ne savent pas toujours clairement si les utilisateurs ont
réellement sélectionné le paramètre DNT eux-mêmes ou s'ils savent qu'ils peuvent le configurer.
Veuillez noter que Krustty n'est pas en mesure, à ce jour, de tenir compte des signaux DNT.</p>
<h5>6. Vos droits</h5>
<p>Vous avez la possibilité de vérifier, compléter et mettre à jour les informations que vous nous
avez fournies en accédant à la section « Mes informations » de votre compte. Vous pouvez
également clôturer votre compte Krustty dans cette section ou en nous contactant à
contact@krustty.com. Si vous décidez de clôturer votre compte Krustty, nous le désactiverons et
supprimerons les informations de votre profil. Sachez qu'une fois votre compte clôturé, vous ne
pourrez plus vous connecter, accéder à vos informations personnelles, ni utiliser vos Avantagess
cumulés. Néanmoins, vous pourrez ouvrir un nouveau compte à tout moment.</p>
<p>Vous pouvez modifier vos préférences marketing à tout moment, par le biais de la section « Mes
abonnements » de votre compte.</p>
<p>Conformément aux lois et règlements applicables, vous disposez d'un droit d'accès à vos
données personnelles, d'un droit de rectification et d'effacement de ces données, d'un droit de
limitation du traitement de ces données, d'un droit à la portabilité de ces données et d'un droit
d'opposition à leur traitement. Vous avez également le droit de déterminer la manière dont vos
données personnelles seront utilisées et dont vos droits seront exercés après votre décès.</p>
<p>Vous trouverez ci-dessous plus de détails et d'informations sur la manière dont vous pouvez
exercer vos droits et à quel moment. Nous répondrons à votre demande dans un délai d'un mois,
mais avons le droit de proroger ce délai de deux mois le cas échéant, compte tenu de la
complexité et du nombre de demandes.</p>
<p>Pour plus d'informations sur vos droits, veuillez lire les dispositions suivantes :</p>
<p>Vous disposez des droits suivants en ce qui concerne vos données personnelles :</p>
<ul>
  <li>• Le droit de demander l'accès à vos données personnelles. Vous pouvez ainsi recevoir
une copie des données personnelles que nous détenons à votre sujet et vérifier que nous
les traitons en toute légalité.</li>
  <li>Le droit de demander la rectification de vos données si celles-ci sont inexactes. Vous
pouvez également compléter les données incomplètes dont nous disposons, en tenant
compte des finalités du traitement.</li>
  <li>• Le droit de demander la suppression de vos données personnelles si
   <ul>
     <li> vos données personnelles ne sont plus nécessaires aux fins pour lesquelles nous
les avons collectées ou traitées ; ou</li>
     <li>vous retirez votre consentement sur lequel est fondé le traitement de vos
données personnelles et il n'existe pas d'autre fondement juridique au traitement ;
ou</li>
     <li>vous vous opposez au traitement de vos données personnelles et il n'existe pas
de motif légitime impérieux pour le traitement ; ou
</li>
     <li>vos données personnelles font l'objet d'un traitement illicite ; ou</li>
     <li>vos données personnelles doivent être effacées pour respecter une obligation
légale</li>
   </ul></li>
  <li>Le droit de s'opposer au traitement de vos données personnelles. Nous répondrons à
votre demande, à moins que nous ayons un intérêt légitime impérieux pour le traitement
ou que nous ayons besoin de continuer à traiter vos données personnelles pour établir la
légitimité de nos droits ou les faire valoir, pour nous défendre dans le cadre d'une action
en justice.</li>
  <li>Le droit de limiter le traitement des données personnelles, si :
  <ul>
    <li>vous contestez l'exactitude de vos données personnelles alors que nous les
vérifions ; ou</li>
    <li>o le traitement est illicite et vous vous opposez à la suppression de vos données
personnelles et vous demandez la limitation ; ou</li>
    <li>o nous n'avons plus besoin de vos données personnelles à des fins de traitement,
mais celles-ci sont nécessaires dans le cadre d'actions en justice ; ou</li>
    <li>vous vous êtes opposé au traitement, pour la période au cours de laquelle nous
devons vérifier les motifs légitimes impérieux.
</li>
  </ul>
</li>
    <li>Le droit à la portabilité des données. Vous pouvez demander à recevoir les données
personnelles qui vous concernent dans un format structuré, couramment utilisé et lisible
par machine. Vous pouvez également nous demander de transmettre ces données
personnelles à un tiers, lorsque cela est possible. Vous n'avez ce droit que si cette
mesure porte sur les données personnelles que vous nous avez fournies, si le traitement
est fondé sur le consentement ou nécessaire à l'exécution d'un contrat entre vous et
nous, et si le traitement est effectué à l'aide de procédés automatisés.</li>
    <li>Le droit de définir vos instructions post-mortem pour déterminer la manière dont vos
données personnelles seront utilisées et la manière dont vos droits seront exercés après
votre décès. Si nous sommes informés de votre décès, nous effacerons vos données
personnelles, sauf si nous devons les conserver en application des dispositions légales
ou réglementaires et/ou des délais de conservation légaux et, le cas échéant, après leur
communication à un tiers désigné par vos soins.</li>
</ul>

<p>Vous pouvez exercer plusieurs de vos droits via la section « Mes informations » de votre compte.
Pour exercer vos autres droits, vous pouvez en faire la demande par email à
contact@krustty.com.
</p>
<p>Vous n'aurez pas à payer de frais pour accéder à vos données personnelles (ou pour exercer
tout autre droit). Nous pourrons toutefois vous facturer des frais d'un montant raisonnable si votre
demande est clairement infondée, répétitive ou excessive.</p>
<p>Afin de nous aider à confirmer votre identité et à garantir votre droit d'accès à vos données
personnelles (ou d'exercer tout autre droit), nous pouvons vous demander de nous fournir une
copie de votre pièce d'identité. Il s'agit d'une mesure de sécurité visant à garantir que les
données personnelles ne sont pas divulguées à une personne qui n'est pas autorisée à les
recevoir</p>
<p>Nous pouvons également vous contacter pour vous demander des informations complémentaires
concernant votre demande afin de vous répondre plus rapidement.
</p>
<h5>7. Protection de vos données personnelles</h5>
<p>Nous souhaitons que vous utilisez notre site en toute confiance. Pour cela, nous nous engageons
à protéger les informations que nous collectons. Bien qu'aucun site Internet ne puisse garantir
une sécurité absolue en raison des difficultés liées aux activités en ligne, nous avons mis en
place et tenons à jour des mesures organisationnelles, techniques, administratives et physiques
destinées à protéger les données personnelles que vous nous confiez contre tout accès illégal ou
non autorisé, mais aussi contre l'utilisation et la divulgation de ces informations, ou encore les
pertes, dommages, altérations ou destructions accidentels de celles-ci.
Par exemple, nous utilisons le chiffrement des données lorsque nous transférons vos
informations de facturation entre notre système et ceux des autres parties avec lesquelles nous
partageons ces informations. Nous employons également des pare-feu et des systèmes de
détection des intrusions afin d'empêcher toute personne non autorisée d'accéder à vos
informations.</p>
<h5>8. Information concernant les mineurs</h5>
<p>Notre site est un site réservé aux personnes majeures et ne propose pas de services destinés
aux mineurs. Nous ne collectons pas sciemment des données relatives aux mineurs. Si une
personne que nous savons âgée de moins de 18 ans nous envoie des données personnelles,
nous supprimerons ou détruiront ces informations dans les plus brefs délais.
</p>
<h5>9. Liens externes</h5>
<p>Notre site peut inclure des liens vers des sites , des plug-ins et des applications tiers. Si vous
accédez à d'autres sites depuis les liens fournis sur notre site, les opérateurs de ces sites
pourront collecter des informations vous concernant ou les partager. Ces informations seront
utilisées conformément à leur politique de confidentialité, laquelle peut être différente de notre
Politique de confidentialité. Nous ne contrôlons pas ces sites tiers et vous recommandons
d'étudier les politiques de confidentialité publiées sur ces sites tiers afin de bien comprendre leurs
procédures en matière de collecte, d'utilisation et de divulgation des données personnelles.</p>
<h5>10. Transferts internationaux</h5>
<p>Vos données personnelles peuvent être transférées dans d'autres pays de l'Espace économique
européen pour les finalités décrites dans la présente Politique de confidentialité. Lorsque nous
transférons vos données personnelles en dehors de l'Espace économique européen , nous
prenons les précautions suivantes pour que vos données personnelles soient convenablement
protégées.</p>
<p>Les transferts de données personnelles sont réalisés :</p>
<ul>
  <li>vers un pays ou une entreprise reconnus par la Commission européenne comme
assurant un niveau de protection adéquat, ou ;</li>
  <li>• vers un pays n'offrant pas de protection adéquate, mais dont le transfert a été encadré
par les clauses contractuelles types de la Commission européenne.</li>
 
</ul>
<p>En utilisant nos services, vous comprenez que vos données personnelles peuvent être
transférées vers les sociétés de notre groupe, ainsi que vers des sociétés tierces, comme décrit
dans la présente Politique de confidentialité.
</p>
<h5>11. Modifications apportées à la Politique de confidentialité</h5>
<p>Nous pourrons être amenés à modifier ou ajuster la présente Politique de confidentialité
périodiquement. Nous noterons la date à laquelle les dernières révisions ont été apportées à la
première page de la présente Politique de confidentialité . Toutes les modifications seront
effectives dès la publication de ces révisions. Nous informerons nos utilisateurs des modifications
substantielles apportées à la présente Politique de confidentialité, en envoyant une notification à
l'adresse email que vous nous avez indiquée ou en affichant un avis sur notre site. Nous vous
invitons à consulter régulièrement notre Politique de confidentialité afin de prendre connaissance
de la version la plus récente.</p>
<h5>12. Modalités pour nous contacter</h5>
<p>Pour toutes vos questions ou réclamations concernant le traitement de vos données personnelles
par nos soins, veuillez nous contacter à l'adresse contact@krustty.com. Vous avez également le
droit de déposer une plainte auprès de la Commission Nationale de l'Informatique et des Libertés
(CNIL) sur la manière dont nous traitons vos données personnelles. Nous apprécierions toutefois
de pouvoir répondre à vos inquiétudes avant que vous ne vous adressiez à l'autorité de contrôle,
alors n'hésitez pas à nous contacter en premier lieu.</p>
<p>Nos coordonnées complètes sont :
</p>
<p>Krustty SAS, 4 Avenue du Général Balfourier 75016 Paris, France</p>
<p>Email : contact@krustty.com</p>
<p>Attn: Service juridique</p>
<p>Si vous nous contactez par téléphone, veuillez noter que les conversations peuvent être
enregistrées. Nous utilisons ces enregistrements afin d'améliorer la qualité ou la conformité de
notre service client, afin de vérifier l'exactitude des informations que vous fournissez, afin
d'assurer la prévention des fraudes et afin de former nos équipes. Nous conservons ces
enregistrements pour toute la durée nécessaire à ces activités, puis nous les effaçons. Vos
données personnelles vous concernant recueillies durant un appel sont traitées conformément
aux dispositions de la présente Politique de confidentialité.</p>
<p>©2019 Krustty SAS. Tous droits réservés</p>

          </div>
        </div>
      </div>
    </div>
  :
  <div className="main-banner--home">
      <div className="innerpadding">
        <div className="outer">
          <div className="wrap">
           
          </div>
          </div>
          </div></div>}
    <Footer />
  </div>

  )
}

import React, { useEffect, useState, useRef, useContext } from 'react'

import Footer from '../footer/footer';
import Header from '../header/header';
import { useHistory, Redirect } from 'react-router-dom';
import './login.css'
import { apiCall, setDefaultHeader } from '../utils/httpClient'
import ApiEndPoints from '../utils/apiEndPoints'
import SimpleReactValidator from 'simple-react-validator';
import Flag from 'react-world-flags'
import countries from '../../component/countries'
import Popup from "reactjs-popup";
import { UserContext } from '../../component/Context/UserContext';
import strings from '../../language';
import detectBrowserLanguage from "detect-browser-language";


export default (props) => {
  const [userData, setUserData] = useContext(UserContext);
  const history = useHistory();
  const inputEl = useRef(null);
  const simpleValidator = useRef(new SimpleReactValidator({
    locale: detectBrowserLanguage()
  }))
  // const simpleValidator = useRef(new SimpleReactValidator({
  //   messages: {
  //     phoneNumber: 'Phone number is invalid',
  //     'countryCode': 'Country code is invalid',
  //   },
  // }))
  const [searchval, setsearchval] = useState('')
  const [searchPlaceh, setsearchPlaceh] = useState('France')
  const [countriesData, setcountriesData] = useState(countries)
  const [flag, setFlag] = useState("fr")
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('+33')
  const [showflagPicker, setShowflagPicker] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [, forceUpdate] = useState()
  const [password, setpassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const wrapperRef = useRef()
  useEffect(() => {

    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowflagPicker(false)
      }
    }
    // Bind the event listener
    showflagPicker && inputEl.current.focus();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showflagPicker, wrapperRef])
  const stateRef = useRef();
  stateRef.current = userData;
  const onCountryCodeSearch = async (searchText) => {
    setsearchval(dataArray2);
    let data = await countries;
    searchText = searchText.trim().toLowerCase();
    let dataArray2 = data.filter(l => {
      return l.name.toLowerCase().match(searchText);
    });
    setcountriesData(dataArray2);
  }
  const onPressFlagPicker = (country) => {
    setShowflagPicker(!showflagPicker)
    setCountryCode(country.dial_code)
    setFlag(country.code)
    setcountriesData(countries);
    setsearchPlaceh(country.name)
  }
  const onPressFlag = () => {
    setShowflagPicker(!showflagPicker)
  }

  const onLogin = async () => {
    if (simpleValidator.current.allValid()) {
      submitForm()
    } else {
      console.log(simpleValidator.current)
      simpleValidator.current.showMessages(true);
      forceUpdate(1);
    }
  }
  const onForgotPassword = () => {
    let path = `forgotppassword`;
    history.push(path, { phoneNumber: phoneNumber, countryCode: countryCode, flag: flag, type: 1 });
  }
  const submitForm = async () => {
    const lang = await localStorage.getItem("locale");
    try {
      const datas = {
        'phone': phoneNumber.replace(/^0+/, ""),
        'country_code': countryCode.substring(1),
        'password': password,
        lang:lang,
      }
      const { data } = await apiCall('post', ApiEndPoints.logirestro, datas)


      if (data.status == 201) {
        if (data.is_verified == 0) {
          navOtp()
        } else if (data.admin_aproval == 0) {
          navOtp()
        }
        setErrorMessage(lang == "en-US" ? data.message : lang == "en" ? data.message : data.fr_message)
      } else if (data.status == 200) {
        await setDefaultHeader('token', data.token);
        await localStorage.setItem('token', data.token);
        await localStorage.setItem("address",data.data.address);
        await localStorage.setItem("name",data.data.name);
        await localStorage.setItem("id",data.data.id);
        if (data.admin_aproval == 0) {
          setShowPopup(true)
        } else {
          console.log("login data",data);
          routeChange(data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  async function routeChange(data) {
    data.admin_aproval == 0 ?
      setShowPopup(true) :
      await setUserData(data)
    await localStorage.setItem('Auth', '01');
    await localStorage.setItem('userdata', JSON.stringify(data));
    history.push('/item_list', { auth: true });
    // return  <Redirect to={{ pathname: '/item_list', state: { auth: true } }} />
  }
  const navOtp = () => {
    let path = `otpverification`;
    history.push(path, { phoneNumber: phoneNumber, countryCode: countryCode, type: 1 });
  }


  const closePopup = () => {
    setShowPopup(false)
    let path = '/home';
    history.push(path)
  }
  return (
    <div>
      <Header />
      <div className="main-banner--home">
        <div
          className="bg"
          style={{ backgroundImage: 'url("images/BackGroundImage.svg")' }}
        />
      </div>
      <div className="form-section" style={{ maxHeight: 350 }}>
        <div className="outer">
          <div className="wrap">
            <div className="row center-lg center-md">
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 center-content-wrapper">
                <div className="single-caption">
                  <h2>{strings.welcome}</h2>
                  <div className="fs-text">{strings.gladSee}</div>
                </div>
                <div className="main-form">
                  <div>
                    {!showflagPicker &&
                      <div style={{ marginBottom: 15, width: "100%" }}>
                        <div style={{ background: "#fff", paddingTop: 8, paddingBottom: 8 }}>
                          <div
                            onClick={() => setShowflagPicker(!showflagPicker)}
                            className="divcenter"
                            style={{
                              width: "15%", paddingLeft: 15,
                              textAlign: "center", fontSize: 23,
                            }}>
                            <Flag height="30" code={flag} fallback={<Flag code={'Fr'} height="30" fallback={<span>Unknown</span>} />} />
                          </div>
                          <div className="divcenter" style={{ width: "13%", paddingLeft: 5, }}>
                            {countryCode}
                          </div>
                          <div className="divcenter" style={{ width: "65%", paddingLeft: 5, }}>
                            <input
                              type="text"
                              name="phone"
                              placeholder={strings.mobile}
                              value={phoneNumber}
                              className="countryinput"
                              onChange={(event) => setPhoneNumber(event.target.value)}
                            />
                          </div>
                        </div>
                        <div className="errorTxt">
                          {simpleValidator.current.message(strings.mobile, phoneNumber, 'required|min:9|max:11|numeric')}
                          {simpleValidator.current.message('countryCode', countryCode, 'required')}
                        </div>

                      </div>}
                    {
                      showflagPicker &&
                      <div className="form--group" style={{ position: "absolute", }} ref={wrapperRef}>
                        <div style={{
                          height: 260,
                          width: 394,
                          minHeight: 100,
                          backgroundColor: '#ffffff',
                          position: "absolute",
                          zIndex: 1,
                        }}>
                          <input
                            ref={inputEl}
                            style={{ width: "100%", paddingLeft: 25, paddingTop: 10, paddingBottom: 10 }}
                            type="text"
                            placeholder={searchPlaceh}
                            value={searchval}
                            className="countryinput"
                            onChange={(event) => onCountryCodeSearch(event.target.value)}
                            onKeyPress={event => {
                              if (event.key === 'Enter') {
                                event.preventDefault();
                                onLogin()
                              }
                            }}
                          />
                          <div style={{
                            height: 260,
                            width: "100%",
                            minHeight: 100,
                            overflowY: 'scroll',
                            backgroundColor: '#ffffff',
                            zIndex: 1,
                          }}>
                            {
                              countriesData.map((country, index) => {
                                return (
                                  <div
                                    key={index}
                                    onClick={() => onPressFlagPicker(country)}
                                    style={{
                                      color: "#OOO", width: "100%",
                                      cursor: 'pointer', padding: 5, textAlign: "left",
                                      paddingTop: 15, paddingBottom: 15,
                                      paddingLeft: 20,
                                    }}>
                                    <Flag code={country.code} height="16" width="50" fallback={<span>Unknown</span>} />&nbsp;&nbsp;&nbsp;&nbsp;{country.name}
                                  </div>)
                              })
                            }
                          </div>
                        </div>
                      </div>
                    }
                    <div className="form--group">
                      <input
                        type="password"
                        placeholder={strings.choosePassword}
                        className="form--control"
                        name="password"
                        onChange={(event) => setpassword(event.target.value)}
                        style={{ paddingTop: 15, paddingBottom: 15 }}
                        onKeyPress={event => {
                          if (event.key === 'Enter') {
                            event.preventDefault();
                            onLogin()
                          }
                        }}
                      />
                      <div className="errorTxt errorM">
                        {errorMessage}
                        {simpleValidator.current.message(strings.password, password, 'required')}
                      </div>
                    </div>
                    <div className="spacer10" />
                    <div className="form--group">
                      <input
                        type="button"
                        defaultValue={strings.login}
                        className="btn-submit"
                        onClick={() => onLogin()}
                      />
                    </div>
                    <div className="form--group" >
                      <a
                        onClick={() => onForgotPassword()}
                        style={{ fontFamily: "Poppins-Light", color: "#fff" }}>
                        {strings.forgotYpassword}?
                      </a>
                    </div>
                    <Popup
                      open={showPopup}
                      closeOnDocumentClick
                      onClose={() => closePopup(false)}
                    >
                      <div style={{ height: 120, width: "100%", display: 'flex', flexDirection: "column" }}>
                        <div>
                          <a className="close" style={{ fontSize: 50 }} onClick={() => closePopup()}>
                            &times;
                        </a>
                        </div>
                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                          <p>
                            {strings.awaitingApproval}
                            {strings.waitingmess}
                          </p>
                        </div>
                      </div>
                    </Popup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        hasPadding={2}
      />
    </div >
  )
}

import LocalizedStrings from "react-localization";
const strings = new LocalizedStrings({
  en: {
    gallery: "Gallery",
    camera: "Open Camera",
    cancel: "Cancel",
    mobile: "Mobile Number",
    password: "Password",
    register: "Register",
    login: "Login",
    dontaccount: "Don't have an account?",
    forgpass: "Trouble logging in?",
    confirmation: "Confirmation",
    verMessagef: "Please enter the verification code",
    verMessages: "sent to your registered email",
    dReceive: "Didn't receive code?",
    resendCode: "Resend Code",
    waitingtxt: "Waiting for Approval",
    waitingmess: "Please wait for your account verification",
    okay: "Okay",
    //Registration
    landingpartner: "Krustty supports you in your digital development!",
    landingpartner2: "Become a Krusty partner and increase your turnover",
    createAccount: "Create New Account",
    restroName: "Bar/ Restaurant name",
    address: "Address",
    eamiladdress: "E-mail Address",
    mobileno: "Mobile",
    NoTables: "No. of tables",
    capacity: "No of Places",
    preparationTimeinm: "Preparation Time in Minutes",
    chooseachair: "Choose a Chair",
    priceRange: "Average Price of Bar/Resto",
    cousines: "Cuisines",
    typeofServices: "Type of Services",
    typeofDishes: "Type of Dish",
    orderoftypeofdishes:"Order of type of dishes",
    selectyourdish:"select your dish",
    acceptedall: "I have accepted all",
    termandCondition: "Terms & Conditions",
    SignUp: "Sign Up",
    Verificationcode: "Verification code",
    search: "Find Bars/Restos",
    Searchlist:"search",
    done: "Done",
    openmap: "Open map",
    // ownerdetails: "Name of the Owner",
    frenchonwer: "Please enter owner details in French",
    ownerdescription: "Please enter the Business description",
    ownerfrenchdescription:
      "Please enter the Restaurant description (In French)",
    closeTime: "Close Time",
    openTime: "Open Time",
    website: "Website",
    instagram: "Instagram",
    facebook: "Facebook",
    parking: "Parking",
    emailexist: "E-mail Already Exists",
    mobileexist: "Mobile No. already Exists",
    resGallery: "Gallery",
    paymetMethodName: "Payment Method",
    selectPayment: "Please select payment methods",
    servicein: "Served In",
    // Edit Item
    dishNameenglish: "Dish Name",
    dishNamefrench: "Dish Name In French",
    restaurantName: "Business Name",
    dishName: "Dish Name",
    description: "Description of the dish",
    descriptioninfrench: "Description of the dish (FR)",
    size: "Size",
    typeOfCusinies: "Type of cuisines",
    typeOfService: "Type of service",
    foodChoices: "More Information" ,
    cuisines: "Cuisines",
    priceIn: "Price",
    preparationTime: "Preparation Time",
    maximumOrderLimit: "Maximum Order Limit",
    OpeningClosingTime: "Service Time",
    discount: "Discount in %",
    offer: "1 acheté = 1 gratuit",
    selectPhoto: "Select Photo",
    save: "Save",
    buyoneget: "Buy one get one free",
    addOn: "Add Ons",
    ChooseofAddOn: "Choice of Add ons",
    //Home
    items: "Items",
    search: "Search",
    delete: "Delete",
    //drawer
    home: "Home",
    dishes: "Items",
    myAccount: "My Account",
    logout: "Logout",
    orders: "Orders",
    myorder: "My Order",
    Warning: "Warning",
    //Myaccount
    myDetails: "My Details",
    payment: "Payment",
    contactSupport: "Contact Support",
    myaccount: "My Account",
    logoutmsg: "Are you sure you want to logout",
    //Message
    pleaseEnterCountryCode: "Please enter valid country code",
    pleaseEnterMobile: "Please enter Mobile number",
    pleaseEnterPassword: "Please enter Password",
    pleaseEnterEmail: "Please enter email address",
    correctEmail: "Please enter a valid email address",
    enterrestuarantname: "Please enter business name",
    enteraddress: "Please enter address",
    enteremail: "Please enter email",
    ownerdetails: "Please enter name of the owner",
    selectAvgPrice: "Please enter average price",
    selectService: "Please select service",
    ConfirmaWrong: "Verification code entered is incorrect",
    correctenteremail: "Please enter correct email address",
    entermobile: "Please enter mobile number",
    correctentermobile: "Please enter correct mobile number",
    enteropentime: "Please enter open time",
    enterclosetime: "Please enter close time",
    enternumberOfTable: "Please enter number Of table",
    correctenternumberOfTable: "Please enter correct number Of table",
    selectchair: "Please enter number Of chair",
    correctselectchair: "Please enter correct number Of chair",
    selectpriceRange: "Please select price range",
    selectcuisines: "Please select cuisines",
    enterdeshName: "Please enter item name",
    enterdeshfrName: "Please enter dish name in fr",
    selectdish: "Please select item",
    selecttypeOfService: "Please select type of service",
    selectfoodChoices: "Please add more item information",
    selecttermcondition: "Please accept the Term & Conditions",
    selectimage: "Please choose an image",
    usernamepasswordincorrect: "Username and password is incorrect",
    correctpleaseEnterPassword: "Please enter at-least 8 characters/digits",
    passwordmess: "Password must have at least 6 characters",
    passwordnotmatch: "Confirm password is not the same",
    itemdelete: "Are you sure you want to delete the item?",
    itemstatusmsg:
      "Are you sure you want to set the item status : Offline?",
    itemstatusmsgonline:
      "Are you sure you want to set the item status : Online?",
    restaurantstatusmsg:
      "Are you sure you want to set the business status to : Offline?",
    restaurantstatusmsgonline:
      "Are you sure you want to set the business status to : Online?",
    selectpriceRangenume: "Average price should be in integer value",
    //payments
    payments: "Payments",
    //addON
    addOnInFR: "Option",
    addOnInEN: "Option",
    addOnPrice: "Price",
    addon: "Add On's",
    addOntytleInEn: "Add on title (EN)",
    addOntytleInFR: "Title",
    Choiceupto: "Choice upto",
    Addon: "Add on",
    Optional: "Optional",
    Required: "Required",

    //order accept
    TrackUser: "Track User",
    Watingforpayment: "Wating for confirmation",
    accept: "Accept",
    subtotal: "Sub Total",
    salesTax: "Sales Tax",
    YouSave: "You Save",
    Total: "Total",
    AddPromoCode: "Add Promo Code",
    Upcoming: "Upcoming",
    Complete: "Complete",
    Confirmed: "Confirmed",
    Accepted: "Accepted",
    AllOrders: "All Orders",
    orderCancelRes: "Order cancelled by Bar/Resto",
    orderCancelUser: "Order cancelled by user",
    ordercompleted: "Order has been completed",
    orderreport:"Earning report",
    // subtotal:"Sub Total",

    // scanQ
    scan: "Scan",
    Tape: "Tap",
    Reservation: "Reservation",

    // map
    Orchoose: "Or choose a nearby place",
    //orderList
    orderdelete: "Are you sure want to delete order?",
    orderaccept: "Are you sure want to accept order?",
    // confirmedOrderCell
    Waiting: "Waiting",
    Cancel: "Cancel",
    Completed: "Completed",
    Confirmed: "Confirmed",
    CancelByUser: "Cancel By User",
    Accepte: "Accept",
    People: "People",
    Ordercomplete: "Order complete",
    Arrived: "Arrived",
    PaymentDone: "Payment Completed",
    waytorestaurant: "On the way to Bar/Resto",
    //Forgot Password
    forgotPassword: "Forgot Password",
    fMsg:
      "Enter your mobile number to reset your password",
    emailphone: "E-mail",
    submit: "Submit",
    // change password
    Newpassword: "New Password",
    Confirmpassword: "Confirm Password",
    //web
    facilitatesales: "Let's succeed together in reopening your Bar or Restaurant",
    startnow: `Become Krusty Business partner now for free*`,
    becomeapartner: "Become a partner",
    becomeapartnerside: "Become a Partner",
    putBar:
      "Put your bars or restaurants for reservations co Address ORDER HERE me never before",
    downloadApp: "Download the App",
    signin: "Log in",
    nameOfdish: "Name of dish",
    choiceofFood: "Choice of food",
    remise: "Discount",
    chooseAddon: "Choose add-ons",
    discription: "Discription",
    detailsowner: "Owner details",
    disOwner: "Owner's description",
    pricescale: "Price scale",
    titleaddon: "Title of the add-on",
    chooseupto: "Choose up to",
    welcome: "Welcome",
    gladSee: "We are glad to see you again",
    choosePassword: "Choose a password",
    forgotYpassword: "Forgot your password",
    awaitingApproval: "Awaiting Approval",
    become: "Become",
    partner: "partner",
    krusttyand: "Krustty and",
    increaseyour: "increase your",
    ItemDelete: "Item Delete Successfully",
    imageDelete: "Image Delete Successfully",
    // Poppins Medium
    mediumTurn: "turnover",
    signUpmsg:
      "The Krustty technology platform helps restaurants in France extend their reach, satisfy their customers and increase their turnover.",
    becomeToday: "Become a partner today",
    byClick: "By clicking on Send, you accept our Policy",
    byClick2: "Privacy and Cookies Policy and our Terms",
    generalsales: "general sales.",
    termsandconditions: "Terms and conditions",
    readPolicy: "and confirm that you have read the Privacy Policy.",
    toSend: "Send",
    enternumberToforgot: "Enter your phone number for the forgotten password",
    enteryOtp: "Enter your verification code",
    update: "Update",
    pleaseEnterOtp: "Please enter your verification code",
    Resendverificationcode: "Resend verification code",
    privacyPolicy: "Privacy Policy",
    yourservice: "At your service",
    contactus: "Contact us",
    termsConditions: "Terms and conditions",
    aboutsus: "abouts us",
    knowyou: "We tell you everything",
    whoweare: "Who are we ?",
    stayconnected: "Stay connected",
    cartside: "Cart",
    signOut: "Sign out",
    dounloadapp: "Download the App",
    servedin: "Served in",
    discoverKrustty: "Discover Krustty on",
    servicecharges:" * Included service charges",
    Order:"Order",
    datetime:"Date & Time",
    ItemName:"Item Name",
    Quantity:"Quantity",
    foodchoice:"Food Choice",
    Price:"Price",
    Earning:"Earning",
    MobileQR:"Mobile",
    Online:"Online",
    Cash:"Cash",
    Paylater:"Paylater",
    Voucher:"Voucher",
    entername:"Please enter name",
    addontitle:"Please enter add-on title",
    selectchoice: 'Please select Choice',
    choiceselectone:"You have selected",
    choceselecttwo:"choice of  so you need at least selcet",
    deleteitem:"Are you sure you wish to delete this item?",
    order_id:"order id",
    ItemName:"Item Name",
    Quantity:"Quantity",
    FoodCharges:"Food Charges",
    Price:"Price",
    all:"All",
    Weekly:"Weekly",
    Monthly:"Monthly",
    Yearly:"Yearly",
    erreport:"Earning Report",
    successmsg:"Details updated successfully",
   
  },
  fr: {
    gallery: "Galerie",
    camera: "Appareil Photo",
    cancel: "Annuler",
    mobile: "Numéro Téléphone",
    password: "Mot de passe",
    register: "S'inscrire",
    login: "S'identifier",
    dontaccount: "Vous n'êtes pas encore Inscrit?",
    forgpass: "Problème de connexion?",
    confirmation: "Confirmation",
    verMessagef: " Veuillez saisir le code de verification",
    verMessages: "envoyé sur votre email",
    dReceive: "Vous n'avez pas reçu de code?",
    resendCode: "Renvoyer le code",
    waitingtxt: "En attente d'approbation",
    waitingmess: "Veuillez attendre la vérification de votre compte",
    okay: "d'accord",
    //Regestration
    landingpartner: "Krustty vous accompagne dans votre développement digital !",
    landingpartner2: "Devenez partenaire Krustty et augmentez votre chiffre D’affaire",
    createAccount: "Créer un nouveau compte",
    restroName: "Nom du Bar/Restaurant",
    address: "Adresse",
    eamiladdress: "Adresse mail",
    mobileno: "Numéro de Téléphone",
    NoTables: "Nombre des places",
    preparationTimeinm: "Temps de préparation en minutes",
    chooseachair: "Nombre des places",
    priceRange: "Prix moyen",
    cousines: "Cuisines",
    capacity: "Nombre de places",
    cuisines: "Cuisines",
    typeofServices: "Mode de consommation",
    typeofDishes: "Type de Article",
    orderoftypeofdishes:"Ordre du type de plats",
    selectyourdish:"sélectionnez votre plat",
    acceptedall: "J'ai accepté toutes les",
    termandCondition: "Conditions Générale de vente",
    SignUp: "S'inscrire",
    Verificationcode: "Code de vérification",
    search: "Rechercher",
    done: "Terminé",
    openmap: "Ouvrir la carte",
    ownerdetails: "Nom du propriétaire",
    selectAvgPrice: "Veuillez saisir le prix moyen",
    selectService: "Veuillez sélectionner un service",
    frenchonwer: "Merci de rentrer le nom de propriétaire",
    selectpriceRangenume: "Average price should be in integer value",
    ownerdescription: "Description d' établissement",
    selectpriceRangenume: "Average price should be in integer value",
    ownerfrenchdescription: "Description de restaurant (FR)",
    closeTime: "heures de fermeture",
    openTime: "heures d'ouverture",
    website: "Site Internet",
    instagram: "Instagram",
    facebook: "Facebook",
    parking: "Voiturier",
    emailexist: "L'adresse mail existe déjà",
    mobileexist: "Le numéro de téléphone existe déjà",
    resGallery: "Photos",
    paymetMethodName: "Modes de paiement",
    selectPayment: "Veuillez sélectionner les modes de paiement",
    servicein: "Temps de Service",
    // Edit Item
    dishNameenglish: "Name of Article",
    dishNamefrench: "Nom du Articles",
    restaurantName: "Nom du établissement",
    dishName: "Nom du Article",
    description: "Description du Article",
    descriptioninfrench: "Description du Article (FR)",
    size: "Taille",
    typeOfCusinies: "Type de cuisines",
    typeOfService: "Mode de consommation",
    foodChoices: "Autres informations",
    priceIn: "Prix",
    preparationTime: "Temps de préparation",
    maximumOrderLimit: "Nombre maximum de commandes",
    OpeningClosingTime: "Disponibilité",
    discount: "Remise en %",
    offer: "Une acheté une offerte",
    selectPhoto: "Sélectionnez des photos",
    selectpriceRangenume: "Average price should be in integer value",
    save: "Sauvegarder",
    selectpriceRangenume: "Average price should be in integer value",
    buyoneget: "Achetez un et obtenez un gratuitement",
    selectpriceRangenume: "Average price should be in integer value",
    addOn: "Suppléments",
    ChooseofAddOn: "Choix des suppléments",
    //Home
    items: "Articles",
    search: "Recherchez des Bars/Restos",
    Searchlist:"Recherchez",
    delete: "Supprimer",
    //drawer
    home: "Accueil",
    selectpriceRangenume: "Average price should be in integer value",
    dishes: "Article",
    myAccount: "Mon compte",
    logout: "Se déconnecter",
    orders: "Commandes",
    myorder: "Mes Commandes",
    Warning: "Attention",
    selectpriceRangenume: "Average price should be in integer value",
    //Myaccount
    selectpriceRangenume: "Average price should be in integer value",
    myDetails: "Mes informations ",
    selectpriceRangenume: "Average price should be in integer value",
    payment: "Paiements",
    contactSupport: "Contactez le support",
    myaccount: "Mon compte",
    logoutmsg: "Vous êtes sûr de vouloir déconnecter",
    //Message
    pleaseEnterCountryCode: "Veuillez saisir un code de pays valide",
    pleaseEnterMobile: "Merci de rentrer numero telephone",
    pleaseEnterPassword: "Veuillez entrer le mot de passe",
    pleaseEnterEmail: "Veuillez saisir une adresse e-mail",
    correctEmail: "Veuillez saisir une adresse e-mail correcte",
    ConfirmaWrong: "Le code de vérification entré est incorrect",
    enterrestuarantname: "Merci de rentrer le nom du Bar/Resto",
    enteraddress: "Veuillez saisir une adresse e-mail",
    enteremail: "Veuillez saisir une adresse e-mail",
    correctenteremail: "Veuillez saisir une adresse e-mail correct",
    entermobile: "Merci de rentrer le numero de telephone",
    correctentermobile: "Merci de rentrer le numero de telephone valide",
    enteropentime: "Merci de choisir l'heure d'ouverture",
    enterclosetime: "Merci de choisir l'heure de fermeture",
    enternumberOfTable: "Merci de choisir le numéro de la table",
    correctenternumberOfTable: "Merci de rentrer le nombre des Places",
    selectchair: "Merci de rentrer le nombre des Places",
    correctselectchair: "Merci de rentrer le nombre des Places",
    selectpriceRange: "Veuillez sélectionner une gamme de prix",
    selectcuisines: "Merci de choisir le type de cuisine",
    enterdeshName: "Merci de rentrer le nom du Article",
    enterdeshfrName: "Merci de rentrer le nom du Article",
    selectdish: "Veuillez sélectionner un Article",
    selecttypeOfService: "Veuillez sélectionner les modes de consommation",
    selectfoodChoices: "Veuillez sélectionner les préférences du Article",
    selecttermcondition: "Veuillez accepter les Conditions générales d'utilisation (CGU)",
    selectimage: "Merci de choisir les images",
    usernamepasswordincorrect:
      "Le nom d'utilisateur et le mot de passe sont incorrects",
    correctpleaseEnterPassword: "Veuillez entrer au moins 8 chiffres/caractères.",
    passwordmess: "Le mot de passe doit comporter au moins 6 chiffres/caractères.",
    passwordnotmatch: "Le mot de passe et le mot de passe de confirmation ne sont pas les mêmes",
    itemdelete: "Voulez-vous vraiment supprimer  cette article?",
    itemstatusmsg:      "Voulez-vous vraiment définir le statut du cette article: Hors ligne?",
    itemstatusmsgonline:
      "Voulez-vous vraiment définir le statut du cette article: En ligne?",
    restaurantstatusmsg:
      "Voulez-vous vraiment définir le statut d' établissement: Hors ligne?",
    restaurantstatusmsgonline:
      "Voulez-vous vraiment définir le statut d' établissement: En ligne?",
    selectpriceRangenume: "Le prix moyen doit être en valeur entière",
    //payments
    payments: "Paiements",
    //addON
    addOnInFR: "Nom du Option",
    addOnInEN: "Option",
    addOnPrice: "Prix",
    addon: "Supplément",
    addOntytleInEn: "Ajouter sur le titre (EN)",
    addOntytleInFR: "Titre de Supplément",
    Choiceupto: " Nombres de Suppléments",
    Addon: "Suppléments",
    Optional: "Optionnelle",
    Required: "Obligatoire",

    //order accept
    TrackUser: "Suivre l'utilisateur",
    Watingforpayment: "En attente de confirmation",
    accept: "Acceptez",
    subtotal: "Total partiel",
    salesTax: "Taxe de vente",
    YouSave: "Vous sauvegardez",
    Total: "Total",
    AddPromoCode: "Ajouter un code promo",
    Upcoming: "En attente",
    Complete: "Validées",
    Confirmed: "Confirmées",
    Accepted: "Accepté",
    AllOrders: "Tous les Commandes",
    orderCancelRes: "Commande refusé par Bar/Resto",
    orderCancelUser: "Annulation de la commande par l'utilisateur",
    ordercompleted: "La Commande est terminée",
    // Wating for payments

    // scanQ
    scan: "Scanner",
    Tape: "Taper",
    Reservation: "Réservations",

    // map
    Orchoose: "Choisissez un lieu à proximité",
    //orderList
    orderdelete: "Voulez-vous vraiment supprimer la commande?",
    orderaccept: "Voulez-vous vraiment accepter la commande?",
    // confirmedOderCel
    Waiting: "Attendre",
    Cancel: "Annuler",
    Completed: "Terminé",
    Confirmed: "Confirmé",
    CancelByUser: "Annuler par utilisateur",
    Accepte: "Accepte",
    People: "Gens",
    Ordercomplete: "Commande terminée",
    orderreport:"Rapport sur les revenus",
    Arrived: "Arrivée",
    PaymentDone: "Paiement effectué",
    waytorestaurant: "En route vers le établissement",
    //Forgot Password
    forgotPassword: "Mot de passe oublié",
    fMsg:
      "Entrez votre numéro de mobile pour réinitialiser votre mot de passe",
    emailphone: "E-mail",
    submit: "Soumettre",
    // change password
    Newpassword: "Nouveau mot de passe",
    Confirmpassword: "Confirmez le mot de passe",
    //web
    facilitatesales: "Réussissons ensemble la réouverture de votre Bar ou Restaurant",
    startnow: `Devenez partenaire et faciliter les réservations*`,
    becomeapartner: "Je suis un Professionnel",
    becomeapartnerside: "Je m`inscris",
    putBar:
      "Mettez vos bars ou restaurants pour des réservations co Address ORDER HERE me jamais auparavant",
    downloadApp: "Télécharger l'App",
    signin: "Connectez-vous",
    nameOfdish: "Nom de l'article",
    choiceofFood: "Plus d'options",
    remise: "Remise",
    chooseAddon: "Choisissez des modules complémentaires",
    discription: "Description",
    detailsowner: "Détails du propriétaire",
    disOwner: "Description du propriétaire",
    pricescale: "Échelle des prix",
    titleaddon: "Titre du module complémentaire",
    chooseupto: "Choisissez jusqu'à",
    welcome: "Bienvenue",
    gladSee: "Nous sommes ravis de vous revoir",
    choosePassword: "Choisissez un mot de passe",
    forgotYpassword: "Mot de passe oublié",
    awaitingApproval: "En attente d'approbation",
    become: "Devenez",
    partner: "partenaire",
    krusttyand: "Krustty et",
    increaseyour: "augmentez votre",
    ItemDelete: "Élément supprimé avec succès",
    imageDelete: "Suppression d'image réussie",
    mediumTurn: "chiffre D’affaire",
    signUpmsg:
      "La plateforme technologique Krustty aide les restaurants de France à étendre leur portée, à satisfaire leurs clients et à augmenter leur chiffre d'affaires.",
    becomeToday: "Devenez partenaire dès aujourd'hui",
    byClick: "En cliquant sur Envoyer, vous acceptez notre",
    byClick2: "Politique de confidentialité et nos Conditions Générales de Vente",
    generalsales: "",
    
    termsandconditions: "Conditions générales",
    readPolicy:
      "et confirmer que vous avez lu la Politique de confidentialité.",
    toSend: "Envoyer",
    enternumberToforgot:
      "Entrez votre numéro de téléphone pour le mot de passe oublié",
    enteryOtp: "Entrez le code de vérification",
    update: "Mettre à jour",
    pleaseEnterOtp: "Veuillez saisir le code de vérification",
    Resendverificationcode: "Renvoyer le code de vérification",
    privacyPolicy: "Conditions Générales De Vente (CGV)",
    yourservice: "À Votre Service",
    contactus: "Nous contacter",
    termsConditions: "Conditions générales de vente",
    aboutsus: "abouts us",
    knowyou: "On Vous Dit Tout",
    whoweare: "Qui sommes-nous ?",
    stayconnected: "Restez Connecté",
    cartside: "Panier",
    signOut: "Déconnexion",
    dounloadapp: "Télécharger l’App",
    servedin: "Servi en",
    discoverKrustty: "Découvrez Krustty sur",
    servicecharges:"Frais de service inclus",
    Order:"Ordre",
    datetime:"Date et heure",
    ItemName:"Nom de l'article",
    Quantity:"Quantité",
    foodchoice:"Frais de nourriture",
    Price:"Prix",
    Earning:"Revenus",
    MobileQR:"Mobile",
    Online:"En ligne",
    Cash:"En espèces",
    Paylater:"Payer plus tard",
    Voucher:"Bon",
    entername:"Veuillez entrer le nom",
    addontitle:"Veuillez saisir le titre du module complémentaire",
    selectchoice: 'Veuillez sélectionner le choix',
    choiceselectone:"Vous avez sélectionné",
    choceselecttwo:"choix donc vous avez besoin d'au moins selcet",
    deleteitem:"Êtes-vous sûr de vouloir supprimer cet élément?",
    order_id:"numéro de commande",
    ItemName:"Nom de l'article",
    Quantity:"Quantité",
    FoodCharges:"Frais de nourriture",
    Price:"Prix",
    all:"tout",
    Weekly:"Hebdomadaire",
    Monthly:"Mensuel",
    Yearly:"Annuel",
    erreport:"Rapport sur les revenus",
    successmsg:"Détails mis à jour avec succès",



  },
});
export default strings;

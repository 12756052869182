import React, { useState, useRef, useEffect } from 'react'
import { apiCall } from '../utils/httpClient'
import ApiEndPoints from '../utils/apiEndPoints'
import Footer from '../footer/footer';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import './login.css'
import countries from '../../component/countries'
import SimpleReactValidator from 'simple-react-validator';
import Flag from 'react-world-flags'
import strings from '../../language';
import detectBrowserLanguage from "detect-browser-language";

export default (props) => {
  // const simpleValidator = useRef(new SimpleReactValidator())
  const simpleValidator = useRef(new SimpleReactValidator({
    locale:detectBrowserLanguage()
   } ))
  const wrapperRef = useRef()
  const inputEl = useRef(null);

  const [searchPlaceh, setsearchPlaceh] = useState('France')
  const [errorMessage, setErrorMessage] = useState('')
  const [countriesData, setcountriesData] = useState(countries)
  const [searchval, setsearchval] = useState('')
  const [showflagPicker, setShowflagPicker] = useState(false)
  const [email, setemail] = useState('')
  const [phoneNumber, setphoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('+33')
  const [type, settype] = useState('')
  const [flag, setFlag] = useState("fr")
  const history = useHistory();
  const [, forceUpdate] = useState()

  useEffect(() => {
    setphoneNumber(history.location.state.phoneNumber)
    setCountryCode(history.location.state.countryCode)
    settype(history.location.state.type)
    setFlag(history.location.state.flag)

    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowflagPicker(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    showflagPicker && inputEl.current.focus();
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showflagPicker, wrapperRef])
  function validateAllField() {
    if (simpleValidator.current.allValid()) {
      return true;
    } else {
      simpleValidator.current.showMessages(true);
      forceUpdate(1)
      return false;
    }
  }

  const navOtp = (type) => {
    let path = `forgotpasswordotp`;
    history.push(path, { phoneNumber: phoneNumber, countryCode: countryCode, type: type });
  }

  const onLogin = async () => {
    const isValid = validateAllField()
    const lang = await localStorage.getItem("locale");
    if (isValid) {
      const datas = {
        'mobileno': phoneNumber.replace(/^0+/, ""),
        'country_code': countryCode.substring(1)
      }
      try {
        const { data } = await apiCall('post', ApiEndPoints.forgotPassCustomer, datas)
        // console.log('data', data)
        if (data.status == 200) {
          navOtp(0)
        } else {
          setErrorMessage(lang == "en-US" ? data.message : lang == "en" ? data.message : data.fr_message)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const onRegestration = async () => {
    const isValid = validateAllField()
    const lang = await localStorage.getItem("locale");
    if (isValid) {
      const datas = {
        'phone': phoneNumber.replace(/^0+/, ""),
        'country_code': countryCode.substring(1)
      }
      try {
        const { data } = await apiCall('post', ApiEndPoints.forgotpassRestro, datas)
        // console.log('data', data)
        if (data.status == 200) {
          navOtp(1)
        } else {
          setErrorMessage(lang == "en-US" ? data.message : lang == "en" ? data.message : data.fr_message)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const onPressFlagPicker = (country) => {
    setShowflagPicker(!showflagPicker)
    setCountryCode(country.dial_code)
    setFlag(country.code)
    setcountriesData(countries);
    setsearchPlaceh(country.name)
  }
  const onCountryCodeSearch = async (searchText) => {
    setsearchval(dataArray2);
    let data = await countries;
    searchText = searchText.trim().toLowerCase();
    let dataArray2 = data.filter(l => {
      return l.name.toLowerCase().match(searchText);
    });
    setcountriesData(dataArray2);
  }
  const onCountryCode = async (val) => {
    const countryData = await countries
    try {
      setCountryCode(val)
      let countryFlag = []
      countryFlag = _.filter(countryData, { dial_code: val });
      //  console.log(countryFlag)
      //  console.log(countryFlag.length > 0 ? countryFlag[0].flag : val, countryFlag.length > 0 ? countryFlag[0] : val)
      setCountryCode(countryFlag.length > 0 ? countryFlag[0].dial_code : val)
      setFlag(countryFlag.length > 0 ? countryFlag[0].code : 'Fr')
    }
    catch (err) {
      console.log(err)
    }
  }
  return (
    <div>
      {/* <Header /> */}
      <div>
        <div className="main-banner--home">
          <div
            className="bg"
            style={{ backgroundImage: 'url("images/BackGroundImage.svg")' }}
          />
        </div>
        <div className="form-section">
          <div className="outer">
            <div className="wrap">
              <div className="row center-lg">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 center-content-wrapper">
                  <div className="single-caption">
                    <h2>{strings.welcome}</h2>
                    <div className="fs-text">
                      {strings.enternumberToforgot}
                    </div>
                  </div>
                  {
                    showflagPicker &&
                    <div ref={wrapperRef} className="form--group" style={{ position: "absolute", }}>
                      <div style={{
                        height: 260,
                        width: 394,
                        minHeight: 100,
                        backgroundColor: '#ffffff',
                        position: "absolute",
                        zIndex: 1,
                      }}>
                        <input
                          ref={inputEl}
                          style={{ width: "100%", paddingLeft: 25, paddingTop: 10, paddingBottom: 10 }}
                          type="text"
                          placeholder={searchPlaceh}
                          value={searchval}
                          className="countryinput"
                          onChange={(event) => onCountryCodeSearch(event.target.value)}
                        />
                        <div style={{
                          height: 260,
                          width: 394,
                          minHeight: 100,
                          overflowY: 'scroll',
                          backgroundColor: '#ffffff',
                          zIndex: 1,
                        }}>
                          {
                            countriesData.map((country, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() => onPressFlagPicker(country)}
                                  style={{
                                    color: "#OOO", width: "100%",
                                    cursor: 'pointer', padding: 5, textAlign: "left",
                                    paddingTop: 15, paddingBottom: 15,
                                    paddingLeft: 20,
                                  }}>
                                  <Flag code={country.code} height="16" width="50" fallback={<span>Unknown</span>} />&nbsp;&nbsp;&nbsp;&nbsp;{country.name}
                                </div>)
                            })
                          }
                        </div>
                      </div>
                    </div>
                  }
                  <div className="main-form">
                    <div>
                      <div className="form--group">
                        {/* <input
                          type="text"
                          placeholder="Entrez votre numéro de téléphone"
                          className="form--control"
                          onChange={(event) => onphoneNumber(event)}
                        /> */}

                        <div style={{ background: "#fff", paddingTop: 10, paddingBottom: 10 }}>
                          <div
                            onClick={() => setShowflagPicker(!showflagPicker)}
                            className="divcenter"
                            style={{ width: "15%", textAlign: "center", fontSize: 23, }}>
                            {/* {flag} */}
                            <Flag code={flag} fallback={<Flag code={'Fr'} fallback={<span>Unknown</span>} />} />
                          </div>
                          <div className="divcenter" style={{ width: "13%", paddingLeft: 10 }}>
                            {
                              countryCode
                            }

                          </div>
                          <div className="divcenter" style={{ width: "65%", paddingLeft: 10 }}>
                            <input
                              type="text"
                              name="phone"
                              placeholder={strings.mobile}
                              value={phoneNumber}
                              className="countryinput"
                              onChange={(event) => setphoneNumber(event.target.value)}
                              onKeyPress={event => {
                                if (event.key === 'Enter') {
                                  event.preventDefault();
                                  onRegestration()
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="fs-text" className="errorTxt">
                          {simpleValidator.current.message(strings.mobile, phoneNumber, 'required|min:9|max:11|numeric')}
                          {simpleValidator.current.message('countryCode', countryCode, 'required')}
                          {errorMessage}
                        </div>
                        {/* </div> */}
                      </div>
                      <div className="spacer10" />
                      <div className="form--group">
                        <button className="btn-submit" onClick={() => type == 1 ? onRegestration() : onLogin()}>
                          {strings.toSend}
                         </button>
                      </div>
                      <div className="form--group">
                        {/* <p>Mot de passe oublié?</p> */}
                        {/* <a style={{ color: "#fff" }} href="/customerregistration" >Don't have an account?</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

import React, { useEffect, useState, useRef} from 'react'
import Footer from '../footer/footerApp';
import Header from '../header/header';
import SideNav from '../sideNav/sideNav';
import { useHistory } from 'react-router-dom';
import ApiEndPoints from '../utils/apiEndPoints';
import { apiCall } from '../utils/httpClient';
import SimpleReactValidator from 'simple-react-validator';
import { useAlert } from 'react-alert'
import strings from '../../language';
import DraggableList from './addonList'


let DATA = [{
  value: '1',
}, {
  value: '2',
}, {
  value: '3',
}, {
  value: '4',
}, {
  value: '5',
}, {
  value: '6',
}, {
  value: '7',
}, {
  value: '8',
}, {
  value: '9',
}, {
  value: '10',
}, {
  value: '11',
}, {
  value: '12',
}, {
  value: '13',
}, {
  value: '14',
}, {
  value: '15',
}];
let addonBtn = true;
let dragged;
let over;
export default (props) => {
  const alert = useAlert()
  const history = useHistory();
  const [, forceUpdate] = useState()
  const [option, setOption] = useState([{ subaddonNameEn: "", price: "" }])
  const [option1, setOption1] = useState([])
  const [addonName, setAddonName] = useState('')
  const [addonId, setAddonId] = useState('')
  const [choiceoff, setChoiceoff] = useState('1')

  const [dataArray, setDataArray] = useState([])
  const [data, setData] = useState([])
  const [isRequired, setIsRequired] = useState(0)
  const [isdefault, setisdefault] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  // useEffect(() => {
  //   fetchAddonList()
  // }, [])


  useEffect(() => {
    fetchAddonList()
  }, [])
  async function fetchAddonList() {
    try {
      const token = await localStorage.getItem('token');
      const { data } = await apiCall('get', ApiEndPoints.getAddonlist + '?lang=en',
        {
          'Accept': "application/json, text/plain, */*",
          'token': token
        }
      )
      if (data.status == 200) {
        setisdefault(0)
        // var list = [...option];
        // setOption(list);
        // console.log('list:  testing data ', list);
      console.log('data: ', data);
        setData(data.data)
        setDataArray(data.data)

        setIsLoading(true)
      }
      else {
        setIsLoading(true)
      }
    } catch (error) {
      console.log('error: ', error);
      setIsLoading(true)
    }
  }

  const handleRemoveClick = async(index,x) => {
    console.log('index: ', index);
    var list = [...option];
    console.log('list: ', list);
    // var indexx = index +1;
  const result = list.filter(word => word.subaddonNameEn !== x.subaddonNameEn);
    console.log('result: ', result);

    // console.log('option: ', option);
    // console.log('list: ', list);
    // var lit = list.splice(index, 1);
    // console.log('lit: ', lit);
     //console.log('list: ', list);
  
     await setOption(result);
     setisdefault(1)
      // await fetchAddonList();
    //console.log("opettion",option);

  };

  const handleAddClick = () => {
    setOption([...option, { subaddonNameEn: "", price: "" }]);
  };

  const handleRadioBtn = (event) => {
    setIsRequired(event.target.value)
  }

  const saveAddon = async (event) => {
    if (addonBtn) {
      addonBtn = false
      try {

        const saveData = {
          'items_addon_id': addonId,
          'name': addonName,
          'fr_name': addonName,
          'price': '',
          'choiceoff': choiceoff,
          'isrequired': isRequired,
          'subaddon': JSON.stringify(option)
        }
        const data = await apiCall('post', ApiEndPoints.saveAddon, saveData)
        
        if (data.status == 200) {
          setAddonName('')
          setOption([{ subaddonNameEn: "option", price: "price" }])
         // setOption(data.data.data.subaddon)
          setChoiceoff('')
          setIsRequired('')
          fetchAddonList()
          addonBtn = true
        }
      } catch (error) {
        console.log('error: ', error);
      }
    }
  }
  const handleChoiceOff = (e) => {
    setChoiceoff(e.target.value)
  }
  const handleOptionName = (e, i) => {
    setisdefault(0)
    option[i].subaddonNameEn = e.target.value
    setOption(option)
    
  }
  const handleOptionPrice = (e, i) => {
    setisdefault(0)
    option[i].price = e.target.value
    
    setOption(option)
  }
  const handleAddon = (item) => {
    setAddonId(item.value)
    setAddonName(item.name)
    setOption(JSON.parse(item.subaddon))
    setChoiceoff(item.choiceoff)
    setIsRequired(item.isrequired)
    setisdefault(1)
  }
  const deleteAddon = async (item, i) => {
    const dataA = [...dataArray];
    dataA.splice(i, 1);
    setDataArray(dataA)
    const data = await apiCall('get', ApiEndPoints.deleteAddon + '?items_addon_id=' + item.value)
    setAddonName('')
    setOption([{ subaddonNameEn: "option", price: "price" }])
    setChoiceoff('')
    setIsRequired('')
    fetchAddonList()
    if (data.data.status == 200) {

    }
  }

  const addAddon = () => {
    const isValid = validateAllField();
    // console.log(serviceVal.length <= 0)
    if (isValid) {
      setisdefault(1)
      saveAddon()
    }
  }

  function validateAllField() {
    if (!addonName) {
      alert.show(strings.addontitle, {
        timeout: 2000, // custom timeout just for this one alert
        type: 'error',

      })
      return false;
    }

    if (!choiceoff) {
      alert.show(strings.selectchoice, {
        timeout: 2000, // custom timeout just for this one alert
        type: 'error',
      })
      return false;
    }

    if (option.length < choiceoff) {
      alert.show(strings.choiceselectone + ' ' + choiceoff + ' ' + strings.choceselecttwo + ' ' + choiceoff + ' ' + 'options', {
        timeout: 2000, // custom timeout just for this one alert
        type: 'error',
      })
      return false;
    }
    return true;
  }
  const dddd = [
    {
      newIndex: 1,
      color: "red"
    },

    {
      newIndex: 2,
      color: "green"
    },

    {
      newIndex: 3,
      color: "blue"
    },

    {
      newIndex: 4,
      color: "yellow"
    },

    {
      newIndex: 5,
      color: "orange"
    },

    {
      newIndex: 6,
      color: "black"
    }
  ]


  function dragStart(e) {
    dragged = e.currentTarget;
  }
  function dragEnd(e) {
    dragged.style.display = 'flex';

    e.target.classList.remove("drag-up");
    over.classList.remove("drag-up");

    e.target.classList.remove("drag-down");
    over.classList.remove("drag-down");

    // var data = data;
    var from = Number(dragged.dataset.id);
    var to = Number(over.dataset.id);
    var data = [...dataArray]
    data.splice(to, 0, data.splice(from, 1)[0]);

    //set newIndex to judge direction of drag and drop
    data = data.map((doc, index) => {
      doc.newIndex = index + 1;
      return doc;
    })
    setData(data)
    // this.setState({ data: data });
  }

  function dragOver(e) {
    e.preventDefault();

    dragged.style.display = "none";

    if (e.target.tagName !== "LI") {
      return;
    }


    const dgIndex = JSON.parse(dragged.dataset.item).newIndex;
    const taIndex = JSON.parse(e.target.dataset.item).newIndex;
    const animateName = dgIndex > taIndex ? "drag-up" : "drag-down";


    if (over && e.target.dataset.item !== over.dataset.item) {
      over.classList.remove("drag-up", "drag-down");
    }

    if (!e.target.classList.contains(animateName)) {
      e.target.classList.add(animateName);
      over = e.target;
    }
  }

  var listItems = data.map((item, i) => {
    return (
      // <li
      //   data-id={i}
      //   key={i}
      //   style={{ height: "60px", border: "solid 1px #cccccc", margin: "10px 30%", borderRadius: "5px", backgroundColor: "green", color: "#ffffff" }}
      //   draggable='true'
      //   onDragEnd={dragEnd.bind(this)}
      //   onDragStart={dragStart.bind(this)}
      //   data-item={JSON.stringify(item)}
      // >{"ww5w"}</li>

      <li
        data-id={i}
        key={i}
        style={{
          height: "60px",
          border: "solid 1px #cccccc",
          margin: 5,
          borderRadius: "5px",
          background: "#fff",
          display: 'flex',
          alignItems: "center",
          flexDirection: "row"

        }}
        draggable='true'
        onDragEnd={dragEnd.bind(this)}
        onDragStart={dragStart.bind(this)}
        data-item={JSON.stringify(item)}
        className="col 12"
      // style={{
      //   background: "#fff",
      //   height: "60px",
      //   borderRadius: 5,
      //   paddingTop: 5, 
      //   paddingBottom: 5,
      //   display: 'flex',
      //   alignItems: "center"
      // }}
      >
        <label className="col-sm-1 col-form-label">&nbsp;</label>
        <div className="col-sm-9 col-form-label" onClick={() => handleAddon(item)}>

          <label className="col-sm-12">
            {item.name}
          </label>
        </div>
        <div className="col-sm-1"
          onClick={() => { if (window.confirm(strings.deleteitem)) deleteAddon(item, i) }} >
          <i className="nav-icon fas fal fa-trash"
            style={{ color: "red" }}
          />
        </div>
        <label className="col-sm-1">&nbsp;</label>
      </li>
    )
  });
  return (
    <>
      <SideNav />
      {isLoading ? (
        <div className="content-wrapper bg-gray-light">
          <div className="content p-0 pt-3" style={{ height: "90vh" }}>
            <div className="container-fluid">
              <div className="row m-0">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                       <div className="form-horizontal" style={{maxHeight:515,overflow:"auto",overflowX:"hidden"}}> 
                      {/* <div className="form-horizontal"> */}
                        <div className="form-group row">
                          <div className="col-sm-9">
                            <label
                              htmlFor="email"
                              className="col-form-label"
                            >
                              {strings.titleaddon}
                            </label>
                            <input
                              value={addonName}
                              placeholder={strings.titleaddon}
                              type="text"
                              className="form-control"
                              id="addonName"
                              onChange={(e) => setAddonName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div
                            className="col-sm-9"
                          >
                            <label
                              htmlFor="choose-upto"
                              className="col-form-label"
                            >
                              {strings.chooseupto}
                            </label>
                            <select
                              id="lang"
                              className="col-sm-12 form-control"
                              value={choiceoff}
                              onChange={handleChoiceOff}
                            >
                              {DATA.map((item, i) => {
                                return (
                                  <option key={i} value={item.value}>
                                    {" "}
                                    {item.value}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div
                            className="col-sm-6 row"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div className="col-sm-3">
                              <input
                                type="radio"
                                className="form-control"
                                id="choose-upto"
                                value={0}
                                onChange={handleRadioBtn}
                                checked={isRequired == 0}
                                style={{
                                  border: "0px",
                                  width: "100 %",
                                  height: "1.5em",
                                }}
                              />
                            </div>
                            <label
                              htmlFor="choose-upto"
                              className="col-sm-3 col-form-label"
                            >
                              Optional
                            </label>
                          </div>
                          <div
                            className="col-sm-6 row"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div className="col-sm-3">
                              <input
                                style={{
                                  border: "0px",
                                  width: "100 %",
                                  height: "1.5em",
                                }}
                                value={1}
                                checked={isRequired == 1}
                                onChange={handleRadioBtn}
                                type="radio"
                                className="form-control"
                                id="choose-upto"
                              />
                            </div>
                            <label
                              htmlFor="choose-upto"
                              className="col-sm-3 col-form-label"
                            >
                              Required
                            </label>
                          </div>
                        </div>
                               
                    {console.log('option: 123 ', option)}
                        {option.map((x, i) => {
                            {console.log('option: i ',i)}
                          return (
                            <div key={i} className="form-group row">
                              {/* <label className="col-sm-2 col-form-label">
                                &nbsp;
                              </label> */}
                              <div className="col-sm-7">
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  {isdefault?
                                   <input
                                   type="text"
                                   className="form-control text-center"
                                   placeholder="Option"
                                   onChange={(e) => handleOptionName(e, i)}
                                   value={x.subaddonNameEn}
                                 />:
                                    <input
                                    type="text"
                                    className="form-control text-center"
                                    placeholder="Option"
                                    onChange={(e) => handleOptionName(e, i)}
                                    defaultValue={x.subaddonNameEn}
                                  />
                                  }
                                   
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  {isdefault?
                                    <input
                                      type="text"
                                      className="form-control text-center"
                                      placeholder="Prix"
                                      onChange={(e) =>
                                        handleOptionPrice(e, i)
                                      }
                                      value={x.price}
                                    />:<input
                                    type="text"
                                    className="form-control text-center"
                                    placeholder="Prix"
                                    onChange={(e) =>
                                      handleOptionPrice(e, i)
                                    }
                                    defaultValue={x.price}
                                  
                                  />}
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-5 row">
                                {option.length !== 1 && (
                                  <button
                                    type="button"
                                    style={{ marginRight: 10 }}
                                    className="btn btn-danger btn-md"
                                    onClick={() => handleRemoveClick(i,x)}
                                  >
                                    <i className="fa fa-minus" />
                                  </button>
                                )}
                                {option.length - 1 === i && (
                                  <button
                                    type="button"
                                    className="btn btn-success btn-md"
                                    onClick={() => handleAddClick()}
                                  >
                                    <i className="fa fa-plus" />
                                  </button>
                                )}
                              </div>
                            </div>
                          );
                        })}
                        <div className="form-group row">
                          {/* <label className="col-sm-4 col-form-label">
                            &nbsp;
                          </label> */}
                          <div className="col-sm-9">
                            <button
                              onClick={() => addAddon()}
                              type="button"
                              className="btn btn-block btn-success btn-lg"
                            >
                              {strings.save}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-5 col-md-5 col-sm-12 col-xs-12"
                      style={{ overflow: "auto", height: "88vh" }}
                    >
                      <ul
                        onDragOver={dragOver.bind(this)}
                        className="contain"
                      >
                        {listItems}
                      </ul>

                      {/* {dataArray.length < 0 ?
                        <DraggableList
                          data={dddd}

                        // {dataArray}
                        />
                        : null
                      } */}
                      {/* {
                      dataArray.map((item, i) => {
                        return (
                          <div key={i}
                            className="form-group row"
                            style={{
                              background: "#fff",
                              // background: "#000",
                              borderRadius: 5,
                              paddingTop: 5, paddingBottom: 5,
                              display: 'flex',
                              alignItems: "center"
                            }}>
                            <label className="col-sm-1 col-form-label">&nbsp;</label>
                            <div className="col-sm-9 col-form-label" onClick={() => handleAddon(item)}>

                              <label className="col-sm-12 col-form-label">
                                {item.name}
                              </label>
                            </div>
                            <div className="col-sm-1"
                              onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteAddon(item, i) }} >

                              
                              <i className="nav-icon fas fal fa-trash"
                                style={{ color: "red" }}
                              />
                            </div>
                            <label className="col-sm-1 col-form-label">&nbsp;</label>
                          </div>

                        )
                      })
                    } */}
                    </div>
                    {/* END OF COL */}
                  </div>
                  {/* END OF ROW */}
                </div>
                {/* /.col-md-6 */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </div>
          {/* /.content */}
        </div>
      ) : null}
      <Footer />
    </>
  );
}
